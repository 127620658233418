<template>
    <div>
        <div class="titulo-filtro">
            TAGS

            <div class="d-flex" style="float: right;">
                <div class="ml-auto">
                    <img
                        v-if="!tagsVisibles"
                        src="../iconos/svg/045-add.svg"
                        width="18px"
                        v-on:click="mostrarTags()"
                    >
                    <img
                        v-if="tagsVisibles"
                        src="../iconos/svg/035-minus.svg"
                        width="18px"
                        v-on:click="mostrarTags()"
                    >
                </div>
            </div>
            
            <div v-if="tagsVisibles"
                class="input-group zona-busqueda"
            >
                <input
                    id="aguja"
                    type="search"
                    class="form-control caja-busqueda"
                    placeholder="Buscar tags"
                />
                <button
                    class="boton boton-buscar"
                    v-on:keyup.enter="buscarTags()"
                    v-on:click="buscarTags()"
                >Buscar</button>
            </div>
        </div>

        <div v-if="cargando">
            <img src="../assets/loading.gif" width="200" />
        </div>

        <div v-else>
            <div v-if="tagsVisibles">
                <div>
                    <select id="cboTagsDocumentos" multiple class="form-control bg-gris-oscuro bd-gris" size="6">
                        <option
                            v-for="tag in tags" v-bind:key="tag.id"
                            class="bg-gris-oscuro gris"
                            v-bind:id="tag.id"
                            v-on:click="seleccionarTags()"
                        >
                            {{ tag.nombre }}
                        </option>
                    </select>
                </div>
            </div>
            
            <div>
                <button class="boton boton-secundario" v-on:click.prevent="limpiarSeleccion()">VER TODO</button>
            </div>
        </div>
    </div>
</template>

<script>
import { bus } from "../main.js";
import Combobox from "../Combobox.js";
import axios from "axios";
import { urlAPI } from "../main.js";

export default {
    name: "SelectorTagsDocumentos",

    data() {
        return {
            cargando: true,
            tags: [],
            tagsSeleccionados: "",
            tagsVisibles: false,
        };
    },

    mounted() { 
        this.leerTags();
    },

    methods: {
        leerTags() {
            axios.get(urlAPI + "/api/v1/documentos/tags")
                .then((respuesta) => {
                    for (let tag of respuesta.data) {
                        this.tags.push({
                            id: tag.id,
                            nombre: tag.nombre,
                        });
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false;
                });
        },

        seleccionarTags() {
            this.tagsSeleccionados = "";

            for (let i = 1; i < this.tags.length; i++) {
                if (Combobox.ComboboxGetSelectedCaption("cboTagsDocumentos") != false) {
                    this.tagsSeleccionados = Combobox.ComboboxGetSelectedCaption("cboTagsDocumentos").split(",");

                    bus.$emit("tagsDocumentosSeleccionados", this.tagsSeleccionados);
                }
            }
        },

        limpiarSeleccion() {
            if (this.tags.length > 0) {
                Combobox.ComboboxClear("cboTagsDocumentos")

                bus.$emit("tagsDocumentosSeleccionados", "");
            }
        },

        mostrarTags() {
            this.tagsVisibles = !this.tagsVisibles
        },

        buscarTags() {
            let aguja = document.getElementById("aguja").value.trim().toUpperCase()

            if (aguja != '') {
                if (this.tags.length > 0) {
                    Combobox.ComboboxClear("cboTagsDocumentos")

                    for (let i = 0; i < this.tags.length; i++) {
                        if (this.tags[i].nombre.toUpperCase().indexOf(aguja) != -1) {
                            Combobox.ComboboxAddSelected("cboTagsDocumentos", i + 1)
                        }
                    }

                    this.seleccionarTags()
                }
            }
        }
    }
};
</script>

<style scoped>
select {
    border: none;
    font-size: 1.2rem;
    text-align: center;
    padding: 20px;
}

option {
    padding-top: 6px;
    padding-bottom: 6px;
    border-bottom: solid;
    border-width: 1px;
    border-color: rgba(63,62,62,0.7);
}

.zona-busqueda {
    padding: 0;
    margin: 0;
    height: 25px;
}

.caja-busqueda {
    border-radius: 0px;
    height: 35px;
    margin-left: -10px;
    border: none;
}

.boton-buscar {
    margin-top: 10px;
    height: 35px;
    padding: 2px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
    margin-right: -10px;
}
</style>