<template>
    <div class="container">
        <!-- <span v-if="$store.getters.usuarioIdentificado && $store.getters.numClasificacionVisible == '2'"> -->
        <span v-if="$store.getters.numClasificacionVisible == '8'">
            <div v-if="cargando">
                <img src="../assets/loading.gif" width="200" />
            </div>

            <div v-else>
                <div class="dropdown borde-si borde-bottom-no borde-delgado bd-verde zona-dropdown">
                    <button class="combobox dropdown-toggle borde-no" type="button" id="dropdownMenuButtonClasificacionArtistasPorOyentes" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Top ...
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButtonClasificacionArtistasPorOyentes">
                        <span
                            class="dropdown-item" href="#"
                            v-on:click.prevent="fijarTopX(5)"
                        >
                            Top 5
                        </span>
                        <span
                            class="dropdown-item" href="#"
                            v-on:click.prevent="fijarTopX(10)"
                        >
                            Top 10
                        </span>
                        <span
                            class="dropdown-item" href="#"
                            v-on:click.prevent="fijarTopX(20)"
                        >
                            Top 20
                        </span>
                    </div>
                </div>

                <table class="table table-dark tabla-clasificacion bd-verde">
                    <thead>
                        <tr>
                            <th scope="col" class="bg-negro amarillo-sistema">#</th>
                            <th scope="col" class="bg-negro amarillo-sistema">Artista</th>
                            <th scope="col" class="bg-negro amarillo-sistema texto-derecha">Oyentes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(artista,indice) in subconjuntoArtistasVisible"
                            v-bind:key="indice"
                        >
                            <td class="bg-negro blanco">
                                {{ indice + 1 }}
                            </td>
                            <td class="bg-negro verde negrita">
                                <img
                                    class="pulsable"
                                    v-bind:src="artista.imagen_url"
                                    width="40"
                                    height="40"
                                    v-on:click.prevent="publicarArtistaPulsado(artista.user_spotify_id)"
                                >
                                <span
                                    class="pulsable"
                                    v-on:click.prevent="publicarArtistaPulsado(artista.user_spotify_id)"
                                >
                                    {{ artista.nombre | truncarTexto(16) }}
                                </span>
                            </td>
                            <td class="bg-negro texto-derecha blanco negrita">
                                {{ artista.oyentes_mensuales | separadorMiles() }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </span>
    </div>
</template>

<script>
import { bus } from "../main.js";
import axios from "axios";
import { urlAPI } from "../main.js";
import { TOPX_CLASIFICACION } from "../main.js";

export default {
    name: "ClasificacionArtistasPorOyentes",

    data() {
        return {
            cargando: false,
            topX: TOPX_CLASIFICACION,
            clasificacionArtistasPorOyentes: [],
            subconjuntoArtistasVisible: [],
        };
    },

    mounted() {
        this.leerClasificacion()
        
        bus.$on("clasificacionSeleccionada", () => {
            if (this.$store.getters.numClasificacionVisible == '8') {
                this.publicarArtistasClasificacion()
            }
        });
    },

    watch: {
        subconjuntoArtistasVisible: function () {
            //Publicar como pulsados a los artistas de la clasificación.
            if (this.$store.getters.paginaVisible === 'chileenspotify' && this.$store.getters.numClasificacionVisible == '8') {
                this.publicarArtistasClasificacion()
            }
        },

        topX: function () {
            this.subconjuntoArtistasVisible = this.clasificacionArtistasPorOyentes.slice(0, this.topX)
        }
    },

    methods: {
        leerClasificacion() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/clasificacionartistasporoyentes")
                .then((respuesta) => {
                    this.clasificacionArtistasPorOyentes = respuesta.data
                    this.subconjuntoArtistasVisible = this.clasificacionArtistasPorOyentes.slice(0, this.topX)
                })
                .catch((error) => {
                    console.log(error);
                    this.errores = true;
                })
                .finally(() => {
                    this.cargando = false;
                });
        },
        
        fijarTopX(maximoElementos) {
            if (this.clasificacionArtistasPorOyentes.length >= maximoElementos) {
                this.topX = maximoElementos
            } else {
                this.topX = this.clasificacionArtistasPorOyentes.length
            }
        },
        
        publicarArtistaPulsado(userSpotifyId) {
            let listaUserSpotifyIdArtistasPulsados = []
            
            listaUserSpotifyIdArtistasPulsados.push(userSpotifyId)

            this.$store.dispatch('definirListaUserSpotifyIdArtistasSeleccionados', listaUserSpotifyIdArtistasPulsados)

            if (listaUserSpotifyIdArtistasPulsados.length > 0) {
                bus.$emit("artistasClasificacionArtistasPorOyentesDesplegados")
            }
        },

        publicarArtistasClasificacion() {
            let listaUserSpotifyIdArtistasPulsados = []
            let cotaSuperior = this.topX

            if (this.topX > this.clasificacionArtistasPorOyentes.length) {
                cotaSuperior = this.clasificacionArtistasPorOyentes.length
            }

            for(let i = 0; i < cotaSuperior; i++) {
                listaUserSpotifyIdArtistasPulsados.push(this.clasificacionArtistasPorOyentes[i].user_spotify_id)
            }

            this.$store.dispatch('definirListaUserSpotifyIdArtistasSeleccionados', listaUserSpotifyIdArtistasPulsados)

            if (listaUserSpotifyIdArtistasPulsados.length > 0) {
                bus.$emit("artistasClasificacionArtistasPorOyentesDesplegados")
            }
        }
    }
};
</script>

<style>
.titulo-tabla {
    color: whitesmoke;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.5rem;
}

.tabla-clasificacion {
    border-style: solid !important;
    border-width: 1px !important;
    border-top-style: none !important;
}

.zona-dropdown {
    padding-top: 30px;
    padding-left: 10px;
}

.pulsable {
    cursor: pointer;
}

.pulsable:hover {
    color: rgb(240,240,16) !important;
}
</style>
