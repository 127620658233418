<template>
    <div class="container">
        <div class="vista-titulo texto-centro">
            Paso a paso para el estudio de artistas
        </div>

        <br />
        <div class="cuadro-icono float-left">
            <img class="icono-paso-a-paso" src="../assets/icono-paso-a-paso-155x140.png"/>
        </div>
        
        <div class="row">
            <div class="cuadro-paso1">
                <span class="titulo-paso">
                    Paso 1
                </span> 
                <div class="texto-paso">
                    Puedes seleccionar un conjunto de géneros para filtrar artistas.
                </div>
            </div>

            <div class="cuadro-paso-a-paso">
                <span class="titulo-paso">
                    Paso 2
                </span> 
                <div class="texto-paso">
                    Busca y selecciona uno o más artistas y añádelos pulsando el botón PARA ESTUDIO.
                </div>
            </div>
            
            <div class="cuadro-paso-a-paso">
                <span class="titulo-paso">
                    Paso 3
                </span> 
                <div class="texto-paso">
                    Cuando tengas listo el conjunto de artistas, pulsa el botón ESTUDIAR.
                </div>
            </div>
        </div>

        <div class="float-clear"></div>

        <div class="container">
            <br /><br />
            <div class="row">
                <span class="amarillo">* </span>
                <span class="blanco nota">
                    El criterio en que los artistas se relacionan entre sí es propio de Spotify.
                </span>
            </div>
            <div class="row">
                <span class="amarillo">* </span>
                <span class="blanco nota">
                    Para una mejor visualización, se recomienda usar esta sección en versión web de escritorio.
                </span>
            </div>
        </div>

        <div>
            <hr>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PasoAPaso'
}
</script>

<style scoped>
cuadro-icono {
    width: 155px;
    height: 150px;
}

.cuadro-paso1 {
    width: 25%;
    /* height: 150px; */
    padding-top: 0px;
    padding-left: 5px;
    padding-right: 30px;
}

.cuadro-paso-a-paso {
    width: 25%;
    /* height: 150px; */
    padding-top: 0px;
    padding-left: 30px;
    padding-right: 30px;
    border-left: solid;
    border-left-color: rgb(240,240,16);
    border-left-width: 1px;
}

.separador-paso {
    vertical-align: top;
    padding-left: 20px;
    padding-right: 25px;
    text-align: right;
}

.icono-paso-a-paso {
    padding-right: 20px;
}

.titulo-paso {
    vertical-align: top;
    font-size: 1.4rem;
    font-weight: 800;
    text-align: left;
    margin: 0;
    padding: 0;
    color: #29efad;
}

.texto-paso {
    vertical-align: top;
    color: #fff;
    font-size: 0.9rem;
}

.nota {
    font-size: 0.9rem;
}
</style>