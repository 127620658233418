<template>
    <div class="footer-contenedor-componente">
        <img class="footer-logo" src="../assets/LogoMinisterio.png" height="50"/>
        <img class="footer-logo" src="../assets/logo-scd-130x56.png" height="50"/>
        <img class="footer-logo" src="../assets/logo-mustach-98x56.png" height="50"/>
        <img class="footer-logo" src="../assets/logo-imi-blanco-230x56.png" height="50"/>
        <img class="footer-logo" src="../assets/embodied.png" height="50"/>
        <img class="footer-logo" src="../assets/logo-chile-creativo-73x56.png" height="50"/>

        <div>
            <img class="footer-logo" src="../assets/logo-corfo-134x56.png" height="50"/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style scoped>
.footer-contenedor-componente {
    padding: 3rem 1.5rem 6rem;
    background-color: rgb(0,0,0);
    text-align: center;
    bottom: 0px;
    max-height: 250px;
    clear: both;
    margin-top: 150px;
}

.footer-logo {
    margin: 20px;
}
</style>