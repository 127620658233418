<template>
    <div class="container">
        <TituloPagina />
        <AyudaPasoAPaso />

        <br />
        <div class="container">
            <div class="row">
                <!-- <SelectorTagsArtistas excluidos="false" aprobados="true" chilenos="true"/> -->
                <SelectorGeneros excluidos="false" aprobados="true" chilenos="true"/>
                <SelectorArtistas excluidos="false" aprobados="true" chilenos="true"/>
                <CajaArtistasSeleccionados />
            </div>
        </div>
        <br /><br />
        <MenuVisualizaciones />
        <br />
        <VisualizacionSeguidoresArtistas />
        <VisualizacionPopularidadArtistas />
        <VisualizacionOyentesArtistas />
        <VisualizacionSeguidoresPopularidadArtistas />
        <VisualizacionOyentesPopularidadArtistas />
        <VisualizacionSeguidoresOyentesArtistas />
        <VisualizacionArtistasRelacionados />
        <FichaNodo />
        <FichasArtistas />
    </div>
</template>

<script>
import TituloPagina from "./TituloPagina.vue";
import AyudaPasoAPaso from "./AyudaPasoAPaso.vue";
// import SelectorTagsArtistas from "./SelectorTagsArtistas.vue";
import SelectorGeneros from "./SelectorGeneros.vue";
import SelectorArtistas from "./SelectorArtistas.vue";
import MenuVisualizaciones from "./MenuVisualizaciones.vue";
import VisualizacionSeguidoresArtistas from "./VisualizacionSeguidoresArtistas.vue";
import VisualizacionPopularidadArtistas from "./VisualizacionPopularidadArtistas.vue";
import VisualizacionOyentesArtistas from "./VisualizacionOyentesArtistas.vue";
import VisualizacionSeguidoresPopularidadArtistas from "./VisualizacionSeguidoresPopularidadArtistas.vue"
import VisualizacionOyentesPopularidadArtistas from "./VisualizacionOyentesPopularidadArtistas.vue"
import VisualizacionSeguidoresOyentesArtistas from "./VisualizacionSeguidoresOyentesArtistas.vue"
import VisualizacionArtistasRelacionados from "./VisualizacionArtistasRelacionados.vue";
import FichaNodo from "./FichaNodo.vue";
import FichasArtistas from "./FichasArtistas.vue";
import CajaArtistasSeleccionados from "./CajaArtistasSeleccionados.vue";

export default {
    name: "Estudio",

    components: {
        TituloPagina,
        AyudaPasoAPaso,
        // SelectorTagsArtistas,
        SelectorGeneros,
        SelectorArtistas,
        MenuVisualizaciones,
        VisualizacionSeguidoresArtistas,
        VisualizacionPopularidadArtistas,
        VisualizacionOyentesArtistas,
        VisualizacionSeguidoresPopularidadArtistas,
        VisualizacionOyentesPopularidadArtistas,
        VisualizacionSeguidoresOyentesArtistas,
        VisualizacionArtistasRelacionados,
        FichaNodo,
        FichasArtistas,
        CajaArtistasSeleccionados
    },

    mounted() {
        this.$gtag.pageview(this.$route)
    }
};
</script>
