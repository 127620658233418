<template>
    <div class="container">
        <TituloPagina />
        <form>
            <div class="vista-titulo">
                ¿Tienes una banda y aquella posee una cuenta Spotify?
            </div>

            <div class="contenedor-fila-formulario bajada texto-centro">
                Ayúdanos a mejorar nuestra información para que esté presente en los análisis de nuestra plataforma.
            </div>

            <br />
            <br />
            <div class="form-group">
                <label for="user_spotify_id" class="titulo-control">Id de tu banda en Spotify (copia solo el ID de la URL de tu perfil Spotify):</label>

                <input
                    id="user_spotify_id"
                    type="text"
                    class="form-control"
                    placeholder="Id en Spotify"
                />
                <br />
                <div class="titulo-control">
                    Ejemplo:
                </div>
                <img class="ayuda-imagen" src="../assets/ayuda-user-spotify-id-795x100.png" height="56"/>

                <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
                <br />
                <br />
                <br />
            </div>

            <div class="zona-botones">
                <button
                    type="reset"
                    class="boton boton-secundario"
                >
                    LIMPIAR
                </button>

                <button
                    type="submit"
                    class="boton"
                    v-on:keyup.enter="guardarFormulario()"
                    v-on:click.prevent="guardarFormulario()"
                >
                    REGISTRAR BANDA
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import axios from "axios";
import { urlAPI } from "../main.js";
import VueNotifications from 'vue-notifications';
import TituloPagina from "./TituloPagina.vue"

export default {
    name: "RegistroBandas",

    components: {
        TituloPagina
    },

    mounted() {
        this.$gtag.pageview(this.$route)
        
        if (document.getElementById("user_spotify_id")) {
            document.getElementById("user_spotify_id").focus();
        }
    },

    notifications: {
        alertaFaltanDatos: {
            type: VueNotifications.types.error,
            title: 'Faltan datos',
            message: 'Por favor, complete todos los datos para poder registrar su banda.'
        },

        alertaBandaYaExiste: {
            type: VueNotifications.types.warn,
            title: 'Banda ya existe',
            message: 'Su banda ya está registrada.'
        },

        alertaRegistroExitoso: {
            type: VueNotifications.types.success,
            title: 'Registro exitoso',
            message: 'Su banda ha sido registrada exitosamente.'
        }
    },

    methods: {
        guardarFormulario() {
            if (document.getElementById("user_spotify_id").value != "") {
                axios
                    .post(urlAPI + "/api/artistas/userspotifyid", {
                        user_spotify_id: document.getElementById("user_spotify_id").value
                    })
                    .then((respuesta) => {
                        if (respuesta.data['Operacion'] == 'OK') {
                            this.$gtag.event('Registrar nueva banda o artista', { method: 'Google' })

                            this.alertaRegistroExitoso()
                        } else {
                            this.alertaBandaYaExiste()
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        
                    });
            } else {
                this.alertaFaltanDatos()
            }
        },
    },
};
</script>

<style scoped>
.ayuda-imagen {
    margin-top: 10px;
}

.bajada {
    color: #ff0;
    font-size: 1.1rem;
}

.titulo-control {
    font-size: 1.2rem;
    color: #fff;
    margin-top: 20px;
}

.zona-botones {
    text-align: center;
}
</style>
