//En ComboBox múltiples, se eliminan todas las selecciones; para ComboBox simples, se selecciona el primer elemento.
function ComboboxClear(idObjeto) {
    let objeto = document.getElementById(idObjeto);

    if (objeto != null) {
        let numElementos = Number(objeto.options.length);

        if (numElementos > 0) {
            for (let i = 0; i < numElementos; i++) {
                objeto.options[i].selected = false;
            }
        } else {
            // objeto.value = this.comboBoxElementsId[0];
        }
    }
}

function ComboboxEmpty(idObjeto) {
    let objeto = document.getElementById(idObjeto);
    let numElementos = Number(objeto.options.length);
    let elemento;

    for (let i = 0; i < numElementos; i++) {
        elemento = document.getElementById(idObjeto);
        elemento.remove(elemento.options[i]);
    }

    objeto.comboBoxElementsId = [];
    objeto.Length = 0;
}

function ComboboxSetSelected(idObjeto, listaElementos) {
    //Acepta listas, valor simple, números y cadena.
    let objeto = document.getElementById(idObjeto);
    let numElementos = Number(objeto.options.length);

    if (numElementos == 1) {
        if (typeof listaElementos === "number") {
            objeto.value = objeto.comboBoxElementsId[listaElementos - 1];
        } else {
            objeto.value = listaElementos;
        }
    } else {
        //ComboBox múltiple.
        ComboboxClear(idObjeto);
        if (typeof listaElementos === "number") {
            //No es una lista, sino solo un valor numérico.
            objeto.options[listaElementos - 1].selected = true;
        } else {
            let elementos = listaElementos.split(",");
            let posicionElemento;

            for (let elemento of elementos) {
                if (!isNaN(parseInt(elemento))) {
                    //Elemento es un número de posición.
                    posicionElemento = elemento;
                } else {
                    //Elemento es un id.
                    for (let i = 0; i < this.comboBoxElementsId.length; i++) {
                        if (elemento === this.comboBoxElementsId[i]) {
                            posicionElemento = i + 1;
                            break;
                        }
                    }
                }

                objeto.options[posicionElemento - 1].selected = true;
            }
        }
    }
}

function ComboboxAddSelected(idObjeto, listaElementos) {
    //Método para ComboBox Múltiples. Acepta listas, valor simple, números y cadena.
    let objeto = document.getElementById(idObjeto);
    let numElementos = Number(objeto.options.length);

    if (numElementos > 0) {
        if (typeof listaElementos === "number") {
            //No es una lista, sino solo un valor numérico.
            objeto.options[listaElementos - 1].selected = true;
        } else {
            let elementos = listaElementos.split(",");
            let elementPosition;

            for (let elemento of elementos) {
                if (!isNaN(parseInt(elemento))) {
                    //Elemento es un número de posición.
                    elementPosition = elemento;
                } else {
                    //Elemento es un id.
                    for (let i = 0; i < this.comboBoxElementsId.length; i++) {
                        if (elemento === this.comboBoxElementsId[i]) {
                            elementPosition = i + 1;
                            break;
                        }
                    }
                }

                objeto.options[elementPosition - 1].selected = true;
            }
        }
    }
}

function ComboboxGetSelected(idObjeto) {
    //Para ListBox, si ningún elemento está seleccionado, se devuelve False.
    let objeto = document.getElementById(idObjeto);
    let numElementos = Number(objeto.options.length);

    if (numElementos == 1) {
        return objeto.value;
    } else {
        let selectedItems = [];

        for (let i = 0; i < numElementos; i++) {
            if (objeto.options[i].selected) {
                selectedItems.push(objeto.options[i].value);
            }
        }

        if (selectedItems.length > 0) {
            return selectedItems;
        } else {
            return false;
        }
    }
}

function ComboboxGetSelectedNumber(idObjeto) {
    //Para ListBox, si ningún elemento está seleccionado, se devuelve False.
    let objeto = document.getElementById(idObjeto);
    let numElementos = Number(objeto.options.length);

    let selectedItems = [];

    for (let i = 0; i < numElementos; i++) {
        if (objeto.options[i].selected) {
            selectedItems.push(i + 1);
        }
    }

    if (selectedItems.length > 0) {
        return selectedItems;
    } else {
        return false;
    }
}

function ComboboxGetElementsNumber(idObjeto) {
    let objeto = document.getElementById(idObjeto);
    return Number(objeto.options.length);
}

//Devuelve una cadena con los caption de los elementos seleccionados. En un listbox, si ningún elemento está seleccionado, se devuelve False.
function ComboboxGetSelectedCaption(idObjeto) {
    let objeto = document.getElementById(idObjeto);
    let numElementos = Number(objeto.options.length);

    if (numElementos == 1) {
        for (let i = 0; i < numElementos; i++) {
            if (objeto.options[i].selected) {
                return String(objeto.options[i].text);
            }
        }
        return false;
    } else {
        let selectedItems = [];

        for (let i = 0; i < numElementos; i++) {
            if (objeto.options[i].selected) {
                selectedItems.push(objeto.options[i].text);
            }
        }

        if (selectedItems.length > 0) {
            return String(selectedItems);
        } else {
            return false;
        }
    }
}

module.exports = {
    ComboboxClear,
    ComboboxEmpty,
    ComboboxSetSelected,
    ComboboxAddSelected,
    ComboboxGetSelected,
    ComboboxGetSelectedNumber,
    ComboboxGetSelectedCaption,
    ComboboxGetElementsNumber
};
