<template>
    <div>
        <br />
        <br />
        <div>
            <section v-if="errores">
                <p class="gris">
                    Lo sentimos, no es posible obtener la información en este
                    momento. Por favor intente nuevamente más tarde.
                </p>
            </section>

            <section v-else>
                <table class="table table-dark bg-negro">
                    <caption>Documentos disponibles para descarga</caption>
                    <thead>
                        <tr class="d-flex">
                            <th scope="col" class="col-4 bg-negro amarillo">Nombre</th>
                            <th scope="col" class="col-2 bg-negro amarillo">Tags</th>
                            <th scope="col" class="col-2 bg-negro amarillo">Origen</th>
                            <th scope="col" class="col-2 bg-negro amarillo texto-centro">Publicación</th>
                            <th scope="col" class="col-2 bg-negro amarillo texto-centro">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="d-flex" v-for="(documento) in documentosFiltradosPorTagNombre"
                            v-bind:key="documento.id"
                        >
                            <td class="col-4">{{ documento.nombre }}</td>
                            <td class="col-2">
                                <div
                                    v-for="tag in documento.tags.split(',')"
                                    v-bind:key="tag.id"
                                    class="lista-documentos-contenedor-tag"
                                >
                                    <span class="lista-documentos-tag">
                                        {{ tag }}
                                    </span>
                                </div>
                            </td>
                            <td class="col-2">{{ documento.origen }}</td>
                            <td class="col-2 texto-centro">
                                {{ documento.fecha_publicacion | formatear_fecha }}
                            </td>
                            <td class="col-2 texto-centro">
                                <a
                                    v-bind:href="'https://www.api.odmc.cl/' + documento.link"
                                    target="_blank"
                                >
                                    <img
                                        src="../assets/nube-30x30.png"
                                        v-on:click="descargarDocumento(documento.id, $store.getters.id)"
                                        class="icono-accion"
                                    />
                                </a>

                                <div>
                                    <input
                                        v-bind:id="'listaDocumento' + documento.id"
                                        type="text"
                                        v-bind:value="'https://www.api.odmc.cl/' + documento.link"
                                        style="width: 30px"
                                        hidden
                                    >
                                    
                                    <img
                                        src="../assets/link-30x30.png"
                                        v-on:click="copiarAlPortapapeles('listaDocumento' + documento.id)"
                                        class="icono-accion"
                                    />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>            
            </section>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { bus } from "../main.js";
import { urlAPI } from "../main.js";

export default {
    name: "ListaDocumentos",

    data() {
        return {
            cargando: true,
            errores: false,
            documentosFiltradosPorTagNombre: [],
            documentos: [],
        };
    },

    filters: {
        formatear_fecha(fecha) {
            let fechaFormateada = new Date(fecha);

            return (
                fechaFormateada.getDate() +
                "-" +
                (fechaFormateada.getMonth() + 1) +
                "-" +
                fechaFormateada.getFullYear()
            );
        },
    },

    mounted() {
        this.leerDocumentos();

        bus.$on("tagsDocumentosSeleccionados", (tags) => {
            this.filtrarDocumentosPorTags(tags);
        });

        bus.$on("agujaBuscarDocumentosDefinida", (aguja) => {
            this.filtrarDocumentosPorAguja(aguja);
        });
    },

    methods: {
        leerDocumentos() {
            axios
                .get(urlAPI + "/api/v1/documentos")
                .then((respuesta) => {
                    this.documentos = respuesta.data;
                    this.documentosFiltradosPorTagNombre = respuesta.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.errores = true;
                })
                .finally(() => {
                    this.cargando = false;
                });
        },

        filtrarDocumentosPorTags(tags) {
            if (tags != '') {
                this.documentosFiltradosPorTagNombre = []
                let documentosFiltrados = []

                for (let documento of this.documentos) {
                    if (documento.tags != '') {
                        let listaTags = documento.tags.split(',')
                        
                        for (let tag of listaTags) {
                            if (tags.indexOf(tag) != -1) {
                                documentosFiltrados.push(documento)
                                break
                            }
                        }
                    }
                }   

                this.documentosFiltradosPorTagNombre = documentosFiltrados.slice()
            } else {
                this.documentosFiltradosPorTagNombre = this.documentos
            }
        },

        filtrarDocumentosPorAguja(aguja) {
            if (aguja != '') {
                this.documentosFiltradosPorTagNombre = []
                let documentosFiltrados = []

                for (let documento of this.documentos) {
                    if (documento.nombre.toUpperCase().indexOf(aguja.toUpperCase()) != -1) {
                        documentosFiltrados.push(documento)
                    }
                }

                this.documentosFiltradosPorTagNombre = documentosFiltrados.slice()
            } else {
                this.documentosFiltradosPorTagNombre = this.documentos
            }
        },

        descargarDocumento(idDocumento, idUsuarioIdentificado) {
            this.$gtag.event('Descargar documento general', { method: 'Google' })

            let fechaDescarga = new Date()

            axios
                .put(urlAPI + "/api/documentos/contabilizarDescarga", {
                    idDocumento: idDocumento,
                    idUsuario: idUsuarioIdentificado,
                    fechaDescarga: fechaDescarga
                })
                .then(function(respuesta) {
                    console.log(respuesta);
                })
                .catch(function(error) {
                    console.log(error);
                })
                .finally(() => {
                    
                });
        },

        copiarAlPortapapeles(idElemento) {
            //Crear un campo de texto "oculto"
            var nodoNuevo = document.createElement("input")
            nodoNuevo.setAttribute("id", "campoTemporalOculto")

            //Añadir el campo a la página
            document.body.appendChild(nodoNuevo)

            //Asignar el contenido del elemento especificado al valor del campo
            document.getElementById("campoTemporalOculto").value = document.getElementById(idElemento).value

            //Seleccionar el contenido del campo
            nodoNuevo.select()

            //Copiar el texto seleccionado
            document.execCommand("copy");

            //Eliminar el campo de la página
            document.body.removeChild(nodoNuevo);
        }
    },
};
</script>

<style scoped>
.lista-documentos-contenedor-tag {
    margin-bottom: 10px;
}

.lista-documentos-tag {
    color: rgba(0, 0, 0, 0.7);
    background-color: #29efad;
    font-size: 0.7rem;
    padding: 5px 10px;
    border-radius: 5px;
}

.icono-accion {
    margin-bottom: 20px;
    cursor: pointer;
}
</style>
