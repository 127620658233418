<template>
    <div class="container">
        <TituloPagina />
        
        <ClasificacionDocumentosPorNumeroDescargas></ClasificacionDocumentosPorNumeroDescargas>
        <ClasificacionDocumentosPorMenosNumeroDescargas></ClasificacionDocumentosPorMenosNumeroDescargas>
    </div>
</template>

<script>
import ClasificacionDocumentosPorNumeroDescargas from "./ClasificacionDocumentosPorNumeroDescargas.vue";
import ClasificacionDocumentosPorMenosNumeroDescargas from "./ClasificacionDocumentosPorMenosNumeroDescargas.vue";
import TituloPagina from "./TituloPagina.vue"

export default {
    name: "MetricasApp",

    components: {
        TituloPagina,
        ClasificacionDocumentosPorNumeroDescargas,
        ClasificacionDocumentosPorMenosNumeroDescargas
    },
};
</script>
