<template>
    <div class="container">
        <TituloPagina />

        <form>
            <div class="row">
                <span class="blanco nota">
                    Si quieres recibir información nuestra, puedes suscribirte aquí:
                </span>
            </div>

            <br />
            <div class="row">
                <div class="col gris">
                    Nombres
                </div>
                <div class="col gris">
                    Apellidos
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <input
                        id="usuario_nombres"
                        type="text"
                        class="form-control"
                        placeholder="Nombres"
                    />
                </div>
                <div class="col">
                    <input
                        id="usuario_apellidos"
                        type="text"
                        class="form-control"
                        placeholder="Apellidos"
                    />
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col gris">
                    Apodo
                </div>
                <div class="col gris">
                    Clave
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <input
                        id="usuario_apodo"
                        type="text"
                        class="form-control"
                        placeholder="Nombre de usuario"
                    />
                </div>
                <div class="col">
                    <input
                        id="usuario_clave"
                        type="password"
                        class="form-control"
                        placeholder="Clave"
                    />
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col gris">
                    Email
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <input
                        id="usuario_email"
                        type="email"
                        class="form-control"
                        aria-describedby="observacion"
                        placeholder="Email"
                    />
                    <!-- <small id="observacion" class="form-text text-muted">Si ingresas todos los datos y el nombre de usuario está disponible, podrás guardar tu registro.</small> -->
                </div>
                <div class="col">
                </div>
            </div>
            <br />
            <button
                type="reset"
                class="boton boton-secundario"
            >
                LIMPIAR
            </button>

            <button
                type="submit"
                class="boton"
                v-on:keyup.enter="verificarFormulario()"
                v-on:click.prevent="verificarFormulario()"
            >
                SUSCRIBIRSE
            </button>
        </form>
        <br />
        <br />
    </div>
</template>

<script>
import axios from "axios";
import { urlAPI } from "../main.js";
import VueNotifications from 'vue-notifications';
import TituloPagina from "./TituloPagina.vue"

export default {
    name: "RegistroUsuarios",

    data() {
        return {
            errores: false,
            apodoExiste: false
        };
    },

    mounted() {
        this.$gtag.pageview(this.$route)
        
        this.limpiarFormulario()

        document.getElementById("usuario_nombres").focus();
    },

    components: {
        TituloPagina
    },

    notifications: {
        alertaApodoYaExiste: {
            type: VueNotifications.types.warn,
            title: 'Apodo ya existe',
            message: 'Este apodo ya existe. Debe escoger otro.'
        },

        alertaFaltanDatos: {
            type: VueNotifications.types.error,
            title: 'Faltan datos',
            message: 'Por favor, complete todos los datos para poder registrarse.'
        }
    },

    methods: {
        limpiarFormulario() {
            document.getElementById("usuario_nombres").value = ""
            document.getElementById("usuario_apellidos").value = ""
            document.getElementById("usuario_apodo").value = ""
            document.getElementById("usuario_clave").value = ""
            document.getElementById("usuario_email").value = ""
        },

        guardarFormulario() {
            axios
                .post(urlAPI + "/api/usuarios", {
                    nombres: document.getElementById("usuario_nombres").value,
                    apellidos: document.getElementById("usuario_apellidos").value,
                    apodo: document.getElementById("usuario_apodo").value,
                    clave: document.getElementById("usuario_clave").value,
                    email: document.getElementById("usuario_email").value,
                    tipo_usuario: "corriente"
                })
                .then((respuesta) => {
                    console.log(respuesta)

                    this.$gtag.event('Registrarse como nuevo usuario', { method: 'Google' })

                    this.$store.dispatch('verificarLogin', {
                        apodo: document.getElementById("usuario_apodo").value,
                        clave: document.getElementById("usuario_clave").value
                    })
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    
                });
        },

        verificarFormulario() {
            if (document.getElementById("usuario_apodo").value != "") {
                this.apodoExiste = false

                axios
                    .get(urlAPI + "/api/v1/usuarios/apodo/existe", {
                        params: {
                            apodo: document.getElementById("usuario_apodo").value
                        }
                    })
                    .then((respuesta) => {
                        this.apodoExiste = respuesta.data.apodousuarioexiste;

                        if (this.apodoExiste == false) {
                            if (document.getElementById("usuario_nombres").value != ""
                                && document.getElementById("usuario_apellidos").value != ""
                                && document.getElementById("usuario_apodo").value != ""
                                && document.getElementById("usuario_clave").value != ""
                                && document.getElementById("usuario_email").value != "") {
                                
                                this.guardarFormulario()
                            } else {
                                this.alertaFaltanDatos()
                            }
                        } else {
                            this.alertaApodoYaExiste()
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.errores = true;
                    })
                    .finally(() => {
                        
                    });
            } else {
                this.alertaFaltanDatos()
            }
        },
    },
};
</script>

<style scoped>
.nota {
    font-size: 0.9rem;
}
</style>