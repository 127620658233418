import Observatorio from './components/Observatorio.vue'
import Contacto from './components/Contacto.vue'
import MusicaChile from './components/MusicaChile.vue'
import Estudio from './components/Estudio.vue'
import Biblioteca from './components/Biblioteca.vue'
import RegistroUsuario from './components/RegistroUsuarios.vue'
import RegistroBandas from './components/RegistroBandas.vue'
import AdministrarTags from './components/AdministrarTags.vue'
import AdministrarDocumentos from './components/AdministrarDocumentos.vue'
import AdministrarArtistas from './components/AdministrarArtistas.vue'
import BaseDatos from './components/BaseDatos.vue'
import MetricasApp from './components/MetricasApp.vue'

export const rutas = [
  { path: '', component: MusicaChile },
  { path: '/observatorio', component: Observatorio },
  { path: '/biblioteca', component: Biblioteca },
  { path: '/contacto', component: Contacto },
  { path: '/musicachile', component: MusicaChile },
  { path: '/estudio', component: Estudio },
  { path: '/registrousuario', component: RegistroUsuario },
  { path: '/registrobanda', component: RegistroBandas },
  { path: '/administrartags', component: AdministrarTags },
  { path: '/administrardocumentos', component: AdministrarDocumentos },
  { path: '/administrarartistas', component: AdministrarArtistas },
  { path: '/basedatos', component: BaseDatos },
  { path: '/metricasapp', component: MetricasApp }
]