<template>
    <div>
        <div class="row">
            <div class="col-2 blanco etiqueta">
                Desde:
            </div>
            <div class="col blanco etiqueta">
                Hasta:
            </div>
        </div>

        <div class="row">
            <div class="col-2">
                <div class="form-group">
                    <select class="form-control form-control-sm combobox" id="SelectorFechas_cboCota1">
                        <option
                            v-for="(fecha, indice) in fechas"
                            v-bind:key="indice"
                        >
                            {{ fecha.fecha_muestra.substring(0,10) }}
                        </option>
                    </select>
                </div>
            </div>
            
            <div class="col-2">
                <div class="form-group">
                    <select class="form-control form-control-sm combobox" id="SelectorFechas_cboCota2">
                        <option
                            v-for="(fecha, indice) in fechas"
                            v-bind:key="indice"
                        >
                            {{ fecha.fecha_muestra.substring(0,10) }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="col">
                <div class="form-group">
                    <button
                        type="submit"
                        class="boton-selector-fechas boton-selector-fechas-secundario"
                        v-on:click.prevent="publicarRangoFechas()"
                    >
                        <img
                            src="../assets/play-blanco.svg"
                            class="boton-accion-icono"
                        />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import { urlAPI } from "../main.js"
import Combobox from "../Combobox.js";
import { bus } from "../main.js"

export default {
    name: "SelectorFechas",

    data() {
        return {
            fechas: [],
        };
    },

    mounted() {
        this.leerFechas()
    },

    methods: {
        leerFechas() {

            axios
                .get(urlAPI + "/api/v1/artistas/fechasmuestras")
                .then((respuesta) => {
                    this.fechas = respuesta.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.errores = true;
                })
                .finally(() => {
                    this.cargando = false;
                });
        },

        publicarRangoFechas(modificador = null) {
            let fechaDesde
            let fechaHasta
            let rango = modificador

            if (rango == null) {
                fechaDesde = Combobox.ComboboxGetSelectedCaption("SelectorFechas_cboCota1");
                fechaHasta = Combobox.ComboboxGetSelectedCaption("SelectorFechas_cboCota2");

                rango = fechaDesde + "," + fechaHasta
            } else {
                rango = null
            }

            bus.$emit("rangoFechasSeleccionado", rango);
        }
    }
}
</script>

<style scoped>
.etiqueta {
    font-size: 0.9rem;
}
.boton-selector-fechas {
    font-size: 0.8rem;
    font-weight: 800;
    color: rgba(0, 0, 0, 0.7);
    background-color: rgb(240,240,16);
    border-style: none;
    margin-right: 10px;
    margin-top: 0px;
    padding: 6px 20px;
    border-radius: 1px;
    text-decoration: none;
}

.boton-selector-fechas-secundario {
    color: #29efad;
    background-color:  rgb(33,32,32);
}
</style>