<template>
    <div class="container">
        <TituloPagina />
        
        <div class="vista-titulo">
            Observatorio Digital de la Música Chilena
        </div>

        <br />
        <br />
        <div class="parrafo">
            El Observatorio Digital de la Música Chilena (ODMC) tiene como
            objetivo principal poner a libre disposición un sistema de
            información continua del sector de la música nacional. Lo anterior
            responde a la necesidad de desarrollar una cultura de registro
            digital que permita orientar la toma de decisiones de los actores
            del ecosistema.
        </div>

        <div class="parrafo">
            En esta plataforma podrás encontrar estudios de distinta índole,
            construidos a partir de datos provenientes de diversas fuentes de la
            industria: músicos, instituciones, empresas, gremios, plataformas de
            streaming, etcétera. Consideramos que toda la información recopilada
            resulta de vital importancia para el desarrollo sostenible de la
            industria.
        </div>

        <div class="parrafo">
            Esta iniciativa es financiada por el Fondo de Bienes Públicos de
            InnovaChile -CORFO. Su directorio está compuesto por un
            representante de las siguientes entidades:
        </div>

        <div class="logos">
            <img class="footer-logo" src="../assets/LogoMinisterio.png" height="30"/>
            <img class="footer-logo" src="../assets/logo-scd-130x56.png" height="30"/>
            <img class="footer-logo" src="../assets/logo-mustach-98x56.png" height="30"/>
            <img class="footer-logo" src="../assets/logo-imi-blanco-230x56.png" height="30"/>
            <img class="footer-logo" src="../assets/embodied.png" height="30"/>
            <img class="footer-logo" src="../assets/logo-chile-creativo-73x56.png" height="30"/>
        </div>
    </div>
</template>

<script>
import TituloPagina from "./TituloPagina.vue";

export default {
    name: "Observatorio",

    components: {
        TituloPagina
    },

    mounted() {
        this.$gtag.pageview(this.$route)
    } 
};
</script>

<style>
.parrafo {
    color: whitesmoke;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    margin-bottom: 20px;
}

.logos {
    padding: 2rem 4.5rem 0rem;
    text-align: center;
    clear: both;
}

.footer-logo {
    margin: 20px;
}
</style>
