<template>
    <div class="col-3">
        <div class="titulo-filtro separador">
            TAGS
        </div>

        <div v-if="cargando">
            <img src="../assets/loading.gif" width="200" />
        </div>

        <div v-else>
            <span v-for="tag in tags" v-bind:key="tag.id">
                <button
                    class="boton-tag"
                    v-bind:class="{
                        'boton-tag-seleccionado': tag.seleccionado,
                    }"
                    v-on:click.prevent="seleccionarTag(tag.id)"
                >
                    {{ tag.nombre }}
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import { bus } from "../main.js";
import axios from "axios";
import { urlAPI } from "../main.js";

export default {
    name: "TagsArtistas",

    data() {
        return {
            cargando: false,
            tags: [],
            listaTagsSeleccionados: [],
            tagsSeleccionados: "",
        };
    },

    props: {
        excluidos: String,
        aprobados: String,
        chilenos: String
    },

    mounted() {
        this.leerTags();
    },
    
    methods: {
        leerTags() {
            this.cargando = true
            
            axios
                .get(urlAPI + "/api/v1/artistas/tags", {
                    params: {
                        excluidos: this.excluidos,
                        aprobados: this.aprobados,
                        chilenos: this.chilenos
                    }
                })
                .then((respuesta) => {
                    let i = 0

                    for (let tag of respuesta.data) {
                        this.tags.push({
                            id: i,
                            nombre: tag.trim(),
                            seleccionado: false,
                        })

                        i++
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.serieTagsSeleccionados = ""
                    this.cargando = false

                    // bus.$emit("tagsArtistasSeleccionados", this.serieTagsSeleccionados);
                });
        },

        seleccionarTag(tagId) {
            this.tags.find(
                (tag) => tag.id == tagId
            ).seleccionado = !this.tags.find((tag) => tag.id == tagId).seleccionado;

            //Actualizar lista de tags seleccionados.
            this.listaTagsSeleccionados = this.tags
                .filter((tag) => tag.seleccionado)
                .slice();

            this.serieTagsSeleccionados = "";

            for (let tag of this.listaTagsSeleccionados) {
                if (this.serieTagsSeleccionados != "") {
                    this.serieTagsSeleccionados += ",";
                }

                this.serieTagsSeleccionados += tag.nombre;
            }
            
            bus.$emit("tagsArtistasSeleccionados", this.serieTagsSeleccionados);
        },
    },
};
</script>

<style scoped>
.boton-tag {
    background-color: rgb(33,32,32);
    border-color: transparent;
    color: #29efad;
    border-width: 1px;
    cursor: pointer;
    justify-content: center;
    padding-top: 6px;
    padding-right: 10px;
    padding-bottom: 6px;
    padding-left: 10px;
    text-align: center;
    white-space: nowrap;
    font-size: 0.9rem;
    border-radius: 0px;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
}

.boton-tag:hover {
    background-color: rgb(240,240,16);
    color: rgb(47, 47, 47);
}

.boton-tag-seleccionado {
    background-color:rgb(240,240,16);
    color: rgb(47, 47, 47);
}

.separador {
    margin-bottom: 25px !important;
}
</style>
