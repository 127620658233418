<template>
    <div class="container">
        <TituloPagina />

        <form>
            <div class="row">
                <div class="col gris">
                    Nombre
                </div>
                <div class="col gris">
                    Email
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <input
                        id="usuario_nombre"
                        type="text"
                        class="form-control"
                        placeholder="Nombre"
                    />
                </div>
                <div class="col">
                    <input
                        id="usuario_email"
                        type="email"
                        class="form-control"
                        placeholder="Email"
                    />
                </div>
            </div>
            <br />
            <div class="row">
                <div class="col gris">
                    Mensaje
                </div>
            </div>
            
            <div class="row">
                <div class="col">
                    <textarea
                        id="usuario_mensaje"
                        class="form-control"
                        rows="6"
                    >
                    </textarea>
                </div>
                <div class="col">
                </div>
            </div>
            <br />

            <button
                type="reset"
                class="boton boton-secundario"
            >
                LIMPIAR
            </button>

            <button
                type="submit"
                class="boton"
                v-on:keyup.enter="enviarCorreo()"
                v-on:click.prevent="enviarCorreo()"
            >
                ENVIAR
            </button>

            <br /><br /><br />
            <span class="blanco nota">
                Si lo prefieres, puedes escribirnos a: info@odmc.cl
            </span>
        </form>
        <br />
        <br />
    </div>
</template>

<script>
import axios from "axios";
import { urlAPI } from "../main.js";
import VueNotifications from 'vue-notifications';
import TituloPagina from "./TituloPagina.vue"

export default {
    name: "Contacto",

    data() {
        return {
            errores: false,
        };
    },

    mounted() {
        this.$gtag.pageview(this.$route)

        this.limpiarFormulario()

        document.getElementById("usuario_nombre").focus();
    },

    components: {
        TituloPagina
    },

    notifications: {
        alertaFaltanDatos: {
            type: VueNotifications.types.error,
            title: 'Faltan datos',
            message: 'Por favor, complete todos los datos.'
        },

        alertaCorreoEnviado: {
            type: VueNotifications.types.success,
            title: 'Envío exitoso',
            message: 'Mensaje enviado exitosamente.'
        }
    },

    methods: {
        limpiarFormulario() {
            document.getElementById("usuario_nombre").value = ""
            document.getElementById("usuario_email").value = ""
            document.getElementById("usuario_mensaje").value = ""
        },

        enviarCorreo() {
            if (
                document.getElementById("usuario_nombre").value != ""
                && document.getElementById("usuario_email").value != ""
                && document.getElementById("usuario_mensaje").value != ""
            ) {
                axios
                    .post(urlAPI + "/api/enviaremail", {
                        nombre: document.getElementById("usuario_nombre").value,
                        email: document.getElementById("usuario_email").value,
                        mensaje: document.getElementById("usuario_mensaje").value
                    })
                    .then(() => {
                        this.$gtag.event('Envío de email desde página Contacto', { method: 'Google' })

                        this.alertaCorreoEnviado()

                        this.limpiarFormulario()
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        
                    });
            } else {
                this.alertaFaltanDatos()
            }
        },
    },
};
</script>

<style scoped>
.nota {
    font-size: 0.9rem;
}
</style>

