<template>
    <div class="container">
        <span v-if="$store.getters.numCuadroDashboardSeleccionado == '5'">
            <div v-if="cargando">
                <img src="../assets/loading.gif" width="200" />
            </div>
            
            <div v-else>
                <canvas id="visualizacionOyentesIndustria"></canvas>

                <div class="container">
                    <br />
                    <div class="row">
                        <span class="amarillo">* </span>
                        <span class="blanco nota">
                            Pulsa los cuadros de colores para mostrar/ocultar una curva.
                        </span>
                    </div>
                </div>
            </div>
        </span>
    </div>
</template>

<script>
import { bus } from "../main.js";
import axios from "axios";
import { urlAPI } from "../main.js";
import Chart from 'chart.js'
import SimpleLinearRegression from 'ml-regression-simple-linear'

export default {
    name: "VisualizacionOyentesIndustria",
    
    data() {
        return {
            cargando: false,
            serieTiempoOyentesIndustria: [],
            OyentesPromedioIndustria: 0,
            numValores: 0,
            fechaDesde: null,
            fechaHasta: null,
            backgroundcolor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(240, 240, 16, 0.2)', //amarillo sistema
                'rgba(41, 239, 173, 0.2)', //verde sistema
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                '#000',
            ],
            color: [
                'rgba(255, 99, 132, 1)', //rojo
                'rgba(54, 162, 235, 1)', //azul
                'rgba(240, 240, 16, 1)', //amarillo sistema
                'rgba(41, 239, 173, 1)', //verde sistema
                'rgba(153, 102, 255, 1)', //morado
                'rgba(255, 159, 64, 1)', //naranjo
            ]
        };
    },

    mounted() {
        this.$nextTick(() => {
            if (this.$store.getters.numCuadroDashboardSeleccionado == '5') {
                this.leerOyentesPromedioIndustria()
            }
        })

        bus.$on("cuadroDashboardSeleccionado", () => {
            if (this.$store.getters.numCuadroDashboardSeleccionado == '5') {
                this.leerOyentesPromedioIndustria()
            }
        });

        bus.$on("rangoFechasSeleccionado", (fechas) => {
            if (this.$store.getters.numCuadroDashboardSeleccionado == '5') {
                if (fechas != null) {
                    this.fechaDesde = fechas.split(",")[0]
                    this.fechaHasta = fechas.split(",")[1]
                } else {
                    this.fechaDesde = null
                    this.fechaHasta = null
                }

                this.leerOyentesPromedioIndustria()
            }
        });
    },

    methods: {
        async leerOyentesPromedioIndustria() {
            this.cargando = true

            await axios
                .get(urlAPI + "/api/v1/artistas/oyentespromedioindustria", {
                    params: {
                        fechaDesde: this.fechaDesde,
                        fechaHasta: this.fechaHasta
                    }
                })
                .then((respuesta) => {
                    this.oyentesPromedioIndustria = respuesta.data[0].oyentes_promedio_periodo
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });

            this.leerSerieTiempoOyentesIndustria()
        },

        async leerSerieTiempoOyentesIndustria() {
            this.cargando = true

            await axios
                .get(urlAPI + "/api/v1/artistas/serietiempooyentesindustria", {
                    params: {
                        fechaDesde: this.fechaDesde,
                        fechaHasta: this.fechaHasta
                    }
                })
                .then((respuesta) => {
                    this.serieTiempoOyentesIndustria = respuesta.data

                    this.numValores = respuesta.data.length
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });

            this.visualizar()
        },
        
        visualizar() {
            let etiquetasEjeX = []
            let valoresOyentesPorFechas = []
            let valoresOyentesPeriodo = []
            let valoresFechasToNumeroNaturalEjeX = []
            let valoresOriginalesPorFechas = []
            let prediccionTendencia = []

            //Armar arreglos de valores a graficar.
            this.serieTiempoOyentesIndustria.forEach(elemento => {
                etiquetasEjeX.push(elemento.fecha_muestra.substring(0,10))
                valoresFechasToNumeroNaturalEjeX.push(Number(Date.parse(elemento.fecha_muestra.substring(0,10)) / (1000*60*60*24) - Date.parse(this.serieTiempoOyentesIndustria[0].fecha_muestra.substring(0,10)) / (1000*60*60*24)))

                if (elemento.oyentes_promedio_fecha !== null) {
                    if (Number(elemento.oyentes_promedio_fecha) > 0) {
                        valoresOyentesPorFechas.push(Number(elemento.oyentes_promedio_fecha).toFixed(1))
                        valoresOriginalesPorFechas.push(Number(elemento.oyentes_promedio_fecha))
                    } else {
                        valoresOyentesPorFechas.push(null)
                        valoresOriginalesPorFechas.push(null)
                    }
                } else {
                    valoresOyentesPorFechas.push(null)
                }

                if (this.oyentesPromedioIndustria !== null) {
                    if (Number(this.oyentesPromedioIndustria) > 0) {
                        valoresOyentesPeriodo.push(Number(this.oyentesPromedioIndustria).toFixed(1))
                    } else {
                        valoresOyentesPeriodo.push(null)
                    }
                } else {
                    valoresOyentesPeriodo.push(null)
                }
            })

            const regression = new SimpleLinearRegression(valoresFechasToNumeroNaturalEjeX, valoresOriginalesPorFechas)

            //Teniendo la regresión lineal calculada, se predicen los valores para cada fecha (convertida a número).
            valoresFechasToNumeroNaturalEjeX.forEach(elemento => {
                prediccionTendencia.push(Number(regression.predict(elemento)))
            })

            //Crear gráfico Oyentes industria.
            var lienzoGraficoOyentes = document.getElementById('visualizacionOyentesIndustria').getContext('2d');
            var visualizacionOyentesIndustria = new Chart(lienzoGraficoOyentes, {
                type: 'line',
                data: {
                    labels: etiquetasEjeX,
                    datasets: [
                        {
                            label: 'Promedio en fechas',
                            data: valoresOyentesPorFechas,
                            borderWidth: 2,
                            fill: false,
                            borderColor: this.color[3],
                        },

                        {
                            label: 'Tendencia',
                            data: prediccionTendencia,
                            borderWidth: 1,
                            fill: false,
                            borderColor: this.color[0],
                        },

                        {
                            label: 'Promedio del período',
                            data: valoresOyentesPeriodo,
                            borderWidth: 1,
                            fill: false,
                            borderColor: this.color[2],
                        },
                    ]
                },
                options: {
                    responsive: true,
                    title: {
                        display: true,
                        text: 'Oyentes promedio',
                        fontColor: '#fff',
                        fontSize: '34'
                    },
                    legend: {
                        labels: {
                            fontColor: '#fff',
                            fontSize: 20,
                        }
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                fontColor: '#fff',
                                fontSize: 18,
                            },
                        }],
                        yAxes: [{
                            ticks: {
                                beginAtZero: false,
                                fontColor: '#fff',
                                fontSize: 18,
                            },
                            gridLines: {
                                display:true,
                                color: 'rgba(255,255,255,0.2)'
                            }
                        }]
                    }
                }
            });

            console.log(visualizacionOyentesIndustria)
        }
    }
}
</script>

<style scoped>
#visualizacionOyentesIndustria {
    margin: 0 auto !important;
    /* width: calc(60vw) !important;
    height: calc(60vh) !important; */
    background-color: #000;
}

.nota {
    font-size: 0.9rem;
}
</style>
