<template>
    <div>
        <div class="row">
            <div class="col-3">
                <div class="form-group">
                    <select class="form-control form-control-sm combobox" id="SelectorFechasDefinidas_cboRangosDefinidos">
                        <option
                            v-on:click.prevent="publicarRangoFechas('ÚLTIMA SEMANA')"
                        >
                            ÚLTIMA SEMANA
                        </option>
                        <option
                            v-on:click.prevent="publicarRangoFechas('ÚLTIMAS DOS SEMANAS')"
                        >
                            ÚLTIMAS DOS SEMANAS
                        </option>
                        <option
                            v-on:click.prevent="publicarRangoFechas('ÚLTIMAS TRES SEMANAS')"
                        >
                            ÚLTIMAS TRES SEMANAS
                        </option>
                        <option
                            v-on:click.prevent="publicarRangoFechas('ÚLTIMO MES')"
                        >
                            ÚLTIMO MES
                        </option>
                        <option
                            v-on:click.prevent="publicarRangoFechas('ÚLTIMOS DOS MESES')"
                        >
                            ÚLTIMOS DOS MESES
                        </option>
                        <option
                            v-on:click.prevent="publicarRangoFechas('ÚLTIMO TRIMESTRE')"
                        >
                            ÚLTIMO TRIMESTRE
                        </option>
                        <option
                            v-on:click.prevent="publicarRangoFechas('ÚLTIMO SEMESTRE')"
                        >
                            ÚLTIMO SEMESTRE
                        </option>
                        <option
                            v-on:click.prevent="publicarRangoFechas('ÚLTIMO AÑO')"
                        >
                            ÚLTIMO AÑO
                        </option>
                    </select>
                </div>
            </div>

            <div class="col">
                <div class="form-group">
                    <button
                        type="submit"
                        class="boton-selector-fechas boton-selector-fechas-secundario"
                        v-on:click.prevent="publicarRangoFechas()"
                    >
                        <img
                            src="../assets/play-blanco.svg"
                            class="boton-accion-icono"
                         alt="play"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import { urlAPI } from "@/main"
import Combobox from "../Combobox.js";
import { bus } from "@/main"
import VueNotifications from "vue-notifications";

export default {
    name: "SelectorFechasDefinidas",

    data() {
        return {
            fechas: [],
        };
    },

    mounted() {
        this.leerFechas()
    },

    notifications: {
        alertaPeriodoSinRegistros: {
            type: VueNotifications.types.error,
            title: 'Período sin muestras',
            message: 'No hay muestras dentro del período seleccionado.'
        },
    },

    methods: {
        leerFechas() {

            axios
                .get(urlAPI + "/api/v1/artistas/fechasmuestras")
                .then((respuesta) => {
                    this.fechas = respuesta.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.errores = true;
                })
                .finally(() => {
                    this.cargando = false;
                });
        },

        publicarRangoFechas() {
            let rango
            let periodo
            let fechaMuestra = new Date()
            let fechaDesde = new Date()
            let fechaHasta = new Date()
            let fechaDesdeEncontrada = false
            let fechaHastaEncontrada = false

            periodo = Combobox.ComboboxGetSelectedCaption("SelectorFechasDefinidas_cboRangosDefinidos");

            bus.$emit("periodoFechasDefinidasSeleccionado", periodo)

            switch (periodo) {
                case 'ÚLTIMA SEMANA':
                    fechaDesde.setDate(fechaDesde.getDate() - 7)
                    break
                case 'ÚLTIMAS DOS SEMANAS':
                    fechaDesde.setDate(fechaDesde.getDate() - 14)
                    break
                case 'ÚLTIMAS TRES SEMANAS':
                    fechaDesde.setDate(fechaDesde.getDate() - 21)
                    break
                case 'ÚLTIMO MES':
                    fechaDesde.setMonth(fechaDesde.getMonth() - 1)
                    break
                case 'ÚLTIMOS DOS MESES':
                    fechaDesde.setMonth(fechaDesde.getMonth() - 2)
                    break
                case 'ÚLTIMO TRIMESTRE':
                    fechaDesde.setMonth(fechaDesde.getMonth() - 3)
                    break
                case 'ÚLTIMO SEMESTRE':
                    fechaDesde.setMonth(fechaDesde.getMonth() - 6)
                    break
                case 'ÚLTIMO AÑO':
                    fechaDesde.setMonth(fechaDesde.getMonth() - 12)
                    break
            }
            
            //Tomar como fechaDesde a la fecha más cercana por arriba de la fecha seleccionada entre las fechas de muestras.
            for (let fecha of this.fechas) {
                fechaMuestra = new Date(fecha.fecha_muestra)

                if (fechaMuestra >= fechaDesde) {
                    fechaDesde = fechaMuestra

                    fechaDesde = fechaDesde.getFullYear() + "-" + (fechaDesde.getMonth() +1) + "-" + fechaDesde.getDate();

                    fechaDesdeEncontrada = true
                    break
                }
            }

            //Tomar como fechaHasta a la fecha más cercana por abajo de la fecha actual entre las fechas de muestras.
            for (let i=this.fechas.length; i>0; i--) {
                fechaMuestra = new Date(this.fechas[i-1].fecha_muestra)

                if (fechaMuestra <= fechaHasta) {
                    fechaHasta = fechaMuestra

                    fechaHasta = fechaHasta.getFullYear() + "-" + (fechaHasta.getMonth() +1) + "-" + fechaHasta.getDate();

                    fechaHastaEncontrada = true
                    break
                }
            }

            if (fechaDesdeEncontrada && fechaHastaEncontrada) {
                rango = fechaDesde + "," + fechaHasta

                bus.$emit("rangoFechasDefinidasSeleccionado", rango)
            } else {
                this.alertaPeriodoSinRegistros()
            }
        }
    }
}
</script>

<style scoped>
.boton-selector-fechas {
    font-size: 0.8rem;
    font-weight: 800;
    color: rgba(0, 0, 0, 0.7);
    background-color: rgb(240,240,16);
    border-style: none;
    margin-right: 10px;
    margin-top: 0;
    padding: 6px 20px;
    border-radius: 1px;
    text-decoration: none;
}

.boton-selector-fechas-secundario {
    color: #29efad;
    background-color:  rgb(33,32,32);
}
</style>