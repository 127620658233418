<template>
    <div class="container">
        <TituloPagina />

        <form>
            <div class="row">
                <div class="col gris">
                    Tag
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <input
                        id="tagNombreEnEdicion"
                        type="text"
                        class="form-control"
                        placeholder="Nombre tag"
                        v-model.lazy="tagNombreEnEdicion"
                    />
                </div>
            </div>

            <br />
            <button
                type="reset"
                class="boton boton-secundario"
                v-on:click.prevent="limpiarFormulario()"
            >
                Limpiar
            </button>

            <button
                type="submit"
                class="boton"
                v-on:keyup.enter="guardarFormulario()"
                v-on:click.prevent="guardarFormulario()"
            >
                Guardar
            </button>
        </form>

        <br />
        <br />
        <div>
            <section v-if="errores">
                <p class="gris">
                    Lo sentimos, no es posible obtener la información en este
                    momento. Por favor intente nuevamente más tarde.
                </p>
            </section>

            <section v-else>
                <div class="administrar-tags-contenedor-tabla">
                    <div v-if="cargando">
                        <img src="../assets/loading.gif" width="200" />
                    </div>

                    <div v-else>
                        <table class="table table-dark bg-negro">
                            <thead>
                                <tr>
                                    <th class="col-md-6 bg-negro amarillo">Nombre</th>
                                    <th class="col-md-6 bg-negro amarillo texto-centro">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="tag in tags" v-bind:key="tag.id">
                                    <td>{{ tag.nombre }}</td>
                                    <td class="texto-centro">
                                        <span
                                            v-on:click.prevent="
                                                editarTag(tag.id)
                                            "
                                            class="boton-accion boton-accion-s"
                                        >
                                            <img
                                                src="../iconos/svg/052-edit.svg"
                                                class="boton-accion-icono"
                                            />
                                        </span>
                                        <span
                                            v-on:click.prevent="
                                                deleteTag(tag.id)
                                            "
                                            class="boton-accion boton-accion-s bg-rojo"
                                        >
                                            <img
                                                src="../iconos/svg/028-trash.svg"
                                                class="boton-accion-icono"
                                            />
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { urlAPI } from "../main.js";
import VueNotifications from 'vue-notifications';
import TituloPagina from "./TituloPagina.vue"

export default {
    name: "AdministrarTags",

    data() {
        return {
            cargando: true,
            errores: false,
            tags: [],
            tagIdEnEdicion: 0,
            tagNombreEnEdicion: "",
        };
    },

    components: {
        TituloPagina
    },

    mounted() {
        this.leerTags();
    },

    notifications: {
        alertaGuardadoExitoso: {
            type: VueNotifications.types.success,
            title: 'Guardado exitoso',
            message: 'El tag se ha guardado exitosamente.'
        },

        alertaFaltanDatos: {
            type: VueNotifications.types.error,
            title: 'Faltan datos',
            message: 'Por favor, complete todos los datos para poder guardar el tag.'
        },

        alertaTagEliminado: {
            type: VueNotifications.types.success,
            title: 'Tag eliminado',
            message: 'El tag ha sido eliminado exitosamente.'
        },

        alertaTagNoEliminable: {
            type: VueNotifications.types.error,
            title: 'Tag no eliminado',
            message: 'El tag no se puede eliminar porque uno o más documentos lo tienen asignado.'
        },
    },

    methods: {
        leerTags() {
            axios
                .get(urlAPI + "/api/v1/documentos/tags")
                .then((respuesta) => {
                    this.tags = respuesta.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.errores = true;
                })
                .finally(() => {
                    this.cargando = false;
                    this.tagNombreEnEdicion = "";
                    this.tagIdEnEdicion = 0;
                    document.getElementById("tagNombreEnEdicion").focus();
                });
        },

        guardarFormulario() {
            if (this.tagNombreEnEdicion != '') {
                if (this.tagIdEnEdicion == 0) {
                    axios
                        .post(urlAPI + "/api/documentos/tags", {
                            tagNombre: this.tagNombreEnEdicion,
                        })
                        .then((respuesta) => {
                            console.log(respuesta);
                            this.alertaGuardadoExitoso()
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .finally(() => {
                            this.leerTags();
                        });
                } else {
                    axios
                        .put(urlAPI + "/api/documentos/tags", {
                            tagId: this.tagIdEnEdicion,
                            tagNombre: this.tagNombreEnEdicion,
                        })
                        .then((respuesta) => {
                            console.log(respuesta);
                            this.alertaGuardadoExitoso()
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .finally(() => {
                            this.leerTags();
                        });
                }
            } else {
                this.alertaFaltanDatos()
            }
        },

        editarTag(tagId) {
            this.tagIdEnEdicion = tagId;
            this.tagNombreEnEdicion = this.tags.find(
                (tag) => tag.id == tagId
            ).nombre;
            document.getElementById("tagNombreEnEdicion").focus();
        },

        deleteTag(tagId) {
            axios
                .delete(urlAPI + "/api/documentos/tags/" + tagId)
                .then((respuesta) => {
                    if (respuesta.data.Operacion == 'OK') {
                        this.alertaTagEliminado()
                    } else {
                        this.alertaTagNoEliminable()
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.leerTags();
                });
        },

        limpiarFormulario() {
            document.getElementById("tagNombreEnEdicion").value = ""

            this.tagIdEnEdicion = 0;
            this.tagNombreEnEdicion = "";

            document.getElementById("tagNombreEnEdicion").focus()
        }
    },
};
</script>
