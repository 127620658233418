<template>
    <div class="container">
        <br />
        <div
            v-if="cargando"
            class="tarjeta"
        >
            <img src="../assets/loading.gif" width="200"  alt="cargando"/>
        </div>

        <div v-else>
            <div v-if="artistaNodoSeleccionado.nombre !== '...'">
                <div class="row">
                    <div class="tarjeta">
                        <div class="bd-gris">
                            <div
                                v-if="artistaNodoSeleccionado.imagen_url !== ''"
                                class="texto-centro"
                            >
                                <img
                                    v-bind:src="artistaNodoSeleccionado.imagen_url"
                                    width="200"
                                    height="200"
                                    v-on:click.prevent="abrirPerfilSpotifyArtista(nodoSeleccionado)"
                                    class="caratula"
                                    alt="carátula"
                                >

                                <img
                                    src="../assets/icono-spotify-40x40.png"
                                    width="40"
                                    class="icono-spotify"
                                    v-on:click.prevent="abrirPerfilSpotifyArtista(nodoSeleccionado)"
                                />

                            </div>
                            <div v-else>
                                <img src="../assets/vinilo-gris.svg" width="200" height="200">
                            </div>
                        

                            <div class="zona-datos">
                                <div class="zona-datos-titulo negrita blanco">{{ artistaNodoSeleccionado.nombre }}</div>

                                <div class="zona-datos-valor verde">
                                    <span class="zona-datos-item">Popularidad:</span>
                                    {{ artistaNodoSeleccionado.popularidad }}
                                </div>
                                
                                <div class="zona-datos-valor verde">
                                    <span class="zona-datos-item">Seguidores:</span>
                                    {{ artistaNodoSeleccionado.seguidores | separadorMiles() }}
                                </div>

                                <div class="zona-datos-valor verde">
                                    <span class="zona-datos-item">Oyentes mes:</span>
                                    {{ artistaNodoSeleccionado.oyentes_mensuales | separadorMiles() }}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="pistas-populares">
                        <div class="row">
                            <div class="col-1 verde negrita">
                                Álbum
                            </div>

                            <div class="col-4 verde negrita">
                                
                            </div>

                            <div class="col-4 verde negrita">
                                Pista
                            </div>

                            <div class="col-1 verde negrita texto-derecha">
                                Popularidad
                            </div>
                        </div>

                        <div
                            v-for="(track,index) in toptracks" v-bind:key="index"
                            class="row"
                        >
                            <div class="col-1 imagen-album">
                                <span
                                    v-if="track.imagen_url_album !== ''"
                                >
                                    <img
                                        v-bind:src="track.imagen_url_album"
                                        width="35"
                                        height="35"
                                    >
                                </span>
                            </div>

                            <div class="col-4 blanco celda">
                                {{ track.nombre_album }}
                            </div>

                            <div class="col-4 blanco celda">
                                {{ track.nombre_track }}
                            </div>

                            <div class="col-1 blanco texto-derecha celda">
                                {{ track.popularidad_track }}
                            </div>

                            <div class="col-2 celda texto-derecha">
                                <span
                                    v-if="track.preview_url !== ''"
                                >
                                    <span
                                        v-if="accionPistaActual == 'stop' || (accionPistaActual == 'play' && index != indexPistaActual)"
                                    >
                                        <img
                                            src="../assets/play-blanco.svg"
                                            width="14"
                                            v-on:click.prevent="play(index)"
                                            class="control-audio"
                                        />
                                    </span>
                                    <span
                                        v-if="(accionPistaActual == 'play' && index == indexPistaActual)"
                                    >
                                        <img
                                            src="../assets/stop-blanco.svg"
                                            width="14"
                                            v-on:click.prevent="stop(index)"
                                        />
                                    </span>
                                    <span>
                                        <audio
                                            v-bind:id="'audio' + index"
                                            v-bind:src="track.preview_url"
                                            preload="auto"
                                        >
                                        </audio>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { bus } from "@/main";
import { urlAPI } from "@/main";

export default {
    name: "FichaNodo",

    data() {
        return {
            cargando: false,
            nodoSeleccionado: '',
            artistaNodoSeleccionado: {
                nombre: '...',
                imagen_url: '',
                generos: ''
            },
            toptracks: [{
                nombre_album: '',
                imagen_url_album: '',
                nombre_track: '',
                popularidad_track: '',
                preview_url: ''
            }],
            indexPistaActual: 0,
            accionPistaActual: 'stop'
        };
    },

    mounted() {
        bus.$on("nodoSeleccionado", (nodoSeleccionado) => {
            this.nodoSeleccionado = nodoSeleccionado
            this.leerDatosArtistaNodo(nodoSeleccionado)
        });
    },

    methods: {
        play(indexPista) {
            //Apagar eventual pista sonando.
            let pistaSonando = document.getElementById("audio" + this.indexPistaActual)
            pistaSonando.load()

            this.indexPistaActual = indexPista
            this.accionPistaActual = 'play'

            //Reproducir pista seleccionada.
            let pista = document.getElementById("audio" + indexPista)
            pista.play()
        },

        pausa(indexPista) {
            let pista = document.getElementById("audio" + indexPista)
            pista.pause()
        },

        stop(indexPista) {
            let pista = document.getElementById("audio" + indexPista)
            pista.load()

            this.indexPistaActual = 0
            this.accionPistaActual = 'stop'
        },

        leerDatosArtistaNodo(nodo) {
            this.$gtag.event('Ver ficha de artista y top tracks', { method: 'Google' })

            this.cargando = true;

            this.toptracks = [{
                nombre_album: '',
                imagen_url_album: '',
                nombre_track: '',
                popularidad_track: '',
                preview_url: ''
            }]

            axios
                .get(urlAPI + "/api/v1/artista", {
                    params: {
                        user_spotify_id: nodo
                    }
                })
                .then((respuesta) => {
                    if (respuesta.data.length > 0 ) {
                        this.artistaNodoSeleccionado = respuesta.data[0];

                        axios
                            .get(urlAPI + "/api/v1/artistas/toptracks", {
                                params: {
                                    user_spotify_id: nodo
                                }
                            })
                            .then((respuesta) => {
                                if (respuesta.data.length > 0 ) {
                                    this.toptracks = respuesta.data;
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                            })
                            .finally(() => {
                                
                            });
                    } else {
                        this.artistaNodoSeleccionado = {
                            nombre: '...',
                            imagen_url: '',
                            generos: ''
                        }    
                    }
                })
                .catch((error) => {
                    this.artistaNodoSeleccionado = {
                        nombre: '...',
                        imagen_url: '',
                        generos: ''
                    }

                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false;
                });
        },

        abrirPerfilSpotifyArtista(artistaUserSpotifyId) {
            this.$gtag.event('Abrir perfil de artista en Spotify', { method: 'Google' })

            window.open("https://open.spotify.com/artist/" + artistaUserSpotifyId)
        }
    }
};
</script>

<style scoped>
.control-audio {
    margin-left: 6px;
}

.tarjeta {
    width: 200px;
    margin-left: 15px;
    margin-top: 20px;
    padding: 0;
}

.pistas-populares {
    margin-left: 30px;
    margin-top: 20px;
    width: calc(100% - 250px);
}

.icono-spotify {
    margin-top: -95px;
    cursor: pointer;
}

.icono-spotify:hover {
    cursor: pointer;
}

.imagen-album {
    padding-top: 15px;
}

.celda {
    padding-top: 10px;
}

.zona-datos {
    margin-top: 0;
    padding-top: 0;
    line-height: 22px;
    margin-left: 0;
}

.zona-datos-titulo {
    font-weight: 800;
    font-size: 1.5rem;
    text-align: center;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: rgb(240,240,16);
    padding-bottom: 20px;
}

.zona-datos-item {
    font-size: 0.9rem;
}

.zona-datos-valor {
    font-weight: 800;
    font-size: 0.9rem;
    margin-top: 10px;
}

.caratula {
    cursor: pointer;
}

</style>
