<template>
    <div class="container">
        <span v-if="($store.getters.paginaVisible === 'estudio' && $store.getters.numVisualizacionVisible === '6') || ($store.getters.paginaVisible === 'chileenspotify' && $store.getters.numVisualizacionDimensionesParesVisible === '1')">
            <div v-if="cargando">
                <img src="../assets/loading.gif" width="200"  alt="cargando"/>
            </div>
            
            <div v-else>
                <br /><br />                
                <canvas id="visualizacionSeguidoresOyentesArtistas"></canvas>

                <div class="container">
                    <br />
                    <div class="row">
                        <span class="amarillo">* </span>
                        <span class="blanco nota">
                            Pulsa los cuadros de colores para mostrar/ocultar una curva.
                        </span>
                    </div>
                </div>
            </div>
        </span>
    </div>
</template>

<script>
import { bus } from "@/main";
import axios from "axios";
import { urlAPI } from "@/main";
import Chart from 'chart.js'

export default {
    name: "VisualizacionSeguidoresOyentesArtistas",
    
    data() {
        return {
            cargando: false,
            datosArtistas: [],
            fechasMuestras: [],
            backgroundcolor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(240, 240, 16, 0.2)', //amarillo sistema (amarillo anterior: rgba(255, 206, 86, 1))
                'rgba(41, 239, 173, 0.2)', //verde sistema (verde anterior: rgba(75, 192, 192, 1))
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                '#000', //color de fondo del lienzo
            ],
            colores: [
                'rgb( 146, 43, 33 )',
                'rgb( 118, 68, 138 )',
                'rgb( 31, 97, 141 )',
                'rgb( 20, 143, 119 )',
                'rgb( 35, 155, 86 )',
                'rgb( 185, 119, 14 )',
                'rgb( 160, 64, 0 )',
                'rgb( 255, 255, 255 )',
                'rgb( 81, 90, 90 )',
                'rgb( 28, 40, 51 )',
                'rgb( 141, 110, 99 )',
                'rgb( 252, 243, 207 )',
                'rgb( 230, 176, 170 )',
                'rgb( 215, 189, 226 )',
                'rgb( 169, 204, 227 )',
                'rgb( 163, 228, 215 )',
                'rgb( 249, 231, 159 )',
                'rgb( 237, 187, 153 )',
                'rgb( 247, 249, 249 )',
                'rgb( 171, 178, 185 )',
            ],
            coloresRelleno: [
                'rgba( 146, 43, 33, 0.5 )',
                'rgba( 118, 68, 138, 0.5 )',
                'rgba( 31, 97, 141, 0.5 )',
                'rgba( 20, 143, 119, 0.5 )',
                'rgba( 35, 155, 86, 0.5 )',
                'rgba( 185, 119, 14, 0.5 )',
                'rgba( 160, 64, 0, 0.5 )',
                'rgba( 255, 255, 255, 0.5 )',
                'rgba( 81, 90, 90, 0.5 )',
                'rgba( 28, 40, 51, 0.5 )',
                'rgba( 141, 110, 99, 0.5 )',
                'rgba( 252, 243, 207, 0.5 )',
                'rgba( 230, 176, 170, 0.5 )',
                'rgba( 215, 189, 226, 0.5 )',
                'rgba( 169, 204, 227, 0.5 )',
                'rgba( 163, 228, 215, 0.5 )',
                'rgba( 249, 231, 159, 0.5 )',
                'rgba( 237, 187, 153, 0.5 )',
                'rgba( 247, 249, 249, 0.5 )',
                'rgba( 171, 178, 185, 0.5 )',
            ]
        };
    },

    mounted() {
        bus.$on("artistasClasificacionArtistasPorSeguidoresDesplegados", () => {
            if (this.$store.getters.paginaVisible === 'chileenspotify') {                
                this.leerSeguidoresOyentesArtistas()
            }
        });

        bus.$on("artistasClasificacionArtistasPorOyentesDesplegados", () => {
            if (this.$store.getters.paginaVisible === 'chileenspotify') {
                this.leerSeguidoresOyentesArtistas()
            }
        });

        bus.$on("artistasClasificacionArtistasPorPopularidadDesplegados", () => {
            if (this.$store.getters.paginaVisible === 'chileenspotify') {
                this.leerSeguidoresOyentesArtistas()
            }
        });

        bus.$on("artistasClasificacionArtistasPorIncrementoPorcentualSeguidoresDesplegados", () => {
            if (this.$store.getters.paginaVisible === 'chileenspotify') {
                this.leerSeguidoresOyentesArtistas()
            }
        });

        bus.$on("artistasClasificacionArtistasPorIncrementoPorcentualOyentesDesplegados", () => {
            if (this.$store.getters.paginaVisible === 'chileenspotify') {
                this.leerSeguidoresOyentesArtistas()
            }
        });

        bus.$on("artistasClasificacionArtistasPorIncrementoPorcentualPopularidadDesplegados", () => {
            if (this.$store.getters.paginaVisible === 'chileenspotify') {
                this.leerSeguidoresOyentesArtistas()
            }
        });

        bus.$on("visualizacionSeleccionada", () => {
            if ((this.$store.getters.paginaVisible === 'estudio' && this.$store.getters.numVisualizacionVisible === '6') || this.$store.getters.paginaVisible === 'chileenspotify' && this.$store.getters.numVisualizacionDimensionesParesVisible === '1') {
                this.leerSeguidoresOyentesArtistas()
            }
        })

        bus.$on("botonEstudiarPulsado", () => {
            if ((this.$store.getters.paginaVisible === 'estudio' && this.$store.getters.numVisualizacionVisible === '6') || this.$store.getters.paginaVisible === 'chileenspotify' && this.$store.getters.numVisualizacionDimensionesParesVisible === '1') {
                this.leerSeguidoresOyentesArtistas()
            }
        })
    },

    methods: {
        leerSeguidoresOyentesArtistas() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/seguidoresoyentesartistas", {
                    params: {
                        listaUserSpotifyIdArtistas: this.$store.getters.listaUserSpotifyIdArtistasSeleccionados
                    }
                })
                .then((respuesta) => {
                    this.datosArtistas = respuesta.data

                    this.leerFechasMuestras()
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        leerFechasMuestras() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/fechasmuestras")
                .then((respuesta) => {
                    this.fechasMuestras = []

                    for (let i=0; i<respuesta.data.length; i++) {
                        this.fechasMuestras.push(respuesta.data[i].fecha_muestra.substring(0,10))
                    }

                    this.visualizar()
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        visualizar() {
            if (document.getElementById("visualizacionSeguidoresOyentesArtistas") !== null) {
                let dataSets = []
                let nombreArtista = ""
                let color = 0
                let numArtistasAVisualizar = this.datosArtistas.length
                let puntos = []

                //Armar data set de cada artista.
                for (let userSpotifyIdArtista of this.$store.getters.listaUserSpotifyIdArtistasSeleccionados) {
                    nombreArtista = ""
                    puntos = []

                    for (let i=0; i<numArtistasAVisualizar; i++) {   
                        if (this.datosArtistas[i].user_spotify_id === userSpotifyIdArtista) {
                            nombreArtista = this.datosArtistas[i].nombre.substring(0,10)

                            puntos.push({x: this.datosArtistas[i].seguidores, y: this.datosArtistas[i].oyentes_mensuales})
                        }
                    }

                    dataSets.push({
                        label: nombreArtista,
                        data: puntos,
                        borderColor: this.colores[color],
                        backgroundColor: this.coloresRelleno[color]
                    })

                    color++
                    if (color >= this.colores.length) {
                        color = 0
                    }
                }

                //Crear gráfico Seguidores vs Oyentes.
                let lienzoGraficoSeguidoresArtistas = document.getElementById('visualizacionSeguidoresOyentesArtistas').getContext('2d');
                let visualizacionSeguidoresOyentesArtistas = new Chart(lienzoGraficoSeguidoresArtistas, {
                    type: 'scatter',
                    data: {
                        labels: this.fechasMuestras,
                        datasets: dataSets
                    },
                    options: {
                        title: {
                            display: true,
                            text: 'Seguidores vs Oyentes',
                            fontColor: '#fff',
                            fontSize: '20'
                        },
                        legend: {
                            labels: {
                                fontColor: '#fff',
                                fontSize: 14,
                            }
                        },
                        scales: {
                            xAxes: [{
                                ticks: {
                                    fontColor: '#fff',
                                    fontSize: 14,
                                },
                            }],
                            yAxes: [{
                                ticks: {
                                    beginAtZero: false,
                                    fontColor: '#fff',
                                    fontSize: 14,
                                },
                                gridLines: {
                                    display:true,
                                    color: 'rgba(255,255,255,0.2)'
                                }
                            }]
                        }
                    }
                });

                console.log(visualizacionSeguidoresOyentesArtistas)
                console.log(lienzoGraficoSeguidoresArtistas)
            }
        }
    }
}
</script>

<style scoped>
#visualizacionSeguidoresOyentesArtistas {
    background-color: #000;
}

.nota {
    font-size: 0.9rem;
}
</style>
