<template>
    <div class="container">
        <!-- <span v-if="$store.getters.usuarioIdentificado && $store.getters.numClasificacionVisible == '5'"> -->
        <span v-if="$store.getters.numClasificacionVisible == '5'">
            <div v-if="cargando">
                <img src="../assets/loading.gif" width="200" />
            </div>

            <div v-else>
                <div class="dropdown borde-si borde-bottom-no borde-delgado bd-verde zona-dropdown">
                    <button class="combobox dropdown-toggle borde-no" type="button" id="dropdownMenuButtonClasificacionGenerosPorArtistas" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Top ...
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButtonClasificacionGenerosPorArtistas">
                        <span
                            class="dropdown-item" href="#"
                            v-on:click.prevent="fijarTopX(5)"
                        >
                            Top 5
                        </span>
                        <span
                            class="dropdown-item" href="#"
                            v-on:click.prevent="fijarTopX(10)"
                        >
                            Top 10
                        </span>
                        <span
                            class="dropdown-item" href="#"
                            v-on:click.prevent="fijarTopX(20)"
                        >
                            Top 20
                        </span>
                    </div>
                </div>

                <table class="table table-dark tabla-clasificacion bd-verde">
                    <thead>
                        <tr>
                            <th scope="col" class="bg-negro amarillo-sistema">#</th>
                            <th scope="col" class="bg-negro amarillo-sistema">Género</th>
                            <th scope="col" class="bg-negro amarillo-sistema texto-derecha">Porcentaje de artistas</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(datosGenero,indice) in subconjuntoGenerosVisible"
                            v-bind:key="indice"
                        >
                            <td class="bg-negro blanco">
                                {{ indice + 1 }}
                            </td>
                            <td class="bg-negro blanco negrita">
                                {{ datosGenero.genero | truncarTexto(16) }}
                            </td>
                            <td class="bg-negro texto-derecha blanco negrita">
                                {{ datosGenero.porcentajeArtistas }} % ({{ datosGenero.numeroArtistas | separadorMiles() }})
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </span>
    </div>
</template>

<script>
import axios from "axios";
import { urlAPI } from "../main.js";
import { TOPX_CLASIFICACION } from "../main.js";

export default {
    name: "ClasificacionGenerosPorArtistas",

    data() {
        return {
            cargando: false,
            topX: TOPX_CLASIFICACION,
            clasificacionGenerosPorArtistas: [],
            subconjuntoGenerosVisible: []
        };
    },

    mounted() {
        this.leerClasificacion()
    },

    watch: {
        topX: function () {
            this.subconjuntoGenerosVisible = this.clasificacionGenerosPorArtistas.slice(0, this.topX)
        }
    },

    methods: {
        leerClasificacion() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/generos/clasificaciongenerosporartistas")
                .then((respuesta) => {
                    this.clasificacionGenerosPorArtistas = respuesta.data
                    this.subconjuntoGenerosVisible = this.clasificacionGenerosPorArtistas.slice(0, this.topX)
                })
                .catch((error) => {
                    console.log(error);
                    this.errores = true;
                })
                .finally(() => {
                    this.cargando = false;
                });
        },
        
        fijarTopX(maximoElementos) {
            if (this.clasificacionGenerosPorArtistas.length >= maximoElementos) {
                this.topX = maximoElementos
            } else {
                this.topX = this.clasificacionGenerosPorArtistas.length
            }
        },
        
    }
};
</script>

<style>
.titulo-tabla {
    color: whitesmoke;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.5rem;
}

.tabla-clasificacion {
    border-style: solid !important;
    border-width: 1px !important;
    border-top-style: none !important;
}

.zona-dropdown {
    padding-top: 30px;
    padding-left: 10px;
}
</style>
