<template>
    <div>
        <br />
        <br />
        <div>
            <section v-if="errores">
                <p class="gris">
                    Lo sentimos, no es posible obtener la información en este
                    momento. Por favor intente nuevamente más tarde.
                </p>
            </section>

            <section v-else>
                <div class="row envoltorio-tarjetas">
                    <div
                        class="card tarjeta bg-negro"
                        v-for="(documento) in documentosPropios"
                            v-bind:key="documento.id"
                    >
                        <a
                            v-bind:href="'https://www.api.odmc.cl/' + documento.link"
                            target="_blank"
                            v-on:click="descargarDocumentoPropio(documento.id, documento.nombre, $store.getters.id)"
                        >
                            <img
                                v-bind:src="'https://www.api.odmc.cl/' + documento.imagen_portada"
                                height="200"
                                class="caratula"
                            >
                        </a>
                        <!-- <div class="titulo-documento">
                            Caracterización capítulo 1
                        </div>
                        <div class="texto-centro">
                            <img
                                src="../assets/documentos-propios-subrayado-95x10.png"
                                width="55"
                                height="5"
                            >
                        </div> -->
                        <div class="descripcion-documento">
                            {{ documento.nombre }}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { urlAPI } from "../main.js";

export default {
    name: "ListaDocumentosPropios",

    data() {
        return {
            cargando: true,
            errores: false,
            documentosPropios: [],
        };
    },

    mounted() {
        this.leerDocumentos();
    },

    methods: {
        leerDocumentos() {
            axios
                .get(urlAPI + "/api/v1/documentospropios")
                .then((respuesta) => {
                    this.documentosPropios = respuesta.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.errores = true;
                })
                .finally(() => {
                    this.cargando = false;
                });
        },

        descargarDocumentoPropio(idDocumento, nombreDocumento, idUsuarioIdentificado) {
            this.$gtag.event('Descargar documento propio ' + nombreDocumento, { method: 'Google' })

            let fechaDescarga = new Date()

            axios
                .put(urlAPI + "/api/documentos/contabilizarDescarga", {
                    idDocumento: idDocumento,
                    idUsuario: idUsuarioIdentificado,
                    fechaDescarga: fechaDescarga
                })
                .then(function(respuesta) {
                    console.log(respuesta);
                })
                .catch(function(error) {
                    console.log(error);
                })
                .finally(() => {
                    
                });
        },
    },
};
</script>

<style scoped>
.fichas-documentos {
    width: 100%;
    margin: auto;
    margin-bottom: 100px;
}

.clear {
    clear: both;
}

.caratula {
    cursor: pointer;
}

.envoltorio-tarjetas {
    margin: auto;
    text-align: center;
}

.tarjeta {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 40px;
    margin-bottom: 120px;
    height: 202px;
    border-width: 1px;
    border-color:#29efad;
    border-style: solid;
}

.titulo-documento {
    text-align: center;
    color: #fff;
    width: 280px;
    font-size: 1.5rem;
}

.descripcion-documento {
    text-align: center;
    color: #fff;
    width: 280px;
    font-size: 0.9rem;
}
</style>
