<template>
    <div class="container">
        <div v-if="cargando">
            <img src="../assets/loading.gif" width="200" />
        </div>

        <div v-else>
            <div class="row">
                <div class="col blanco titulo-dashboard">
                    INDUSTRIA HOY
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <div class="card cuadro cuadro-angosto">
                        <div class="card-body bg-gris-oscuro">
                            <h5 class="blanco cuadro-titulo">MUESTRA</h5>
                            <h5 class="card-title blanco valor">
                                    {{ totalArtistas | separadorMiles() }}
                                    <span class="cuadro-detalle"> artistas/bandas</span>
                                    <h4 class="datos-adicionales">
                                        Artistas y bandas chilenas con presencia en Spotify
                                    </h4>
                            </h5>
                            <p class="card-text blanco cuadro-detalle"></p>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card cuadro cuadro-angosto">
                        <div class="card-body bg-gris-oscuro">
                            <h5 class="blanco cuadro-titulo">TOTAL GÉNEROS MUSICALES</h5>
                            <h5 class="card-title blanco valor">
                                <span v-if="totalArtistas != 0">
                                    {{ totalGeneros | separadorMiles() }}
                                    <span class="cuadro-detalle"> géneros</span>
                                    <h4 class="datos-adicionales">
                                        Artistas con géneros musicales: {{ (totalArtistasConGeneros * 100 / totalArtistas).toFixed(1) }}%
                                        ({{ totalArtistasConGeneros | separadorMiles() }})
                                    </h4>
                                </span>
                                <span v-else>
                                    0
                                    <span class="cuadro-detalle"> seguidores</span>
                                    <h4 class="datos-adicionales">
                                        Artistas con géneros musicales: 0%
                                        (0)
                                    </h4>
                                </span>
                            </h5>
                            <p class="card-text blanco cuadro-detalle"></p>
                        </div>
                    </div>
                </div>
            </div>

            <br />
            <div class="row">
                <div class="col">
                    <div class="card cuadro">
                        <div class="card-body bg-verde puntero-cliqueable cuadro-cliqueable"
                            v-bind:class="[{'bg-amarillo-sistema': $store.getters.numCuadroDashboardSeleccionado == '3'}]"
                            v-on:click="seleccionarCuadroDashboard('3')"
                        >
                            <h5 class="gris-oscurisimo cuadro-titulo">SEGUIDORES</h5>
                            <h5 class="card-title gris-oscurisimo valor">
                                <span v-if="totalArtistas != 0">
                                    <span class="datos-adicionales">Promedio: </span>
                                    {{ promedioSeguidores | separadorMiles() }}
                                    <h4 class="datos-adicionales">
                                        Desv. estándar: {{ desviacionEstandarSeguidores | separadorMiles() }}
                                    </h4>
                                    <h4 class="datos-adicionales">
                                        Máximo: {{ maximoSeguidores | separadorMiles() }}
                                    </h4>
                                    <h4 class="datos-adicionales">
                                        Total: {{ totalSeguidores | separadorMiles() }}
                                    </h4>
                                    <h4 class="datos-adicionales">
                                        Artistas con seguidores: {{ (totalArtistasConSeguidores * 100 / totalArtistas).toFixed(1) }}%
                                        ({{ totalArtistasConSeguidores | separadorMiles() }})
                                    </h4>
                                </span>
                                <span v-else>
                                    <span class="datos-adicionales">Promedio: </span>
                                    0
                                    <h4 class="datos-adicionales">
                                        Desv. estándar: 0
                                    </h4>
                                    <h4 class="datos-adicionales">
                                        Máximo: 0
                                    </h4>
                                    <h4 class="datos-adicionales">
                                        Total: 0
                                    </h4>
                                    <h4 class="datos-adicionales">
                                        Artistas con seguidores: 0%
                                        (0)
                                    </h4>
                                </span>
                            </h5>
                            <p class="card-text bg-verde gris-oscurisimo cuadro-detalle"></p>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card cuadro">
                        <div
                            class="card-body bg-verde puntero-cliqueable cuadro-cliqueable"
                                v-bind:class="[{'bg-amarillo-sistema': $store.getters.numCuadroDashboardSeleccionado == '2'}]"
                                v-on:click="seleccionarCuadroDashboard('2')"
                        >
                            <h5 class="gris-oscurisimo cuadro-titulo">POPULARIDAD [0, 100]</h5>
                            <h5 class="card-title gris-oscurisimo valor">
                                <span v-if="totalArtistas != 0">
                                    <span class="datos-adicionales">Promedio: </span>
                                    {{ promedioPopularidad }}
                                    <h4 class="datos-adicionales">
                                        Desv. estándar: {{ desviacionEstandarPopularidad | separadorMiles() }}
                                    </h4>
                                    <h4 class="datos-adicionales">
                                        Máximo: {{ maximoPopularidad }}
                                    </h4>
                                    <h4 class="datos-adicionales">
                                        Artistas con popularidad: {{ (totalArtistasConPopularidad * 100 / totalArtistas).toFixed(1) }}%
                                        ({{ totalArtistasConPopularidad | separadorMiles() }})
                                    </h4>
                                </span>
                                <span v-else>
                                    <span class="datos-adicionales">Promedio: </span>
                                    0
                                    <h4 class="datos-adicionales">
                                        Desv. estándar: 0
                                    </h4>
                                    <h4 class="datos-adicionales">
                                        Máximo: 0
                                    </h4>
                                    <h4 class="datos-adicionales">
                                        Artistas con popularidad: 0%
                                        (0)
                                    </h4>
                                </span>
                            </h5>
                            <p class="card-text bg-verde gris-oscurisimo cuadro-detalle"></p>
                        </div>
                    </div>
                </div>

                <div class="col">
                    <div class="card cuadro">
                        <div class="card-body bg-verde puntero-cliqueable cuadro-cliqueable"
                            v-bind:class="[{'bg-amarillo-sistema': $store.getters.numCuadroDashboardSeleccionado == '5'}]"
                            v-on:click="seleccionarCuadroDashboard('5')"
                        >
                            <h5 class="gris-oscurisimo cuadro-titulo">OYENTES</h5>
                            <h5 class="card-title gris-oscurisimo valor">
                                <span v-if="totalArtistas != 0">
                                    <span class="datos-adicionales">Promedio: </span>
                                    {{ promedioOyentes | separadorMiles() }}
                                    <h4 class="datos-adicionales">
                                        Desv. estándar: {{ desviacionEstandarOyentes | separadorMiles() }}
                                    </h4>
                                    <h4 class="datos-adicionales">
                                        Máximo: {{ maximoOyentes | separadorMiles() }}
                                    </h4>
                                    <h4 class="datos-adicionales">
                                        Total: {{ totalOyentes | separadorMiles() }}
                                    </h4>
                                    <h4 class="datos-adicionales">
                                        Artistas con oyentes: {{ (totalArtistasConOyentes * 100 / totalArtistas).toFixed(1) }}%
                                        ({{ totalArtistasConOyentes | separadorMiles() }})
                                    </h4>
                                </span>
                                <span v-else>
                                    <span class="datos-adicionales">Promedio: </span>
                                    0
                                    <h4 class="datos-adicionales">
                                        Desv. estándar: 0
                                    </h4>
                                    <h4 class="datos-adicionales">
                                        Máximo: 0
                                    </h4>
                                    <h4 class="datos-adicionales">
                                        Total: 0
                                    </h4>
                                    <h4 class="datos-adicionales">
                                        Artistas con oyentes: 0%
                                        (0)
                                    </h4>
                                </span>
                            </h5>
                            <p class="card-text bg-verde gris-oscurisimo cuadro-detalle"></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <br />
            <div class="row">
                <span class="amarillo">* </span>
                <span class="blanco nota">
                    Pulsa cualquiera de estos tres cuadros superiores para ver la visualización correspondiente de promedios en el tiempo.
                </span>
            </div>
            <div class="row">
                <span class="amarillo">* </span>
                <span class="blanco nota">
                    Toda la información está basada en datos generados por Spotify. ODMC no tiene influencia en ella.
                </span>
            </div>
        </div>

    </div>
</template>

<script>
import { bus } from "../main.js";
import axios from "axios";
import { urlAPI } from "../main.js";

export default {
    name: "Dashboard",

    data() {
        return {
            cargando: true,
            errores: false,
            totalArtistas: 0,
            totalSeguidores: 0,
            promedioSeguidores: 0,
            desviacionEstandarSeguidores: 0,
            maximoSeguidores: 0,
            promedioPopularidad: 0,
            desviacionEstandarPopularidad: 0,
            maximoPopularidad: 0,
            totalOyentes: 0,
            promedioOyentes: 0,
            desviacionEstandarOyentes: 0,
            maximoOyentes: 0,
            totalGeneros: 0,
            totalArtistasConGeneros: 0,
            totalArtistasConSeguidores: 0,
            totalArtistasConPopularidad: 0,
            totalArtistasConOyentes: 0,

            artistas: [],
            generos: "",
        };
    },

    mounted() {
        this.seleccionarCuadroDashboard('3')

        this.leerTotalMuestra()

        this.leerTotalGenerosMusicales()
        this.leerTotalArtistasConGeneros()

        this.leerPromedioActualSeguidores()
        this.leerTotalSeguidores()
        this.leerDesviacionEstandarSeguidores()
        this.leerTotalArtistasConSeguidores()
        this.leerMaximoSeguidores()

        this.leerPromedioActualPopularidad()
        this.leerDesviacionEstandarPopularidad()
        this.leerTotalArtistasConPopularidad()
        this.leerMaximoPopularidad()

        this.leerPromedioActualOyentes()
        this.leerTotalOyentes()
        this.leerDesviacionEstandarOyentes()
        this.leerTotalArtistasConOyentes()
        this.leerMaximoOyentes()
    },

    methods: {
        leerTotalMuestra() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/totalArtistas", {
                    params: {
                        aprobados: true,
                        excluidos: false,
                        chilenos: true
                    }
                })
                .then((respuesta) => {
                    this.totalArtistas = respuesta.data.totalArtistas
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        leerPromedioActualSeguidores() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/promedioactualseguidoresartistas")
                .then((respuesta) => {
                    this.promedioSeguidores = respuesta.data.promedioSeguidores
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        leerPromedioActualPopularidad() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/promedioactualpopularidadartistas")
                .then((respuesta) => {
                    this.promedioPopularidad = respuesta.data.promedioPopularidad
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        leerPromedioActualOyentes() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/promedioactualoyentesartistas")
                .then((respuesta) => {
                    this.promedioOyentes = respuesta.data.promedioOyentes
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        leerTotalSeguidores() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/totalactualseguidoresartistas")
                .then((respuesta) => {
                    this.totalSeguidores = respuesta.data.totalSeguidores
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        leerMaximoSeguidores() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/maximoseguidores")
                .then((respuesta) => {
                    this.maximoSeguidores = respuesta.data.maximoSeguidores
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        leerMaximoPopularidad() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/maximopopularidad")
                .then((respuesta) => {
                    this.maximoPopularidad = respuesta.data.maximoPopularidad
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        leerMaximoOyentes() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/maximooyentes")
                .then((respuesta) => {
                    this.maximoOyentes = respuesta.data.maximoOyentes
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        leerTotalOyentes() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/totalactualoyentesartistas")
                .then((respuesta) => {
                    this.totalOyentes = respuesta.data.totalOyentes
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        leerDesviacionEstandarSeguidores() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/desviacionestandarseguidoresartistas")
                .then((respuesta) => {
                    this.desviacionEstandarSeguidores = respuesta.data.desviacionEstandar
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        leerDesviacionEstandarPopularidad() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/desviacionestandarpopularidadartistas")
                .then((respuesta) => {
                    this.desviacionEstandarPopularidad = respuesta.data.desviacionEstandar
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        leerDesviacionEstandarOyentes() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/desviacionestandaroyentesartistas")
                .then((respuesta) => {
                    this.desviacionEstandarOyentes = respuesta.data.desviacionEstandar
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        leerTotalGenerosMusicales() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/generos/total")
                .then((respuesta) => {
                    this.totalGeneros = respuesta.data.totalGeneros
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        leerTotalArtistasConGeneros() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/generos/totalartistascongeneros")
                .then((respuesta) => {
                    this.totalArtistasConGeneros = respuesta.data.totalArtistasConGeneros
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        leerTotalArtistasConSeguidores() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/totalartistasconseguidores")
                .then((respuesta) => {
                    this.totalArtistasConSeguidores = respuesta.data.totalArtistasConSeguidores
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        leerTotalArtistasConPopularidad() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/totalartistasconpopularidad")
                .then((respuesta) => {
                    this.totalArtistasConPopularidad = respuesta.data.totalArtistasConPopularidad
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        leerTotalArtistasConOyentes() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/totalartistasconoyentes")
                .then((respuesta) => {
                    this.totalArtistasConOyentes = respuesta.data.totalArtistasConOyentes
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        seleccionarCuadroDashboard(numCuadroDashboardSeleccionado) {
            if (numCuadroDashboardSeleccionado == '2') {
                this.$gtag.event('Pulsar en dashboard popularidad', { method: 'Google' })
            }

            if (numCuadroDashboardSeleccionado == '3') {
                this.$gtag.event('Pulsar en dashboard seguidores', { method: 'Google' })
            }

            if (numCuadroDashboardSeleccionado == '5') {
                this.$gtag.event('Pulsar en dashboard oyentes', { method: 'Google' })
            }

            this.$store.dispatch('seleccionarCuadroDashboard', numCuadroDashboardSeleccionado)

            bus.$emit("cuadroDashboardSeleccionado");
        },
    },
};
</script>

<style scoped>
.cuadro {
    border-radius: 0px;
    border-style: none;
    height: 230px;
}

.cuadro-angosto {
    height: 170px;
}

.cuadro-titulo {
    font-size: 1.1rem;
    font-weight: 800;
}

.valor {
    font-size: 3.5rem;
    font-weight: 900;
}

.cuadro-detalle {
    font-size: 1.3rem;
    font-weight: 500;
}

.datos-adicionales {
    font-size: 0.9rem;
}

.nota {
    font-size: 0.9rem;
}

.puntero-cliqueable {
    cursor: pointer;
}

.cuadro-cliqueable:hover {
    background-color: rgb(240,240,16) !important;
}

.titulo-dashboard {
    font-size: 1.3rem;
    font-weight: 500;
}
</style>