function FileUpload(idFormulario, endPoint) {
    let xhr = new XMLHttpRequest();

    xhr.onreadystatechange = () => {
        if (xhr.readyState == 4) {
            if (xhr.status == 200) {
                try {
                    return window[idFormulario + "_OnFinish"].call(true, xhr.responseText);
                } catch(e) {
                    return console.log('status: ' + xhr.status + ". " + xhr.responseText)
                }
            } else {
                try {
                    window[idFormulario + "_OnFinish"].call(false, xhr.responseText);
                } catch(e) {
                    console.log('status: ' + xhr.status + ". " + xhr.responseText)
                }
            }
        }
    }

    xhr.open('POST', endPoint, false);

    let formulario = new FormData(document.getElementById(idFormulario));

    xhr.send(formulario);
}

module.exports = {
    FileUpload
}