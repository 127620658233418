<template>
    <div class="container">
        <div v-if="cargando">
            <img src="../assets/loading.gif" width="200"  alt="cargando"/>
        </div>
        
        <div v-else>
            <br /><br />                
            <canvas id="visualizacionRadarArtistas"></canvas>

            <div class="container">
                <br />
                <div class="row">
                    <span class="amarillo">* </span>
                    <span class="blanco nota">
                        Pulsa los nodos en el grafo para comparar los valores.
                    </span>
                </div>
                <div class="row">
                    <span class="amarillo">* </span>
                    <span class="blanco nota">
                        Comparación de descriptores en escala normalizada (1 a 100) respecto de los máximos de la industria en la última muestra.
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { bus } from "@/main";
import axios from "axios";
import { urlAPI } from "@/main";
import Chart from 'chart.js'

export default {
    name: "VisualizacionRadarArtistas",
    
    data() {
        return {
            cargando: false,
            nodoSeleccionado: '',
            numNodoSeleccionado: 0,
            artistasNodosSeleccionados: [],
            datosMaximosArtistas: {},
            fechasMuestras: [],
            backgroundcolor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(240, 240, 16, 0.2)', //amarillo sistema (amarillo anterior: rgba(255, 206, 86, 1))
                'rgba(41, 239, 173, 0.2)', //verde sistema (verde anterior: rgba(75, 192, 192, 1))
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                '#000', //color de fondo del lienzo
            ],
            colores: [
                'rgb( 146, 43, 33 )',
                'rgb( 118, 68, 138 )',
                'rgb( 31, 97, 141 )',
                'rgb( 20, 143, 119 )',
                'rgb( 35, 155, 86 )',
                'rgb( 185, 119, 14 )',
                'rgb( 160, 64, 0 )',
                'rgb( 255, 255, 255 )',
                'rgb( 81, 90, 90 )',
                'rgb( 28, 40, 51 )',
                'rgb( 141, 110, 99 )',
                'rgb( 252, 243, 207 )',
                'rgb( 230, 176, 170 )',
                'rgb( 215, 189, 226 )',
                'rgb( 169, 204, 227 )',
                'rgb( 163, 228, 215 )',
                'rgb( 249, 231, 159 )',
                'rgb( 237, 187, 153 )',
                'rgb( 247, 249, 249 )',
                'rgb( 171, 178, 185 )',
            ],
            coloresRelleno: [
                'rgba( 146, 43, 33, 0.5 )',
                'rgba( 118, 68, 138, 0.5 )',
                'rgba( 31, 97, 141, 0.5 )',
                'rgba( 20, 143, 119, 0.5 )',
                'rgba( 35, 155, 86, 0.5 )',
                'rgba( 185, 119, 14, 0.5 )',
                'rgba( 160, 64, 0, 0.5 )',
                'rgba( 255, 255, 255, 0.5 )',
                'rgba( 81, 90, 90, 0.5 )',
                'rgba( 28, 40, 51, 0.5 )',
                'rgba( 141, 110, 99, 0.5 )',
                'rgba( 252, 243, 207, 0.5 )',
                'rgba( 230, 176, 170, 0.5 )',
                'rgba( 215, 189, 226, 0.5 )',
                'rgba( 169, 204, 227, 0.5 )',
                'rgba( 163, 228, 215, 0.5 )',
                'rgba( 249, 231, 159, 0.5 )',
                'rgba( 237, 187, 153, 0.5 )',
                'rgba( 247, 249, 249, 0.5 )',
                'rgba( 171, 178, 185, 0.5 )',
            ]
        };
    },

    mounted() {
        this.leerDatosMaximosArtistas()

        bus.$on("nodoSeleccionado", (nodoSeleccionado) => {
            this.nodoSeleccionado = nodoSeleccionado

            //Pueden haber uno o dos nodos seleccionados.
            switch (this.numNodoSeleccionado) {
                case 0:
                    this.numNodoSeleccionado = 1
                    break

                case 1:
                    this.numNodoSeleccionado = 2
                    break
            }

            this.leerDatosArtistaNodo(nodoSeleccionado)
        });
    },

    methods: {
        leerDatosMaximosArtistas() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/datosmaximos")
                .then((respuesta) => {
                    if (respuesta.data.length > 0 ) {
                        this.datosMaximosArtistas = respuesta.data[0]
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        leerDatosArtistaNodo(nodo) {
            this.cargando = true;

            axios
                .get(urlAPI + "/api/v1/artista", {
                    params: {
                        user_spotify_id: nodo
                    }
                })
                .then((respuesta) => {
                    if (respuesta.data.length > 0 ) {
                        if (this.artistasNodosSeleccionados.length === 2) {
                            this.artistasNodosSeleccionados[0].nombre = this.artistasNodosSeleccionados[1].nombre
                            this.artistasNodosSeleccionados[0].seguidores = this.artistasNodosSeleccionados[1].seguidores
                            this.artistasNodosSeleccionados[0].popularidad = this.artistasNodosSeleccionados[1].popularidad
                            this.artistasNodosSeleccionados[0].oyentes_mensuales = this.artistasNodosSeleccionados[1].oyentes_mensuales
                        }

                        this.artistasNodosSeleccionados[this.numNodoSeleccionado - 1] = respuesta.data[0];

                        this.leerFechasMuestras()
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false;
                });
        },

        leerFechasMuestras() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/fechasmuestras")
                .then((respuesta) => {
                    this.fechasMuestras = []

                    for (let i=0; i<respuesta.data.length; i++) {
                        this.fechasMuestras.push(respuesta.data[i].fecha_muestra.substring(0,10))
                    }

                    this.visualizar()
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        visualizar() {
            if (document.getElementById("visualizacionRadarArtistas") !== null) {
                let dataSets = []
                let puntos1 = []
                let puntos2 = []

                puntos1 = [
                    Number((Number(this.artistasNodosSeleccionados[0].seguidores) * 100 / Number(this.datosMaximosArtistas.max_seguidores)).toFixed(1)),
                    Number((Number(this.artistasNodosSeleccionados[0].popularidad)).toFixed(1)),
                    Number((Number(this.artistasNodosSeleccionados[0].oyentes_mensuales) * 100 / Number(this.datosMaximosArtistas.max_oyentes_mensuales)).toFixed(1))
                ]

                dataSets.push({
                    label: this.artistasNodosSeleccionados[0].nombre.substring(0,10),
                    data: puntos1,
                    borderColor: 'rgb( 35, 155, 86 )',
                    backgroundColor: 'rgba( 35, 155, 86, 0.5 )',
                    fill: true
                })

                if (this.numNodoSeleccionado === 2) {
                    puntos2 = [
                        Number((Number(this.artistasNodosSeleccionados[1].seguidores) * 100 / Number(this.datosMaximosArtistas.max_seguidores)).toFixed(1)),
                        Number((Number(this.artistasNodosSeleccionados[1].popularidad)).toFixed(1)),
                        Number((Number(this.artistasNodosSeleccionados[1].oyentes_mensuales) * 100 / Number(this.datosMaximosArtistas.max_oyentes_mensuales)).toFixed(1))
                    ]

                    dataSets.push({
                        label: this.artistasNodosSeleccionados[1].nombre.substring(0,10),
                        data: puntos2,
                        borderColor: 'rgb( 118, 68, 138 )',
                        backgroundColor: 'rgba( 118, 68, 138, 0.6 )',
                        fill: true
                    })
                }
                
                //Crear gráfico.
                let lienzoGraficoRadarArtistas = document.getElementById('visualizacionRadarArtistas').getContext('2d');
                let visualizacionRadarArtistas = new Chart(lienzoGraficoRadarArtistas, {
                    type: 'radar',
                    data: {
                        labels: ['Seguidores', 'Popularidad', 'Oyentes mensuales'],
                        datasets: dataSets
                    },
                    options: {
                        title: {
                            display: true,
                            text: 'Comparación de descriptores',
                            fontColor: '#fff',
                            fontSize: '20'
                        },
                        legend: {
                            labels: {
                                fontColor: '#fff',
                                fontSize: 14,
                            }
                        },
                        scale: {
                            angleLines: {
                                display: true,
                                color: 'rgb(240,240,16)'
                            },
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 100,
                                fontColor: '#777'
                            },
                            gridLines: {
                                circular: false,
                                color: 'rgba(255,255,255,0.2)',
                                fontSize: 20
                            }
                        }
                    }
                });

                console.log(visualizacionRadarArtistas)
                console.log(lienzoGraficoRadarArtistas)
            }
        }
    }
}
</script>

<style scoped>
#visualizacionRadarArtistas {
    background-color: #000;
}

.nota {
    font-size: 0.9rem;
}
</style>
