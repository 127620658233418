<template>
    <div class="container">
        <!-- <span v-if="$store.getters.usuarioIdentificado && $store.getters.numClasificacionVisible == '2'"> -->
        <span v-if="$store.getters.numClasificacionVisible == '7'">
            <div v-if="cargando">
                <img src="../assets/loading.gif" width="200" />
            </div>

            <div v-else>
                <div class="dropdown borde-si borde-bottom-no borde-delgado bd-verde zona-dropdown">
                    <span class="blanco periodo">Período visible: {{ periodo }}</span>

                    <SelectorFechasDefinidas />

                    <button class="combobox dropdown-toggle borde-no" type="button" id="dropdownMenuButtonClasificacionArtistasIncrementoPorcentualPopularidad" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Top ...
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButtonClasificacionArtistasIncrementoPorcentualPopularidad">
                        <span
                            class="dropdown-item" href="#"
                            v-on:click.prevent="fijarTopX(5)"
                        >
                            Top 5
                        </span>
                        <span
                            class="dropdown-item" href="#"
                            v-on:click.prevent="fijarTopX(10)"
                        >
                            Top 10
                        </span>
                        <span
                            class="dropdown-item" href="#"
                            v-on:click.prevent="fijarTopX(20)"
                        >
                            Top 20
                        </span>
                    </div>
                </div>

                <table class="table table-dark tabla-clasificacion bd-verde">
                    <thead>
                        <tr>
                            <th scope="col" class="bg-negro amarillo-sistema">#</th>
                            <th scope="col" class="bg-negro amarillo-sistema">Artista</th>
                            <th scope="col" class="bg-negro amarillo-sistema texto-derecha">&#916;% en popularidad</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="(artista,indice) in subconjuntoArtistasVisible"
                            v-bind:key="indice"
                        >
                            <td class="bg-negro blanco">
                                {{ indice + 1 }}
                            </td>
                            <td class="bg-negro verde negrita">
                                <img
                                    class="pulsable"
                                    v-bind:src="artista.imagen_url"
                                    width="40"
                                    height="40"
                                    v-on:click.prevent="publicarArtistaPulsado(artista.user_spotify_id)"
                                >
                                <span
                                    class="pulsable"
                                    v-on:click.prevent="publicarArtistaPulsado(artista.user_spotify_id)"
                                >
                                    {{ artista.nombre | truncarTexto(16) }}
                                </span>
                            </td>
                            <td class="bg-negro texto-derecha blanco negrita">
                                {{ artista.incremento_porcentual }} %
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </span>
    </div>
</template>

<script>
import { bus } from "../main.js";
import axios from "axios";
import { urlAPI } from "../main.js";
import { TOPX_CLASIFICACION } from "../main.js";
import SelectorFechasDefinidas from "@/components/SelectorFechasDefinidas";

export default {
    name: "ClasificacionArtistasPorIncrementoPorcentualPopularidad",

    data() {
        return {
            cargando: false,
            topX: TOPX_CLASIFICACION,
            clasificacionArtistasPorIncrementoPorcentualPopularidad: [],
            subconjuntoArtistasVisible: [],
            fechaDesde: null,
            fechaHasta: null,
            periodo: 'ÚLTIMA SEMANA'
        };
    },

    components: {
        SelectorFechasDefinidas,
    },

    mounted() {
        this.leerIncrementosPopularidad()

        bus.$on("clasificacionSeleccionada", () => {
            if (this.$store.getters.numClasificacionVisible == '7') {
                this.publicarArtistasClasificacion()
            }
        });

        // bus.$on("rangoFechasDefinidasSeleccionado", (fechas) => {
        //     if (this.$store.getters.paginaVisible === 'chileenspotify' && this.$store.getters.numClasificacionVisible == '7') {
        //         if (fechas != null) {
        //             this.fechaDesde = fechas.split(",")[0]
        //             this.fechaHasta = fechas.split(",")[1]

        //             this.leerIncrementosPopularidad()
        //         }
        //     }
        // });

        bus.$on("periodoFechasDefinidasSeleccionado", (periodo) => {
            this.periodo = periodo

            if (this.$store.getters.paginaVisible === 'chileenspotify' && this.$store.getters.numClasificacionVisible == '7') {
                this.leerIncrementosPopularidad()
            }
        });
    },

    watch: {
        subconjuntoArtistasVisible: function () {
            //Publicar como pulsados a los artistas de la clasificación.
            if (this.$store.getters.paginaVisible === 'chileenspotify' && this.$store.getters.numClasificacionVisible == '7') {
                this.publicarArtistasClasificacion()
            }
        },

        topX: function () {
            this.subconjuntoArtistasVisible = this.clasificacionArtistasPorIncrementoPorcentualPopularidad.slice(0, this.topX)
        }
    },

    methods: {
        leerIncrementosPopularidad() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/clasificacionartistasporincrementoporcentualpopularidaddesdetablacalculada", {
                    params: {
                        periodo: this.periodo
                    }
                })
                .then((respuesta) => {
                    this.clasificacionArtistasPorIncrementoPorcentualPopularidad = respuesta.data
                    this.subconjuntoArtistasVisible = this.clasificacionArtistasPorIncrementoPorcentualPopularidad.slice(0, this.topX)
                })
                .catch((error) => {
                    console.log(error);
                    this.errores = true;
                })
                .finally(() => {
                    this.cargando = false;
                });
        },
        
        fijarTopX(maximoElementos) {
            if (this.clasificacionArtistasPorIncrementoPorcentualPopularidad.length >= maximoElementos) {
                this.topX = maximoElementos
            } else {
                this.topX = this.clasificacionArtistasPorIncrementoPorcentualPopularidad.length
            }
        },

        publicarArtistaPulsado(userSpotifyId) {
            let listaUserSpotifyIdArtistasPulsados = []
            
            listaUserSpotifyIdArtistasPulsados.push(userSpotifyId)

            this.$store.dispatch('definirListaUserSpotifyIdArtistasSeleccionados', listaUserSpotifyIdArtistasPulsados)

            if (listaUserSpotifyIdArtistasPulsados.length > 0) {
                bus.$emit("artistasClasificacionArtistasPorIncrementoPorcentualPopularidadDesplegados")
            }
        },

        publicarArtistasClasificacion() {
            if (this.$store.getters.numClasificacionVisible == '7') {
                let listaUserSpotifyIdArtistasPulsados = []
                let cotaSuperior = this.topX

                if (this.topX > this.clasificacionArtistasPorIncrementoPorcentualPopularidad.length) {
                    cotaSuperior = this.clasificacionArtistasPorIncrementoPorcentualPopularidad.length
                }

                for(let i = 0; i < cotaSuperior; i++) {
                    listaUserSpotifyIdArtistasPulsados.push(this.clasificacionArtistasPorIncrementoPorcentualPopularidad[i].user_spotify_id)
                }

                this.$store.dispatch('definirListaUserSpotifyIdArtistasSeleccionados', listaUserSpotifyIdArtistasPulsados)

                if (listaUserSpotifyIdArtistasPulsados.length > 0) {
                    bus.$emit("artistasClasificacionArtistasPorIncrementoPorcentualPopularidadDesplegados")
                }
            }
        }
    }
};
</script>

<style>
.titulo-tabla {
    color: whitesmoke;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1.5rem;
}

.tabla-clasificacion {
    border-style: solid !important;
    border-width: 1px !important;
    border-top-style: none !important;
}

.zona-dropdown {
    padding-top: 30px;
    padding-left: 10px;
}

.pulsable {
    cursor: pointer;
}

.pulsable:hover {
    color: rgb(240,240,16) !important;
}

.periodo {
    font-size: 0.9rem;
}
</style>
