<template>
    <div class="col-4">
        <div class="titulo-filtro-ancho">
            PARA ESTUDIO ({{ numArtistasSeleccionados | separadorMiles() }})
        </div>

        <div>
            <select id="cboArtistasParaEstudio" multiple class="form-control bg-gris-oscuro bd-gris" size="7">
                <option
                    v-for="(artista) in artistas" v-bind:key="artista.id"
                    class="bg-gris-oscuro gris"
                    v-bind:id="artista.user_spotify_id"
                >
                    {{ artista.nombre }}
                </option>
            </select>
        </div>
        <div>
            <button class="boton boton-secundario" v-on:click.prevent="limpiarSeleccion()">BORRAR</button>
            <button class="boton" v-on:click.prevent="estudiar()">ESTUDIAR</button>
        </div>
    </div>
</template>

<script>
import { bus } from "../main.js";
import Combobox from "../Combobox.js";
import axios from "axios";
import { urlAPI } from "../main.js";

export default {
    name: "CajaArtistasSeleccionados",

    data() {
        return {
            cargando: false,
            numArtistasSeleccionados: 0,
            artistas: [],
        };
    },

    mounted() {
        bus.$on("botonPublicarArtistasSeleccionadosPulsado", () => {
            if (this.$store.getters.paginaVisible === 'estudio') {
                this.leerArtistas()
            }
        })
    },
    
    methods: {
        leerArtistas() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/lista", {
                    params: {
                        listaUserSpotifyIdArtistas: this.$store.getters.listaUserSpotifyIdArtistasSeleccionados
                    }
                })
                .then((respuesta) => {
                    for (let artista of respuesta.data) {
                        let encontrado = false

                        for (let i=0; i<this.artistas.length; i++) {
                            if (artista.id === this.artistas[i].id) {
                                encontrado = true
                                break
                            }
                        }

                        if (!encontrado) {
                            this.artistas.push(artista)
                        }
                    }

                    this.numArtistasSeleccionados = this.artistas.length
                })
                .catch((error) => {
                    console.log(error);
                    this.errores = true;
                })
                .finally(() => {
                    this.cargando = false;
                });
        },

        estudiar() {
            this.$gtag.event('Estudiar artistas', { method: 'Google' })

            let listaUserSpotifyIdArtistas = []

            if (Combobox.ComboboxGetElementsNumber("cboArtistasParaEstudio") > 0) {
                //Buscar los user_spotify_id de los artistas seleccionados y armar una lista con los datos de los artistas seleccionados.
                for (let indexArtista=0; indexArtista < this.numArtistasSeleccionados; indexArtista++) {
                    listaUserSpotifyIdArtistas.push(this.artistas[indexArtista].user_spotify_id)
                }

                this.$store.dispatch('definirListaUserSpotifyIdArtistasSeleccionados', listaUserSpotifyIdArtistas)

                if (this.artistas.length > 0) {
                    bus.$emit("botonEstudiarPulsado")
                }
            }
        },

        limpiarSeleccion() {
            if (this.artistas.length > 0) {
                this.artistas = []
                Combobox.ComboboxClear("cboArtistasParaEstudio")

                this.$store.dispatch('definirListaUserSpotifyIdArtistasSeleccionados', [])
                
                this.numArtistasSeleccionados = 0
            }
        },
    }
};
</script>

<style scoped>
select {
    border: none;
    font-size: 1.2rem;
    text-align: center;
    padding: 20px;
}

option {
    padding-top: 6px;
    padding-bottom: 6px;
    border-bottom: solid;
    border-width: 1px;
    border-color: rgba(63,62,62,0.7);
}

.titulo-filtro-ancho {
    color: #fff;
    background-color:  rgb(33,32,32);
    text-align: center;
    font-size: 1.1rem;
    font-weight: 800;
    margin-bottom: 8px;
    padding: 10px;
    padding-bottom: 35px;
}
</style>