import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex)

import VueRouter from "vue-router";
import { rutas } from "./rutas.js";
import store from './store';
import App from "./App.vue";
import VueNotifications from 'vue-notifications';
import miniToastr from 'mini-toastr';
import gtag from "vue-gtag";

Vue.use(gtag, {
  config: { id: "UA-161162503-1" }
});

miniToastr.init()

function toast ({title, message, type, timeout, cb}) {
    return miniToastr[type](message, title, timeout, cb)
}

const options = {
    success: toast,
    error: toast,
    info: toast,
    warn: toast
}

Vue.use(VueNotifications, options)


const port = process.env.PORT || 8081;

// Fijar si la app se ejecuta en modo Local (L) o Explotación (E).
let modo = "E"; // Variable a fijar para establecer el modo de ejecución. L = modo Local, E = modo Explotación.
var urlAPI = "";

if (modo == "L") {
    urlAPI = "http://localhost:" + port;
}
if (modo == "E") {
    urlAPI = "https://www.api.odmc.cl"
}

Vue.use(VueRouter);
const enrutador = new VueRouter({
    routes: rutas
});

export { urlAPI };
export var bus = new Vue();


//Variables de colores.
export const White = 'rgb(255,255,255)'
export const Blue = 'rgb(66,139,202)'
export const LightBlue = 'rgb(49,176,213)'
export const DarkBlue = 'rgb(46,70,120)'
export const FacebookBlue = 'rgb(66,103,178)'
export const Green = 'rgb(28,175,154)'
export const Yellow = 'rgb(240,173,78)'
export const Red = 'rgb(217,83,79)'
export const DarkRed = 'rgb(153,0,0)'
export const Orange = 'rgb(243,129,8)'
export const Black = 'rgb(0,0,0)'
export const LightGray = 'rgb(228,231,234)'
export const Gray = 'rgb(161,169,179)'
export const DarkGray = 'rgb(74,81,91)'
export const Brown = 'rgb(81,57,33)'
export const Pink = 'rgb(245,121,232)'

//Constantes.
export const TOPX_CLASIFICACION = 5; //Cantidad de filas a mostrar inicialmente en los ránking de las estadísticas.

//Filtros globales.
Vue.filter('truncarTexto', function(texto, max) {
    if (texto.length > (max + 1)) {
        return texto.substring(0,max) + "...";
    } else {
        return texto;
    }
}),

Vue.filter('separadorMiles', function(numero) {
    return Number(numero).toLocaleString('de-DE', {minimumFractionDigits: 0, maximumFractionDigits: 0})
})

new Vue({
    el: "#app",
    router: enrutador,
    store: store,
    render: (h) => h(App)
});

