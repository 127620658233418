<template>
    <div class="container">
        <TituloPagina />

        <div class="jumbotron bg-negro">
            <div class="vista-subtitulo texto-izquierda">Importar datos de artistas desde Spotify</div>
            <br />
            <div class="row">
                <p class="gris">
                    Este proceso importa desde Spotify los datos de cada artista de la base de datos de
                    ODMC que tenga definido su User Spotify Id. Es un proceso que debe ejecutarse regularmente para ir
                    almacenando la información de los artistas en el tiempo (por ejemplo, una vez por semana).
                </p>
            </div>
            <br />
            <div class="row">
                <a href="https://www.api.odmc.cl/login?importar=importardatosartistasdesdespotify" class="boton boton-secundario">
                    Importar datos de artistas desde Spotify
                </a>
            </div>
        </div>

        <br /><br />
        <div class="jumbotron bg-negro">
            <div class="vista-subtitulo texto-izquierda">Importar top tracks</div>
            <br />
            <div class="row">
                <p class="gris">
                    Este proceso importa desde Spotify los top tracks de cada artista (aprobado y no-excluido) de la base de datos de
                    ODMC.
                </p>
            </div>
            <br />
            <div class="row">
                <a href="https://www.api.odmc.cl/login?importar=importartoptracksdesdespotify" class="boton boton-secundario">
                    Importar top tracks
                </a>
            </div>
        </div>

        <br /><br />
        <div class="jumbotron bg-negro">
            <div class="vista-subtitulo texto-izquierda">Importar artistas relacionados</div>
            <br />
            <div class="row">
                <p class="gris">
                    Este proceso importa desde Spotify los artistas relacionados de cada artista (aprobado y no-excluido) de la base de datos de
                    ODMC.
                </p>
            </div>
            <br />
            <div class="row">
                <a href="https://www.api.odmc.cl/login?importar=importarartistasrelacionadosdesdespotify" class="boton boton-secundario">
                    Importar artistas relacionados
                </a>
            </div>
        </div>

        <br /><br />
        <div class="jumbotron bg-negro">
            <div class="vista-subtitulo texto-izquierda">Depurar artistas de la base de datos</div>
            <br />
            <div class="row">
                <p class="gris">
                    Este proceso toma cada User Spotify Id de la base de datos de ODMC, y solicita sus datos a Spotify.
                    Si Spotify no devuelve datos, implica que el User Spotify Id es errado y es marcado como no-aprobado,
                    quedando fuera de las estadísticas mientras el administrador no lo revise y vuelva a aprobarlo (o eliminarlo).
                </p>
            </div>
            <br />
            <div class="row">
                <a href="https://www.api.odmc.cl/login?importar=depurarbasedatosartistas" class="boton boton-secundario">
                    Depurar
                </a>
            </div>
        </div>

        <br /><br />
        <div class="jumbotron bg-negro">
            <div class="vista-subtitulo texto-izquierda">Incorporar artistas a la base de datos ODMC</div>
            <br />
            <div class="row">
                <p class="gris">
                    Este proceso importa un listado de nombres o de User Spotify Id de artistas,
                    quedando incorporados en la base de datos de ODMC con el tag que Ud. ingrese.
                    Posteriormente, podrá ejecutar los otros procesos para importar sus datos desde Spotify.
                </p>
            </div>
            <br />

            <!-- Tag con el que se guardarán los artistas. -->
            <br />
            <form>
                <div class="row">
                    <div class="col">
                        <label for="baseDatosTipoDato">Importar</label>
                        <select
                            id="baseDatosTipoDato"
                            class="form-control"
                        >
                            <option>Nombres de artistas</option>
                            <option>User Spotify Id de artistas</option>
                        </select>
                    </div>

                    <div class="col">
                        <label for="baseDatosTagListaAImportar">Tag con el que se importarán</label>
                        <input
                            id="baseDatosTagListaAImportar"
                            type="text"
                            class="form-control"
                            placeholder="Tag"
                        />
                    </div>
                </div>

                <br />
                <div class="row">
                    <div class="col">
                        <label for="baseDatosListaAImportar">Lista a importar</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <textarea
                            id="baseDatosListaAImportar"
                            rows="10"
                            cols="54"
                            placeholder="Copie aquí la lista"
                        >
                        </textarea>
                    </div>
                </div>

                <br />
                <div class="row">
                    <button
                        type="reset"
                        class="boton boton-secundario"
                    >
                        Limpiar
                    </button>

                    <button
                        type="submit"
                        class="boton"
                        v-on:click.prevent="importarLista()"
                    >
                        Importar
                    </button>
                </div>
            </form>
        </div>

        <br /><br />
        <div class="jumbotron bg-negro">
            <div class="vista-subtitulo texto-izquierda">Importar artistas desde Spotify que coincidan con sus nombres</div>
            <br />
            <div class="row">
                <p class="gris">
                    Este proceso importa desde Spotify todos los artistas que coincidan con el nombre de los artistas de la base
                    de datos de ODMC que no tengan almacenado User Spotify Id.
                    Es útil si se tiene una lista insertada en la base de datos con solo nombres de artistas
                    que desee incorporar a la base de datos de ODMC.
                </p>
            </div>
            <br />
            <div class="row">
                <a href="https://www.api.odmc.cl/login?importar=importardatosartistaspornombredesdespotify" class="boton boton-secundario">
                    Importar artistas sin User Spotify Id desde Spotify a partir de sus nombres
                </a>
            </div>
        </div>

        <br /><br />
    </div>
</template>

<script>
import { urlAPI } from '../main.js'
import axios from "axios";
import VueNotifications from 'vue-notifications';
import Combobox from "../Combobox.js";
import TituloPagina from "./TituloPagina.vue"

export default {
    name: "BaseDatos",

    components: {
        TituloPagina
    },

    notifications: {
        alertaFaltanDatos: {
            type: VueNotifications.types.error,
            title: 'Faltan datos',
            message: 'Por favor, complete todos los datos para poder importar los datos.'
        },

        alertaGuardadoExitoso: {
            type: VueNotifications.types.success,
            title: 'Guardado exitoso',
            message: 'Los datos se han importado exitosamente.'
        },
    },

    methods: {
        importarLista() {
            if (
                document.getElementById("baseDatosTagListaAImportar").value != ""
                && document.getElementById("baseDatosListaAImportar").value != ""
            ) {

                axios
                    .post(urlAPI + "/api/artistas/importar", {
                        tipoDato: Combobox.ComboboxGetSelectedCaption("baseDatosTipoDato"),
                        tag: document.getElementById("baseDatosTagListaAImportar").value,
                        lista: document.getElementById("baseDatosListaAImportar").value
                    })
                    .then((respuesta) => {
                        console.log(respuesta)
                        this.alertaGuardadoExitoso()
                        this.limpiarFormulario()
                    })
                    .catch((error) => {
                        console.log(error);
                    })
                    .finally(() => {
                        
                    });
            } else {
                this.alertaFaltanDatos()
            }
        },

        limpiarFormulario() {
            Combobox.ComboboxClear("baseDatosTipoDato")
            document.getElementById("baseDatosTagListaAImportar").value = ""
            document.getElementById("baseDatosListaAImportar").value = ""
        }
    },
};
</script>
