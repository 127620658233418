<template>
    <div class="col-4">
        <div class="titulo-filtro">
            ARTISTAS ({{ artistasFiltradosPorTagsGenerosBusqueda.length | separadorMiles() }})
            
            <div v-if="cajaBusqueda"
                class="input-group zona-busqueda"
            >
                <input
                    id="SelectorArtistas_aguja"
                    type="search"
                    class="form-control caja-busqueda"
                    placeholder="Buscar artistas"
                />
                <button
                    class="boton boton-buscar"
                    v-on:keyup.enter="buscarArtistas()"
                    v-on:click="buscarArtistas()"
                >Buscar</button>
            </div>
        </div>

        <div v-if="cargando">
            <img src="../assets/loading.gif" width="200" />
        </div>

        <div v-else>
            <div>
                <select id="cboArtistas" multiple class="form-control bg-gris-oscuro bd-gris" size="7">
                    <option
                        v-for="(artista) in artistasFiltradosPorTagsGenerosBusqueda" v-bind:key="artista.id"
                        class="bg-gris-oscuro gris"
                        v-bind:id="artista.user_spotify_id"
                    >
                        {{ artista.nombre }}
                    </option>
                </select>
            </div>
            <div>
                <button class="boton boton-secundario" v-on:click.prevent="verTodo()">VER TODO</button>
                <button class="boton" v-on:click.prevent="publicarArtistasSeleccionados()">PARA ESTUDIO</button>
            </div>
        </div>
    </div>
</template>

<script>
import { bus } from "../main.js";
import Combobox from "../Combobox.js";
import axios from "axios";
import { urlAPI } from "../main.js";

export default {
    name: "SelectorArtistas",

    data() {
        return {
            inputComponenteSerieTags: "",
            inputComponenteSerieGeneros: "",
            cargando: false,
            artistas: [],
            artistasFiltradosPorTagsGeneros: [],
            artistasFiltradosPorTagsGenerosBusqueda: [],
            cajaBusqueda: true,
        };
    },

    mounted() {
        this.$store.dispatch('definirListaUserSpotifyIdArtistasSeleccionados', [])

        this.leerArtistas();

        bus.$on("tagsArtistasSeleccionados", (serieTags) => {
            this.inputComponenteSerieTags = serieTags

            this.filtrarArtistasPorTags()
            this.filtrarArtistasPorGeneros()
        });

        bus.$on("generosArtistasSeleccionados", (serieGeneros) => {
            this.inputComponenteSerieGeneros = serieGeneros

            this.filtrarArtistasPorTags()
            this.filtrarArtistasPorGeneros()
        });
    },

    props: {
        excluidos: String,
        aprobados: String,
        chilenos: String
    },
    
    methods: {
        leerArtistas() {
            this.cargando = true
            
            axios
                .get(urlAPI + "/api/v1/artistas/selecciondatos", {
                    params: {
                        excluidos: this.excluidos,
                        aprobados: this.aprobados,
                        chilenos: this.chilenos
                    }
                })
                .then((respuesta) => {
                    this.artistas = [],
                    this.artistasFiltradosPorTagsGeneros = []
                    this.artistasFiltradosPorTagsGenerosBusqueda = []

                    for (let artista of respuesta.data) {
                        this.artistas.push({
                            id: artista.user_spotify_id,
                            nombre: artista.nombre.trim(),
                            generos: artista.generos,
                            tags: artista.tags,
                            seleccionado: false,
                        })
                    }

                    this.artistasFiltradosPorTagsGeneros = this.artistas.slice()
                    this.artistasFiltradosPorTagsGenerosBusqueda = this.artistas.slice()

                    if (this.artistasFiltradosPorTagsGeneros.length > 0) {
                        this.$store.dispatch('definirListaUserSpotifyIdArtistasSeleccionados', [this.artistasFiltradosPorTagsGeneros[0].id])

                        bus.$emit("botonEstudiarPulsado")
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        filtrarArtistasPorTags() {
            let tagsABuscar = this.inputComponenteSerieTags.split(",")
            let tagsArtista = []
            let tagEncontrado = false

            //Quitar eventual selección de artistas.
            Combobox.ComboboxClear("cboArtistas")

            //Vaciar arreglo de artistas filtrados.
            this.artistasFiltradosPorTagsGeneros = []
            this.artistasFiltradosPorTagsGenerosBusqueda = []

            if (this.inputComponenteSerieTags != "") {

                //Filtrar artistas por tags.
                for (let artista of this.artistas) {
                    if (artista.tags != "") {
                        tagsArtista = artista.tags.split(",")
                        
                        tagEncontrado = false
                        
                        for (let tag of tagsArtista) {
                            for (let tagABuscar of tagsABuscar) {
                                if (tag == tagABuscar) {
                                    tagEncontrado = true
                                    
                                    break                                
                                }
                            }

                            if (tagEncontrado) {
                                break
                            }
                        }

                        if (tagEncontrado) {
                            this.artistasFiltradosPorTagsGeneros.push(artista)
                            this.artistasFiltradosPorTagsGenerosBusqueda.push(artista)
                        }
                    }
                }
            } else { //Si no hay tags seleccionados, entonces se listan todos los artistas.
                this.artistasFiltradosPorTagsGeneros = this.artistas.slice()
                this.artistasFiltradosPorTagsGenerosBusqueda = this.artistas.slice()
            }

            //Fijar como no seleccionado a los artistas filtrados.
            for (let artista of this.artistasFiltradosPorTagsGeneros) {
                artista.seleccionado = false
            }
        },

        filtrarArtistasPorGeneros() {
            let generosABuscar = this.inputComponenteSerieGeneros.split(",")
            let generosArtista = []
            let generoEncontrado = false
            let copiaArtistasFiltradosPorTagsGeneros = []

            //Quitar eventual selección de artistas.
            Combobox.ComboboxClear("cboArtistas")

            if (this.inputComponenteSerieGeneros != "") {
                copiaArtistasFiltradosPorTagsGeneros = this.artistasFiltradosPorTagsGeneros.slice()

                //Vaciar arreglo de artistas filtrados para volver a poblarlo.
                this.artistasFiltradosPorTagsGeneros = []
                this.artistasFiltradosPorTagsGenerosBusqueda = []

                //Filtrar artistas por géneros.
                for (let artista of copiaArtistasFiltradosPorTagsGeneros) {
                    if (artista.generos != "") {
                        generosArtista = artista.generos.split(",")
                        
                        generoEncontrado = false
                        
                        for (let genero of generosArtista) {
                            for (let generoABuscar of generosABuscar) {
                                if (genero == generoABuscar) {
                                    generoEncontrado = true
                                    
                                    break                                
                                }
                            }

                            if (generoEncontrado) {
                                break
                            }
                        }

                        if (generoEncontrado) {
                            this.artistasFiltradosPorTagsGeneros.push(artista)
                            this.artistasFiltradosPorTagsGenerosBusqueda.push(artista)
                        }
                    }
                }
            }

            //Fijar como no seleccionado a los artistas filtrados.
            for (let artista of this.artistasFiltradosPorTagsGeneros) {
                artista.seleccionado = false
            }
        },

        estudiar() {
            this.$gtag.event('Estudiar artistas', { method: 'Google' })

            let listaIndexArtistasSeleccionados = []
            let listaUserSpotifyIdArtistas = []

            if (Combobox.ComboboxGetSelectedNumber("cboArtistas") != false) {
                listaIndexArtistasSeleccionados = Combobox.ComboboxGetSelectedNumber("cboArtistas")

                //Buscar los user_spotify_id de los artistas seleccionados y armar una lista con los datos de los artistas seleccionados.
                for (let indexArtista of listaIndexArtistasSeleccionados) {
                    listaUserSpotifyIdArtistas
                        .push(this.artistasFiltradosPorTagsGeneros[indexArtista - 1].id)
                }

                this.$store.dispatch('definirListaUserSpotifyIdArtistasSeleccionados', listaUserSpotifyIdArtistas)

                if (this.artistasFiltradosPorTagsGeneros.length > 0) {
                    bus.$emit("botonEstudiarPulsado")
                }
            }
        },

        publicarArtistasSeleccionados() {
            let listaIndexArtistasSeleccionados = []
            let listaUserSpotifyIdArtistas = []

            if (Combobox.ComboboxGetSelectedNumber("cboArtistas") != false) {
                listaIndexArtistasSeleccionados = Combobox.ComboboxGetSelectedNumber("cboArtistas")

                //Buscar los user_spotify_id de los artistas seleccionados y armar una lista con los datos de los artistas seleccionados.
                for (let indexArtista of listaIndexArtistasSeleccionados) {
                    listaUserSpotifyIdArtistas
                        .push(this.artistasFiltradosPorTagsGenerosBusqueda[indexArtista - 1].id)
                }

                this.$store.dispatch('definirListaUserSpotifyIdArtistasSeleccionados', listaUserSpotifyIdArtistas)

                if (this.artistasFiltradosPorTagsGenerosBusqueda.length > 0) {
                    bus.$emit("botonPublicarArtistasSeleccionadosPulsado")
                }
            }            
        },

        mostrarCajaBusqueda() {
            this.cajaBusqueda = !this.cajaBusqueda;
        },

        // buscarArtistas() {
        //     let aguja = document.getElementById("SelectorArtistas_aguja").value.trim().toUpperCase()

        //     if (aguja != '') {
        //         Combobox.ComboboxClear("cboArtistas")

        //         for (let i = 0; i < this.artistasFiltradosPorTagsGeneros.length; i++) {
        //             if (this.artistasFiltradosPorTagsGeneros[i].nombre.toUpperCase().indexOf(aguja) != -1) {
        //                 Combobox.ComboboxAddSelected("cboArtistas", i + 1)
        //             }
        //         }
        //     }
        // }

        verTodo() {
            this.artistasFiltradosPorTagsGenerosBusqueda = this.artistasFiltradosPorTagsGeneros.slice()
            
            Combobox.ComboboxClear("cboArtistas")
        },

        buscarArtistas() {
            let aguja = document.getElementById("SelectorArtistas_aguja").value.trim().toUpperCase()

            if (aguja != '') {
                // Combobox.ComboboxEmpty("cboArtistas")
                this.artistasFiltradosPorTagsGenerosBusqueda = []

                for (let i = 0; i < this.artistasFiltradosPorTagsGeneros.length; i++) {
                    if (this.artistasFiltradosPorTagsGeneros[i].nombre.toUpperCase().indexOf(aguja) != -1) {

                        this.artistasFiltradosPorTagsGenerosBusqueda.push(this.artistasFiltradosPorTagsGeneros[i])
                    }
                }
            }
        }
    }
};
</script>

<style scoped>
select {
    border: none;
    font-size: 1.2rem;
    text-align: center;
    padding: 20px;
}

option {
    padding-top: 6px;
    padding-bottom: 6px;
    border-bottom: solid;
    border-width: 1px;
    border-color: rgba(63,62,62,0.7);
}

.zona-busqueda {
    padding: 0;
    margin: 0;
    height: 25px;
}

.caja-busqueda {
    border-radius: 0px;
    height: 35px;
    margin-left: -10px;
    border: none;
}

.boton-buscar {
    margin-top: 10px;
    height: 35px;
    padding: 2px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
    margin-right: -10px;
}
</style>