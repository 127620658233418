<template>
    <div>
        <div class="d-flex bg-negro padding-top-10">
            <form class="form-inline ml-auto">
                <input
                    v-if="$store.getters.tipoUsuario == 'anónimo'"
                    id="menu_apodo"
                    class="form-control form-control-sm margin-right-5"
                    type="text"
                    placeholder="Apodo"
                />
                <input
                    v-if="$store.getters.tipoUsuario == 'anónimo'"
                    id="menu_clave"
                    class="form-control form-control-sm margin-right-5"
                    type="password"
                    placeholder="Clave"
                />

                <span
                    v-if="$store.getters.tipoUsuario == 'corriente' || $store.getters.tipoUsuario == 'administrador'"
                    class="gris padding-right-10"
                >
                    {{ $store.getters.apodo }}
                </span>

                <button
                    v-if="$store.getters.tipoUsuario == 'corriente' || $store.getters.tipoUsuario == 'administrador'"
                    class="btn btn-sm btn-success bg-verde gris-oscuro my-2 my-sm-0 margin-right-10"
                    v-on:click.prevent="cerrarSesionUsuario()"
                >
                    Cerrar sesión
                </button>
                <button
                    v-else
                    class="btn btn-sm btn-success bg-verde gris-oscuro my-2 my-sm-0 margin-right-10"
                    v-on:keyup.enter="identificarUsuario()"
                    v-on:click.prevent="identificarUsuario()"
                >
                    Ingresar
                </button>
            </form>
        </div>

        <nav class="navbar navbar-expand-lg navbar-dark bg-negro header">
            <img src="../assets/logo-odmc-225x80.png" height="60" class="d-inline-block align-top padding-right-30" alt="">
            
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item active">
                        <a
                            v-if="$store.getters.tipoUsuario == 'anónimo' || $store.getters.tipoUsuario == 'corriente' || $store.getters.tipoUsuario == 'administrador'"
                            class="nav-link verde"
                            v-bind:class="[{'amarillo-sistema': $store.getters.paginaVisible == 'chileenspotify'}]"
                            href="#/musicachile"
                            v-on:click="$store.dispatch('verPagina','chileenspotify')"
                        >
                            Chile en Spotify
                        </a>
                    </li>

                    <li class="nav-item active">
                        <a
                            v-if="$store.getters.tipoUsuario == 'anónimo' || $store.getters.tipoUsuario == 'corriente'"
                            class="nav-link verde" href="#/estudio"
                            v-bind:class="[{'amarillo-sistema': $store.getters.paginaVisible == 'estudio'}]"
                            v-on:click="$store.dispatch('verPagina','estudio')"
                        >
                            Estudio
                        </a>
                    </li>

                    <span v-if="$store.getters.tipoUsuario == 'anónimo'">
                        <li class="nav-item active">
                            <a
                                class="nav-link verde" href="#/biblioteca"
                                v-bind:class="[{
                                    'amarillo-sistema': $store.getters.paginaVisible == 'biblioteca'
                                }]"
                                v-on:click="$store.dispatch('verPagina','biblioteca')"
                            >
                                Biblioteca
                            </a>
                        </li>
                    </span>

                    <!-- <span v-if="$store.getters.tipoUsuario == 'anónimo'">
                        <li class="nav-item active">
                            <a
                                class="nav-link" href="#"
                                v-bind:class="[{
                                    'verde-transparente': !$store.getters.usuarioIdentificado,
                                    'amarillo-sistema': $store.getters.paginaVisible == 'biblioteca'
                                }]"
                                v-on:click="alertaUsuarioNoIdentificado()"
                            >
                                Biblioteca
                            </a>
                        </li>
                    </span> -->

                    <span v-if="$store.getters.tipoUsuario == 'corriente'">
                        <li class="nav-item active">
                            <a
                                class="nav-link verde" href="#/biblioteca"
                                v-bind:class="[{
                                    'verde-transparente': !$store.getters.usuarioIdentificado,
                                    'amarillo-sistema': $store.getters.paginaVisible == 'biblioteca'
                                }]"
                                v-on:click="$store.dispatch('verPagina','biblioteca')"
                            >
                                Biblioteca
                            </a>
                        </li>
                    </span>

                    <li class="nav-item active">
                        <a
                            v-if="$store.getters.tipoUsuario == 'anónimo' || $store.getters.tipoUsuario == 'corriente'"
                            class="nav-link verde" href="#/observatorio"
                            v-bind:class="[{'amarillo-sistema': $store.getters.paginaVisible == 'observatorio'}]"
                            v-on:click="$store.dispatch('verPagina','observatorio')"
                        >
                            Observatorio
                        </a>
                    </li>

                    <li class="nav-item active">
                        <a
                            v-if="$store.getters.tipoUsuario == 'anónimo' || $store.getters.tipoUsuario == 'corriente'"
                            class="nav-link verde" href="#/contacto"
                            v-bind:class="[{'amarillo-sistema': $store.getters.paginaVisible == 'contacto'}]"
                            v-on:click="$store.dispatch('verPagina','contacto')"
                        >
                            Contacto
                        </a>
                    </li>

                    <li class="nav-item active">
                        <a
                            v-if="$store.getters.tipoUsuario == 'anónimo'"
                            class="nav-link verde" href="#/registrousuario"
                            v-bind:class="[{'amarillo-sistema': $store.getters.paginaVisible == 'registro'}]"
                            v-on:click="$store.dispatch('verPagina','registro')"
                        >
                            Suscribirse
                        </a>
                    </li>

                    <span v-if="$store.getters.tipoUsuario == 'anónimo' || $store.getters.tipoUsuario == 'corriente'">
                        <li class="nav-item active">
                            <a
                                class="nav-link verde" href="#/registrobanda"
                                v-bind:class="[{
                                    'amarillo-sistema': $store.getters.paginaVisible == 'registrarbanda'
                                }]"
                                v-on:click="$store.dispatch('verPagina','registrarbanda')"
                            >
                                Registrar banda
                            </a>
                        </li>
                    </span>

                    <li class="nav-item active">
                        <a
                            v-if="$store.getters.tipoUsuario == 'administrador'"
                            class="nav-link verde" href="#/administrardocumentos"
                            v-bind:class="[{'amarillo-sistema': $store.getters.paginaVisible == 'documentos'}]"
                            v-on:click="$store.dispatch('verPagina','documentos')"
                        >
                            Documentos
                        </a>
                    </li>

                    <li class="nav-item active">
                        <a
                            v-if="$store.getters.tipoUsuario == 'administrador'"
                            class="nav-link verde" href="#/administrartags"
                            v-bind:class="[{'amarillo-sistema': $store.getters.paginaVisible == 'tags'}]"
                            v-on:click="$store.dispatch('verPagina','tags')"
                        >
                            Tags
                        </a>
                    </li>

                    <li class="nav-item active">
                        <a
                            v-if="$store.getters.tipoUsuario == 'administrador'"
                            class="nav-link verde" href="#/administrarartistas"
                            v-bind:class="[{'amarillo-sistema': $store.getters.paginaVisible == 'artistas'}]"
                            v-on:click="$store.dispatch('verPagina','artistas')"
                        >
                            Artistas
                        </a>
                    </li>

                    <li class="nav-item active">
                        <a
                            v-if="$store.getters.tipoUsuario == 'administrador'"
                            class="nav-link verde" href="#/metricasapp"
                            v-bind:class="[{'amarillo-sistema': $store.getters.paginaVisible == 'métricas'}]"
                            v-on:click="$store.dispatch('verPagina','métricas')"
                        >
                            Métricas
                        </a>
                    </li>

                    <li class="nav-item active">
                        <a
                            v-if="$store.getters.tipoUsuario == 'administrador'"
                            class="nav-link verde" href="#/basedatos"
                            v-bind:class="[{'amarillo-sistema': $store.getters.paginaVisible == 'basededatos'}]"
                            v-on:click="$store.dispatch('verPagina','basededatos')"
                        >
                            Base de datos
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
import VueNotifications from 'vue-notifications'

export default {
    name: "Header",

    mounted() {
        this.limpiarLogin()
    },

    notifications: {
        alertaUsuarioNoIdentificado: {
            type: VueNotifications.types.error,
            title: 'Usuario no identificado',
            message: 'Debe identificarse o registrarse para acceder a esta opción.'
        },

        alertaSeccionEnDesarrollo: {
            type: VueNotifications.types.info,
            title: 'Sección en desarrollo',
            message: 'Esta sección se encuentra en desarrollo.'
        },

        alertaFaltanDatos: {
            type: VueNotifications.types.error,
            title: 'Faltan datos',
            message: 'Por favor, ingrese su apodo y clave para poder ingresar.'
        },

        alertaUsuarioNoExiste: {
            type: VueNotifications.types.error,
            title: 'Usuario no existe',
            message: 'El apodo o clave no están registrados.'
        }
    },

    methods: {
        limpiarLogin() {
            document.getElementById("menu_apodo").value = ""
            document.getElementById("menu_clave").value = ""
        },

        identificarUsuario() {
            if (document.getElementById("menu_apodo").value != "" && document.getElementById("menu_clave").value != "") {
                this.$store.dispatch('verificarLogin', {
                    apodo: document.getElementById("menu_apodo").value,
                    clave: document.getElementById("menu_clave").value
                })
            } else {
                this.alertaFaltanDatos()
            }
        },

        cerrarSesionUsuario() {
            this.$store.dispatch('cerrarSesionUsuario')
        }
    }
};
</script>

<style scoped>
    .header {
        padding-top: 0px;
        padding-bottom: 20px;
        padding-left: 100px;
        padding-right: 100px;
    }

    .padding-bottom-10 {
        padding-bottom: 10px;
    }

    .padding-top-10 {
        padding-top: 10px;
    }

    .padding-right-10 {
        padding-right: 10px;
    }

    .padding-right-30 {
        padding-right: 30px;
    }

    a:hover {
        color: rgb(240,240,16) !important;
    }

    .margin-right-5 {
        margin-right: 5px;
    }

    .margin-right-10 {
        margin-right: 10px;
    }

    .margin-bottom-10 {
        margin-bottom: 10px;
    }
</style>