<template>
    <div class="container">
        <TituloPagina />

        <div>
            <section v-if="errores">
                <p class="gris">
                    Lo sentimos, no es posible obtener la información en este
                    momento. Por favor intente nuevamente más tarde.
                </p>
            </section>

            <section v-else>
                <div class="row">
                    <SelectorTagsArtistas excluidos="todo" aprobados="todo" chilenos="todo"/>

                    <div class="col-md-4">
                        <!-- Combobox con opciones para filtrar por aprobación los artistas a ver.  -->
                        <div class="dropdown">
                            <button class="combobox dropdown-toggle borde-no" type="button" id="dropdownMenuButtonAprobacion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Filtrar por aprobación ...
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButtonAprobacion">
                                <span
                                    class="dropdown-item" href="#"
                                    v-on:click.prevent="filtrarListaArtistas('aprobados y no aprobados')"
                                >
                                    Aprobados y no aprobados
                                </span>
                                <span
                                    class="dropdown-item" href="#"
                                    v-on:click.prevent="filtrarListaArtistas('solo aprobados')"
                                >
                                    Solo aprobados
                                </span>
                                <span
                                    class="dropdown-item" href="#"
                                    v-on:click.prevent="filtrarListaArtistas('solo no aprobados')"
                                >
                                    Solo no aprobados
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <!-- Combobox con opciones para filtrar por exclusión los artistas a ver.  -->
                        <div class="dropdown">
                            <button class="combobox dropdown-toggle borde-no" type="button" id="dropdownMenuButtonExclusion" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Filtrar por exclusión ...
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButtonExclusion">
                                <span
                                    class="dropdown-item" href="#"
                                    v-on:click.prevent="filtrarListaArtistas('excluidos y no excluidos')"
                                >
                                    Excluidos y no excluidos
                                </span>
                                <span
                                    class="dropdown-item" href="#"
                                    v-on:click.prevent="filtrarListaArtistas('solo excluidos')"
                                >
                                    Solo excluidos
                                </span>
                                <span
                                    class="dropdown-item" href="#"
                                    v-on:click.prevent="filtrarListaArtistas('solo no excluidos')"
                                >
                                    Solo no excluidos
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <br />
                <br />
                <div>
                    <div v-if="cargando">
                        <img src="../assets/loading.gif" width="200" />
                    </div>

                    <div v-else>
                        <div class="row">
                            <div class="col-4">
                                <span class="gris">
                                    Página {{ paginaActual }} de {{ numPaginas }} ({{ numArtistasFiltrados }} artistas)      
                                </span>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-8">
                                <span class="gris">
                                    Ir a página
                                    <input
                                        id="AdministrarArtistas_txtIrAPagina"
                                        type="text"
                                        class="textbox navegacion"
                                        value="1"
                                    />

                                    <button
                                        class="boton boton-secundario navegacion"
                                        v-on:click="irAPagina()"
                                    > 
                                        Ir    
                                    </button>
                                </span>
                            </div>
                        </div>

                        <table class="table table-dark bg-negro">
                            <thead>
                                <tr>
                                    <th scope="col" class="amarillo">Nombre</th>
                                    <th scope="col" class="amarillo">Spotify id</th>
                                    <th scope="col" class="amarillo">Ver en Spotify</th>
                                    <th scope="col" class="amarillo">Aprobado por ODMC</th>
                                    <th scope="col" class="amarillo">Excluido de los cálculos</th>
                                    <th scope="col" class="amarillo">Chileno</th>
                                    <th scope="col" class="amarillo">Eliminar</th>
                                    <th scope="col" class="amarillo">Asignar tags</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="artista in artistas" v-bind:key="artista.id">
                                    <td>{{ artista.nombre }}</td>
                                    <td>{{ artista.user_spotify_id }}</td>
                                    <td class="texto-centro">
                                        <span
                                            v-on:click.prevent="
                                                abrirPerfilSpotifyArtista(artista.user_spotify_id)
                                            "
                                            class="boton-accion boton-accion-s bg-amarillo-sistema"
                                        >
                                            <img
                                                src="../iconos/svg/050-world-grid.svg"
                                                class="boton-accion-icono"
                                            />
                                        </span>
                                    </td>

                                    <td class="texto-centro">
                                        <span v-if="artista.aprobado">
                                            <span
                                                v-on:click.prevent="
                                                    aprobarArtista(artista.id, artista.aprobado)
                                                "
                                                class="boton-accion boton-accion-s bg-verde"
                                            >
                                                <img
                                                    src="../iconos/svg/015-checked.svg"
                                                    class="boton-accion-icono"
                                                />
                                            </span>
                                        </span>

                                        <span v-else>
                                            <span
                                                v-on:click.prevent="
                                                    aprobarArtista(artista.id, artista.aprobado)
                                                "
                                                class="boton-accion boton-accion-s bg-gris"
                                            >
                                                <img
                                                    src="../iconos/svg/015-checked.svg"
                                                    class="boton-accion-icono"
                                                />
                                            </span>
                                        </span>
                                    </td>

                                    <td class="texto-centro">
                                        <span v-if="artista.excluido">
                                            <span
                                                v-on:click.prevent="
                                                    excluirArtista(artista.id, artista.excluido)
                                                "
                                                class="boton-accion boton-accion-s bg-verde"
                                            >
                                                <img
                                                    src="../iconos/svg/046-bookmark.svg"
                                                    class="boton-accion-icono"
                                                />
                                            </span>
                                        </span>

                                        <span v-else>
                                            <span
                                                v-on:click.prevent="
                                                    excluirArtista(artista.id, artista.excluido)
                                                "
                                                class="boton-accion boton-accion-s bg-gris"
                                            >
                                                <img
                                                    src="../iconos/svg/046-bookmark.svg"
                                                    class="boton-accion-icono"
                                                />
                                            </span>
                                        </span>
                                    </td>

                                    <td class="texto-centro">
                                        <span v-if="artista.chileno">
                                            <span
                                                v-on:click.prevent="
                                                    artistaChileno(artista.id, artista.chileno)
                                                "
                                                class="boton-accion boton-accion-s bg-verde"
                                            >
                                                <img
                                                    src="../iconos/svg/021-flag.svg"
                                                    class="boton-accion-icono"
                                                />
                                            </span>
                                        </span>

                                        <span v-else>
                                            <span
                                                v-on:click.prevent="
                                                    artistaChileno(artista.id, artista.chileno)
                                                "
                                                class="boton-accion boton-accion-s bg-gris"
                                            >
                                                <img
                                                    src="../iconos/svg/021-flag.svg"
                                                    class="boton-accion-icono"
                                                />
                                            </span>
                                        </span>
                                    </td>

                                    <td class="texto-centro">
                                        <span
                                            v-on:click.prevent="
                                                eliminarArtista(artista.id)
                                            "
                                            class="boton-accion boton-accion-s bg-rojo"
                                        >
                                            <img
                                                src="../iconos/svg/028-trash.svg"
                                                class="boton-accion-icono"
                                            />
                                        </span>
                                    </td>

                                    <td class="texto-centro">
                                        <input
                                            v-bind:id="artista.id"
                                            type="text"
                                            class="textbox"
                                            v-on:blur="guardarTagsArtista(artista.id)"
                                            v-bind:value="artista.tags"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <nav>
                            <ul class="pagination">
                                <li class="page-item">
                                    <span
                                        v-on:click.prevent="paginaAtras()"
                                        class="page-link boton boton-secundario"
                                        href="#"
                                    >
                                        Atrás
                                    </span>
                                </li>
                                
                                <li class="page-item">
                                    <span
                                        v-on:click.prevent="paginaAdelante()"
                                        class="page-link boton boton-secundario"
                                        href="#"
                                    >
                                        Adelante
                                    </span>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import { urlAPI } from "../main.js"
import { bus } from "../main.js"
import VueNotifications from 'vue-notifications'
import TituloPagina from "./TituloPagina.vue"
import SelectorTagsArtistas from "./SelectorTagsArtistas"

export default {
    name: "AdministrarArtistas",

    components: {
        TituloPagina,
        SelectorTagsArtistas
    },

    data() {
        return {
            cargando: true,
            errores: false,
            artistas: [],
            tags: "",
            paginaActual: 1,
            numPaginas: 0,
            numArtistasFiltrados: 0,
            elementosPorPagina: 10,
            aprobados: 'todo',
            excluidos: 'todo',
        };
    },

    mounted() {
        this.leerArtistas();

        bus.$on("tagsArtistasSeleccionados", (tags) => {
            this.tags = tags
            this.paginaActual = 1
            this.numPaginas = 1
            this.leerArtistas()
        });
    },

    notifications: {
        alertaGuardadoExitoso: {
            type: VueNotifications.types.success,
            title: 'Guardado exitoso',
            message: 'Los tags del artista se han guardado exitosamente.'
        },

        alertaArtistaEliminado: {
            type: VueNotifications.types.success,
            title: 'Artista eliminado',
            message: 'El artista ha sido eliminado exitosamente.'
        },

        alertaArtistaExluido: {
            type: VueNotifications.types.success,
            title: 'Artista excluído/incluído',
            message: 'El artista ha sido marcado como excluído o incluído.'
        },

        alertaArtistaAprobado: {
            type: VueNotifications.types.success,
            title: 'Artista aprobado/no aprobado',
            message: 'El artista ha sido marcado como aprobado o no aprobado.'
        },

        alertaArtistaChileno: {
            type: VueNotifications.types.success,
            title: 'Artista chileno/no chileno',
            message: 'El artista ha sido marcado como chileno o no chileno.'
        },
    },

    methods: {
        leerArtistas() {

            axios
                .get(urlAPI + "/api/v1/artistas", {
                    params: {
                        excluidos: this.excluidos,
                        aprobados: this.aprobados,
                        chilenos: 'todo',
                        desplazamiento: (Number(this.paginaActual) - 1) * Number(this.elementosPorPagina),
                        elementosPorPagina: this.elementosPorPagina,
                        tags: this.tags
                    }
                })
                .then((respuesta) => {
                    if (respuesta.data.length > 0) {
                        if (respuesta.data[0].total_filas != undefined) {
                            this.artistas = respuesta.data;

                            this.numArtistasFiltrados = Number(respuesta.data[0].total_filas)

                            this.numPaginas = Math.trunc(Number(respuesta.data[0].total_filas) / Number(this.elementosPorPagina))

                            if (Number(respuesta.data[0].total_filas) % this.elementosPorPagina > 0) {
                                this.numPaginas++
                            }
                        } else {
                            this.artistas = []

                            this.numArtistasFiltrados = 0

                            this.numPaginas = 1
                        }
                    } else {
                        this.artistas = []

                        this.numArtistasFiltrados = 0

                        this.numPaginas = 1
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.errores = true;
                })
                .finally(() => {
                    this.cargando = false;
                });
        },

        eliminarArtista(artistaId) {
            axios
                .delete(urlAPI + "/api/artistas/" + artistaId)
                .then((respuesta) => {
                    console.log(respuesta);
                    this.alertaArtistaEliminado()
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.leerArtistas();
                });
        },

        aprobarArtista(artistaId, valor) {
            axios
                .put(urlAPI + "/api/artistas/aprobacion/porid", {
                    artistaId: artistaId,
                    valor: !valor,
                })
                .then((respuesta) => {
                    console.log(respuesta);
                    this.alertaArtistaAprobado()
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.leerArtistas();
                });
        },

        excluirArtista(artistaId, valor) {
            axios
                .put(urlAPI + "/api/artistas/excluir", {
                    artistaId: artistaId,
                    valor: !valor,
                })
                .then((respuesta) => {
                    console.log(respuesta);
                    this.alertaArtistaExluido()
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.leerArtistas();
                });
        },

        artistaChileno(artistaId, valor) {
            axios
                .put(urlAPI + "/api/artistas/chileno", {
                    artistaId: artistaId,
                    valor: !valor,
                })
                .then((respuesta) => {
                    console.log(respuesta);
                    this.alertaArtistaChileno()
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.leerArtistas();
                });
        },

        abrirPerfilSpotifyArtista(artistaUserSpotifyId) {
            window.open("https://open.spotify.com/artist/" + artistaUserSpotifyId)
        },

        guardarTagsArtista(artistaId) {
            //Quitar eventuales espacios en los extremos de la cadena de tags.
            document.getElementById(artistaId).value = document.getElementById(artistaId).value.trim()

            let tags = document.getElementById(artistaId).value;
            
            axios
                .put(urlAPI + "/api/artistas/tags", {
                    artistaId: artistaId,
                    tags: tags,
                })
                .then((respuesta) => {
                    console.log(respuesta);
                    this.alertaGuardadoExitoso()
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.leerArtistas();
                });
        },

        filtrarListaArtistas(filtro) {
            switch (filtro) {
                case 'aprobados y no aprobados':
                    this.aprobados = 'todo'
                    break

                case 'solo aprobados':
                    this.aprobados = true
                    break

                case 'solo no aprobados':
                    this.aprobados = false
                    break

                case 'excluidos y no excluidos':
                    this.excluidos = 'todo'
                    break

                case 'solo excluidos':
                    this.excluidos = true
                    break

                case 'solo no excluidos':
                    this.excluidos = false
                    break
            }

            this.paginaActual = 1

            this.numPaginas = 1

            this.leerArtistas()
        },

        paginaAtras() {
            if (this.paginaActual > 1) {
                this.paginaActual -= 1

                this.leerArtistas()
            }
        },

        paginaAdelante() {
            if (this.paginaActual < this.numPaginas) {
                this.paginaActual += 1

                this.leerArtistas()
            }
        },

        irAPagina() {
            let pagina = 0

            if (!isNaN(document.getElementById("AdministrarArtistas_txtIrAPagina").value)) {
                pagina = Number(document.getElementById("AdministrarArtistas_txtIrAPagina").value)

                if (pagina > 0 && pagina <= this.numPaginas) {
                    this.paginaActual = pagina

                    this.leerArtistas()
                }
            }
        }
    },
};
</script>

<style scoped>
.navegacion {
    margin: 14px 10px 0px 1px !important;
    padding: 9px 20px !important;
}
</style>