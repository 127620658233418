<template>
    <div class="container">
        <TituloPagina />

        <ListaDocumentosPropios />

        <br /><br />
        <div class="vista-titulo">
            General
        </div>

        <label for="Documentos_txtAguja">Puedes buscar documentos por nombre o por tags.</label>
        <div class="input-group mb-3">
            <div class="input-group-prepend">
                <span class="input-group-text bg-amarillo-sistema esquinas-top-no esquinas-bottom-no borde-no" id="basic-addon3">BUSCAR POR NOMBRE:</span>
            </div>

            <input type="text" class="form-control borde-no bg-caja-texto" id="Documentos_txtAguja" aria-describedby="basic-addon3">
            <div class="input-group-append">
                <button
                    class="boton boton-barra"
                    v-on:keyup.enter="buscarDocumentos()"
                    v-on:click="buscarDocumentos()"
                >Buscar</button>
            </div>
        </div>

        <SelectorTagsDocumentos />
        <ListaDocumentos />
    </div>
</template>

<script>
import TituloPagina from "./TituloPagina.vue";
import ListaDocumentosPropios from './ListaDocumentosPropios.vue';
import SelectorTagsDocumentos from './SelectorTagsDocumentos.vue'
import ListaDocumentos from './ListaDocumentos.vue'
import { bus } from "../main.js";

export default {
    name: 'Documentos',
  
    components: {
        TituloPagina,
        ListaDocumentosPropios,
        SelectorTagsDocumentos,
        ListaDocumentos
    },

    mounted() {
        this.$gtag.pageview(this.$route)
    },

    methods: {
        buscarDocumentos() {
            if (document.getElementById("Documentos_txtAguja").value.trim() != '') {
                bus.$emit("agujaBuscarDocumentosDefinida", document.getElementById("Documentos_txtAguja").value);
            }
        }
    }
}
</script>

<style scoped>
.boton-barra {
    margin-top: 10px;
    padding: 2px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
}

.bg-caja-texto {
    background-color: rgb(233,230,140);
}
</style>