<template>
    <div class="container">
        <TituloPagina />

        <div class="container">
            <a href="https://encuestadedigitalizacion.questionpro.com" target="_blank" style='color: rgb(28,175,154)'>
                <img
                    src="../assets/boton-encuesta-200x200.png"
                    height="200"
                />
            </a>
            <br /><br /><br /><br />
        </div>

        <Dashboard />

        <br />

        <span v-if="$store.getters.numCuadroDashboardSeleccionado == '2' || $store.getters.numCuadroDashboardSeleccionado == '3'">
            <br />
            <div class="container">
                <SelectorFechas />
            </div>
            <br />
        </span>

        <VisualizacionSeguidoresIndustria />
        <VisualizacionPopularidadIndustria />
        <VisualizacionOyentesIndustria />
        
        <br />
        <div class="container">
            <hr />
        </div>
        
        <!-- <AyudaPasoAPasoHome /> -->
        <br />
        <MenuClasificacion />
        <ClasificacionArtistasPorSeguidores />
        <ClasificacionArtistasPorPopularidad />
        <ClasificacionArtistasPorOyentes />
        <ClasificacionGenerosPorPopularidad />
        <ClasificacionGenerosPorSeguidores />
        <ClasificacionGenerosPorArtistas />
        <ClasificacionArtistasPorIncrementoPorcentualSeguidores />
        <ClasificacionArtistasPorIncrementoPorcentualPopularidad />
        <ClasificacionArtistasPorIncrementoPorcentualOyentes />
        <VisualizacionSeguidoresArtistas />
        <VisualizacionPopularidadArtistas />
        <VisualizacionOyentesArtistas />
        <br /><br />
        <MenuVisualizacionesDimensionesPares />
        <VisualizacionSeguidoresPopularidadArtistas />
        <VisualizacionOyentesPopularidadArtistas />
        <VisualizacionSeguidoresOyentesArtistas />
        <VisualizacionArtistasRelacionados />
        <FichaNodo />
        <VisualizacionRadarArtistas />
    </div>
</template>

<script>
import TituloPagina from "./TituloPagina.vue";
import Dashboard from "./Dashboard.vue";
import SelectorFechas from './SelectorFechas.vue';
import VisualizacionSeguidoresIndustria from './VisualizacionSeguidoresIndustria.vue';
import VisualizacionPopularidadIndustria from './VisualizacionPopularidadIndustria.vue';
import VisualizacionOyentesIndustria from './VisualizacionOyentesIndustria.vue';
import MenuClasificacion from "./MenuClasificacion.vue";
import ClasificacionArtistasPorIncrementoPorcentualSeguidores from "./ClasificacionArtistasPorIncrementoPorcentualSeguidores.vue";
import ClasificacionArtistasPorIncrementoPorcentualPopularidad from "./ClasificacionArtistasPorIncrementoPorcentualPopularidad.vue";
import ClasificacionArtistasPorIncrementoPorcentualOyentes from "./ClasificacionArtistasPorIncrementoPorcentualOyentes.vue";
import ClasificacionArtistasPorPopularidad from "./ClasificacionArtistasPorPopularidad.vue";
import ClasificacionArtistasPorSeguidores from "./ClasificacionArtistasPorSeguidores.vue";
import ClasificacionArtistasPorOyentes from "./ClasificacionArtistasPorOyentes.vue";
import ClasificacionGenerosPorPopularidad from "./ClasificacionGenerosPorPopularidad.vue";
import ClasificacionGenerosPorSeguidores from "./ClasificacionGenerosPorSeguidores.vue";
import ClasificacionGenerosPorArtistas from "./ClasificacionGenerosPorArtistas.vue";
import VisualizacionArtistasRelacionados from "./VisualizacionArtistasRelacionados.vue";
import VisualizacionSeguidoresArtistas from "./VisualizacionSeguidoresArtistas.vue";
import VisualizacionPopularidadArtistas from "./VisualizacionPopularidadArtistas.vue";
import VisualizacionOyentesArtistas from "./VisualizacionOyentesArtistas.vue";
import FichaNodo from "./FichaNodo.vue";
import MenuVisualizacionesDimensionesPares from "./MenuVisualizacionesDimensionesPares.vue";
import VisualizacionSeguidoresPopularidadArtistas from "./VisualizacionSeguidoresPopularidadArtistas.vue"
import VisualizacionOyentesPopularidadArtistas from "./VisualizacionOyentesPopularidadArtistas.vue"
import VisualizacionSeguidoresOyentesArtistas from "./VisualizacionSeguidoresOyentesArtistas.vue"
import VisualizacionRadarArtistas from "./VisualizacionRadarArtistas.vue"

// import AyudaPasoAPasoHome from "./AyudaPasoAPasoHome.vue";

export default {
    name: "MusicaChile",

    mounted() {
        this.$gtag.pageview(this.$route)
    },

    components: {
        TituloPagina,
        Dashboard,
        SelectorFechas,
        VisualizacionSeguidoresIndustria,
        VisualizacionPopularidadIndustria,
        VisualizacionOyentesIndustria,
        ClasificacionArtistasPorIncrementoPorcentualSeguidores,
        ClasificacionArtistasPorIncrementoPorcentualPopularidad,
        ClasificacionArtistasPorIncrementoPorcentualOyentes,
        MenuClasificacion,
        ClasificacionArtistasPorPopularidad,
        ClasificacionArtistasPorSeguidores,
        ClasificacionGenerosPorPopularidad,
        ClasificacionArtistasPorOyentes,
        ClasificacionGenerosPorSeguidores,
        ClasificacionGenerosPorArtistas,
        VisualizacionArtistasRelacionados,
        VisualizacionSeguidoresArtistas,
        VisualizacionPopularidadArtistas,
        VisualizacionOyentesArtistas,
        FichaNodo,
        MenuVisualizacionesDimensionesPares,
        VisualizacionSeguidoresPopularidadArtistas,
        VisualizacionOyentesPopularidadArtistas,
        VisualizacionSeguidoresOyentesArtistas,
        VisualizacionRadarArtistas,
        // AyudaPasoAPasoHome
    },
};
</script>
