<template>
    <div class="container">
        <div class="vista-subtitulo blanco">Documentos más descargados</div>
        <div v-if="cargando">
            <img src="../assets/loading.gif" width="200" />
        </div>
        
        <div v-else>
            <table class="table table-dark bg-negro">
                <thead>
                    <tr>
                        <th scope="col" class="amarillo">#</th>
                        <th scope="col" class="amarillo">Documento</th>
                        <th scope="col" class="amarillo">Descargas</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(documento, indice) in documentos"
                        v-bind:key="documento.id"
                    >
                        <td scope="row">{{ indice + 1 }}</td>
                        <td>{{ documento.nombre }}</td>
                        <td>{{ documento.numero_descargas }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { urlAPI } from "../main.js";

export default {
    name: "ClasificacionDocumentosPorNumeroDescargas",

    data() {
        return {
            cargando: true,
            errores: false,
            documentos: [],
        };
    },

    mounted() {
        this.leerDocumentosMasDescargados()
    },

    methods: {
        leerDocumentosMasDescargados() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/documentos/rankingPorNumeroDescargas", {
                    params: {
                        topX: 10,
                    }
                })
                .then((respuesta) => {
                    this.documentos = respuesta.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.errores = true;
                })
                .finally(() => {
                    this.cargando = false;
                });
        },
    },
};
</script>
