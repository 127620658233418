<template>
    <div class="container">
        <br />
        <div class="vista-titulo">
            Clasificaciones
        </div>

        <div
            class="tab margen-izquierdo"
            v-bind:class="[{'bg-amarillo-sistema': $store.getters.numClasificacionVisible == '1'}]"
            v-on:click="seleccionarClasificacion('1')"
        >
            <span class="negrita">Artistas </span>por
            <div>seguidores</div>
        </div>

        <div
            class="tab margen-izquierdo"
            v-bind:class="[{'bg-amarillo-sistema': $store.getters.numClasificacionVisible == '2'}]"
            v-on:click="seleccionarClasificacion('2')"
        >
            <span class="negrita">Artistas </span>por
            <div>popularidad</div>
        </div>

        <div
            class="tab margen-izquierdo"
            v-bind:class="[{'bg-amarillo-sistema': $store.getters.numClasificacionVisible == '8'}]"
            v-on:click="seleccionarClasificacion('8')"
        >
            <span class="negrita">Artistas </span>por
            <div>oyentes</div>
        </div>

        <div
            class="tab margen-izquierdo"
            v-bind:class="[{'bg-amarillo-sistema': $store.getters.numClasificacionVisible == '6'}]"
            v-on:click="seleccionarClasificacion('6')"
        >
            <span class="negrita">Artistas </span>por
            <div>&#916;% de seguidores</div>
        </div>

        <div
            class="tab margen-izquierdo"
            v-bind:class="[{'bg-amarillo-sistema': $store.getters.numClasificacionVisible == '7'}]"
            v-on:click="seleccionarClasificacion('7')"
        >
            <span class="negrita">Artistas </span>por
            <div>&#916;% en popularidad</div>
        </div>

        <div
            class="tab margen-izquierdo"
            v-bind:class="[{'bg-amarillo-sistema': $store.getters.numClasificacionVisible == '9'}]"
            v-on:click="seleccionarClasificacion('9')"
        >
            <span class="negrita">Artistas </span>por
            <div>&#916;% de oyentes</div>
        </div>

        <!-- <div class="float-clear"></div> -->
        
        <div
            class="tab margen-izquierdo"
            v-bind:class="[{'bg-amarillo-sistema': $store.getters.numClasificacionVisible == '3'}]"
            v-on:click="seleccionarClasificacion('3')"
        >
            <span class="negrita">Géneros </span>por
            <div>seguidores</div>
        </div>

        <div
            class="tab margen-izquierdo"
            v-bind:class="[{'bg-amarillo-sistema': $store.getters.numClasificacionVisible == '4'}]"
            v-on:click="seleccionarClasificacion('4')"
        >
            <span class="negrita">Géneros </span>por
            <div>popularidad promedio</div>
        </div>

        <div
            class="tab margen-izquierdo"
            v-bind:class="[{'bg-amarillo-sistema': $store.getters.numClasificacionVisible == '5'}]"
            v-on:click="seleccionarClasificacion('5')"
        >
            <span class="negrita">Géneros </span>por
            <div>artistas</div>
        </div>

        <div class="float-clear"></div>
    </div>
</template>

<script>
import { bus } from "../main.js";

export default {
    name: "MenuClasificacion",

    methods: {
        seleccionarClasificacion(numClasificacionSeleccionada) {
            if (numClasificacionSeleccionada == '1') {
                this.$gtag.event('Ver clasificación artistas por seguidores', { method: 'Google' })
            }
            if (numClasificacionSeleccionada == '2') {
                this.$gtag.event('Ver clasificación artistas por popularidad', { method: 'Google' })
            }
            if (numClasificacionSeleccionada == '3') {
                this.$gtag.event('Ver clasificación géneros por seguidores', { method: 'Google' })
            }
            if (numClasificacionSeleccionada == '4') {
                this.$gtag.event('Ver clasificación géneros por popularidad promedio', { method: 'Google' })
            }
            if (numClasificacionSeleccionada == '5') {
                this.$gtag.event('Ver clasificación géneros por artistas', { method: 'Google' })
            }
            if (numClasificacionSeleccionada == '6') {
                this.$gtag.event('Ver clasificación artistas por incremento porcentual de seguidores', { method: 'Google' })
            }
            if (numClasificacionSeleccionada == '7') {
                this.$gtag.event('Ver clasificación artistas por incremento porcentual en popularidad', { method: 'Google' })
            }
            if (numClasificacionSeleccionada == '8') {
                this.$gtag.event('Ver clasificación artistas por oyentes', { method: 'Google' })
            }
            if (numClasificacionSeleccionada == '9') {
                this.$gtag.event('Ver clasificación artistas por incremento porcentual de oyentes', { method: 'Google' })
            }

            this.$store.dispatch('seleccionarClasificacion', numClasificacionSeleccionada)

            bus.$emit("clasificacionSeleccionada");
        }
    }
};
</script>

<style scoped>
.tab {
    float: left;
    margin-top: 3px;
    width: calc(20% - 3px);
    background-color: #29efad;
    text-align: center;
    padding: 4px;
    line-height: 1rem;
    color: #000;
    cursor: pointer;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    font-size: 0.9rem;
}

.tab:hover {
    background-color: rgb(240,240,16) !important;
}

.margen-izquierdo {
    margin-left: 3px;
}
</style>
