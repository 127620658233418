<template>
    <div class="col-4">
        <div class="titulo-filtro">
            GÉNEROS ({{ generosFiltradosPorBusqueda.length | separadorMiles() }})

            <div v-if="cajaBusqueda"
                class="input-group zona-busqueda"
            >
                <input
                    id="SelectorGeneros_aguja"
                    type="search"
                    class="form-control caja-busqueda"
                    placeholder="Buscar géneros"
                />
                <button
                    class="boton boton-buscar"
                    v-on:keyup.enter="buscarGeneros()"
                    v-on:click="buscarGeneros()"
                >Buscar</button>
            </div>
        </div>

        <div v-if="cargando">
            <img src="../assets/loading.gif" width="200" />
        </div>

        <div v-else>
            <div>
                <select id="cboGeneros" multiple class="form-control bg-gris-oscuro bd-gris" size="7">
                    <option
                        v-for="(genero,indice) in generosFiltradosPorBusqueda" v-bind:key="indice"
                        class="bg-gris-oscuro gris"
                        v-bind:id="'SelectorGeneros' + String(indice)"
                        v-on:click="seleccionarGeneros()"
                    >
                        {{ genero.nombre }}
                    </option>
                </select>
            </div>
            <div>
                <button class="boton boton-secundario" v-on:click.prevent="verTodo()">VER TODO</button>
                <button class="boton boton-secundario" v-on:click.prevent="limpiarSeleccion()">BORRAR SELECCIÓN</button>
            </div>
        </div>
    </div>
</template>

<script>
import { bus } from "../main.js";
import Combobox from "../Combobox.js";
import axios from "axios";
import { urlAPI } from "../main.js";

export default {
    name: "SelectorGeneros",

    data() {
        return {
            cargando: false,
            generos: [],
            generosFiltradosPorBusqueda: [],
            serieGenerosSeleccionados: "",
            tags: "",
            cajaBusqueda: true
        };
    },

    props: {
        excluidos: String,
        aprobados: String,
        chilenos: String
    },

    mounted() {
        this.leerGeneros();
    },
    
    methods: {
        leerGeneros() {
            this.cargando = true
            
            axios
                .get(urlAPI + "/api/v1/artistas/generos", {
                    params: {
                        excluidos: this.excluidos,
                        aprobados: this.aprobados,
                        chilenos: this.chilenos
                    }
                })
                .then((respuesta) => {
                    let i = 0

                    for (let genero of respuesta.data) {
                        this.generos.push({
                            id: i,
                            nombre: genero.trim(),
                            seleccionado: false,
                        })

                        i++
                    }

                    this.generosFiltradosPorBusqueda = this.generos.slice()
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.serieGenerosSeleccionados = ""
                    this.cargando = false

                    // bus.$emit("tagsArtistasSeleccionados", this.serieTagsSeleccionados);
                });
        },

        seleccionarGeneros() {
            let listaGenerosSeleccionados = ""

            if (Combobox.ComboboxGetSelectedNumber("cboGeneros") != false) {
                listaGenerosSeleccionados = Combobox.ComboboxGetSelectedCaption("cboGeneros")
            }
            
            bus.$emit("generosArtistasSeleccionados", listaGenerosSeleccionados);
        },

        limpiarSeleccion() {
            if (this.generosFiltradosPorBusqueda.length > 0) {
                Combobox.ComboboxClear("cboGeneros")
                
                bus.$emit("generosArtistasSeleccionados", "");
            }
        },

        mostrarCajaBusqueda() {
            this.cajaBusqueda = !this.cajaBusqueda;
        },

        verTodo() {
            this.generosFiltradosPorBusqueda = this.generos.slice()

            this.limpiarSeleccion()
        },

        buscarGeneros() {
            let aguja = document.getElementById("SelectorGeneros_aguja").value.trim().toUpperCase()

            if (aguja != '') {
                if (this.generos.length > 0) {
                    this.generosFiltradosPorBusqueda = []
                    Combobox.ComboboxClear("cboGeneros")

                    for (let i = 0; i < this.generos.length; i++) {
                        if (this.generos[i].nombre.toUpperCase().indexOf(aguja) != -1) {
                            // Combobox.ComboboxAddSelected("cboGeneros", i + 1)

                            this.generosFiltradosPorBusqueda.push(this.generos[i])
                        }
                    }

                    // this.seleccionarGeneros()
                }
            }
        }
    },
};
</script>

<style scoped>
select {
    border: none;
    font-size: 1.2rem;
    text-align: center;
    padding: 20px;
}

option {
    padding-top: 6px;
    padding-bottom: 6px;
    border-bottom: solid;
    border-width: 1px;
    border-color: rgba(63,62,62,0.7);
}

.zona-busqueda {
    padding: 0;
    margin: 0;
    height: 25px;
}

.caja-busqueda {
    border-radius: 0px;
    height: 35px;
    margin-left: -10px;
    border: none;
}

.boton-buscar {
    margin-top: 10px;
    height: 35px;
    padding: 2px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 0;
    margin-right: -10px;
}
</style>
