<template>
    <div class="container float-clear">
        <br />
        <br />
        <span v-if="$store.getters.paginaVisible === 'chileenspotify' || $store.getters.paginaVisible === 'estudio'">
            <div v-if="cargando">
                <img src="../assets/loading.gif" width="200" />
            </div>
                
            <div v-else>
                <br /><br />
                <div id="visualizacionArtistasRelacionados"></div>

                <div class="container">
                    <br />
                    <div class="row">
                        <span class="amarillo">* </span>
                        <span class="blanco nota">
                            Pulsa los nodos para ver más información de los artistas.
                        </span>
                    </div>
                    <div class="row">
                        <span class="amarillo">* </span>
                        <span class="blanco nota">
                            Nodos: mientras mayor el nodo, mayor popularidad del artista.
                        </span>
                    </div>
                    <div class="row">
                        <span class="amarillo">* </span>
                        <span class="blanco nota">
                            Aristas: mientras más ancha la arista, mayor fuerza de la relación con el artista central.
                        </span>
                    </div>
                </div>
            </div>
        </span>
    </div>
</template>

<script>
import axios from "axios";
import { bus } from "../main.js";
import { urlAPI } from "../main.js";
import 'vis/dist/vis-network.min.css';
import vis from 'vis';

export default {
    name: "VisualizacionArtistasRelacionados",

    data() {
        return {
            cargando: false,
            artistasSemilla: [],
            artistasRelacionados: [],
            colores: [
                'rgb(21,115,81)',
                'rgb(31,173,123)',
                'rgb(41,230,164)',
                'rgb(128,240,200)',
                'rgb(169,245,219)',
                'rgb(155,155,155)',
                'rgb(196,196,196)',
                'rgb(42,185,165)',
                'rgb(41,185,97)',
                'rgb(42,185,201)',
                'rgb(131,206,165)',
            ]
        };
    },

    mounted() {
        bus.$on("artistasClasificacionArtistasPorSeguidoresDesplegados", () => {
            if (this.$store.getters.paginaVisible === 'chileenspotify') {                
                this.leerArtistasSemilla()
            }
        });

        bus.$on("artistasClasificacionArtistasPorOyentesDesplegados", () => {
            if (this.$store.getters.paginaVisible === 'chileenspotify') {
                this.leerArtistasSemilla()
            }
        });

        bus.$on("artistasClasificacionArtistasPorPopularidadDesplegados", () => {
            if (this.$store.getters.paginaVisible === 'chileenspotify') {
                this.leerArtistasSemilla()
            }
        });

        bus.$on("artistasClasificacionArtistasPorIncrementoPorcentualSeguidoresDesplegados", () => {
            if (this.$store.getters.paginaVisible === 'chileenspotify') {
                this.leerArtistasSemilla()
            }
        });

        bus.$on("artistasClasificacionArtistasPorIncrementoPorcentualOyentesDesplegados", () => {
            if (this.$store.getters.paginaVisible === 'chileenspotify') {
                this.leerArtistasSemilla()
            }
        });

        bus.$on("artistasClasificacionArtistasPorIncrementoPorcentualPopularidadDesplegados", () => {
            if (this.$store.getters.paginaVisible === 'chileenspotify') {
                this.leerArtistasSemilla()
            }
        });

        bus.$on("botonEstudiarPulsado", () => {
            if (this.$store.getters.paginaVisible === 'estudio') {
                this.leerArtistasSemilla()
            }
        })
    },

    methods: {
        leerArtistasSemilla() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/lista", {
                    params: {
                        listaUserSpotifyIdArtistas: this.$store.getters.listaUserSpotifyIdArtistasSeleccionados
                    }
                })
                .then((respuesta) => {
                    this.artistasSemilla = respuesta.data;

                    this.leerArtistasRelacionados()
                })
                .catch((error) => {
                    console.log(error);
                    this.errores = true;
                })
                .finally(() => {
                    this.cargando = false;
                });
        },

        leerArtistasRelacionados() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/relacionados", {
                    params: {
                        listaUserSpotifyIdArtistas: this.$store.getters.listaUserSpotifyIdArtistasSeleccionados
                    }
                })
                .then((respuesta) => {
                    this.artistasRelacionados = respuesta.data;
                    
                    this.graficarRelacionesPorUserSpotifyId()
                })
                .catch((error) => {
                    console.log(error);
                    this.errores = true;
                })
                .finally(() => {
                    this.cargando = false;
                });
        },

        graficarRelacionesPorUserSpotifyId() {
            if (document.getElementById("visualizacionArtistasRelacionados") !== null) {
                let listaNodos = []
                let listaAristas = []
                let listaUserSpotifyIdArtistasGraficados = []
                let color = 0

                this.cargando = true
                
                if (this.$store.getters.listaUserSpotifyIdArtistasSeleccionados != false) {

                    //Crear nodos de artistas principales.
                    for (let artista of this.artistasSemilla) {
                        let fuerzaRelacionArtistaRelacionado = 0

                        //Añadir nodo de artista principal.
                        listaNodos.push({
                            id: artista.user_spotify_id,
                            label: artista.nombre,
                            font: { color: "white" },
                            shape: "dot",
                            color: this.colores[color],
                            value: artista.popularidad
                        })

                        //Contabilizar nodo graficado.
                        listaUserSpotifyIdArtistasGraficados.push(artista.user_spotify_id)


                        //Crear nodos de artistas relacionados.
                        //Verificar si el artista principal posee artistas relacionados.
                        // if (this.artistasRelacionados.find(elemento => elemento.user_spotify_id_artista_semilla == artista.user_spotify_id) != undefined) {
                            //El artista principal sí posee artistas relacionados.

                            //Buscar los artistas relacionados del artista principal.
                            for (let artistaRelacionado of this.artistasRelacionados) {

                                if (artista.user_spotify_id == artistaRelacionado.user_spotify_id_artista_semilla) {

                                    if (fuerzaRelacionArtistaRelacionado == 0) {
                                        fuerzaRelacionArtistaRelacionado = 1
                                    }
                                    
                                    //Verificar que el artista relacionado no sea a su vez un artista semilla, para que se grafique solo como semilla.
                                    if (this.$store.getters.listaUserSpotifyIdArtistasSeleccionados.indexOf(artistaRelacionado.user_spotify_id) == -1) {
                                        
                                        //Verificar que el artista relacionado no esté ya graficado como relacionado.
                                        if (listaUserSpotifyIdArtistasGraficados.indexOf(artistaRelacionado.user_spotify_id) == -1) {
                                            
                                            //Contabilizar nodo graficado.
                                            listaUserSpotifyIdArtistasGraficados.push(
                                                artistaRelacionado.user_spotify_id
                                            )

                                            //Añadir nodo de artista relacionado.
                                            listaNodos.push({
                                                id: artistaRelacionado.user_spotify_id,
                                                label: artistaRelacionado.nombre,
                                                font: { color: "white" },
                                                shape: "dot",
                                                color: this.colores[color],
                                                value: artistaRelacionado.popularidad
                                            })
                                        }

                                        listaAristas.push({
                                            from: artista.user_spotify_id,
                                            to: artistaRelacionado.user_spotify_id,
                                            value: 21 - fuerzaRelacionArtistaRelacionado
                                        })

                                        fuerzaRelacionArtistaRelacionado++
                                    }
                                }
                            }
                        // }

                        //Cambiar de color.
                        color += 1
                        if (color >= this.colores.length) {
                            color = 0
                        }
                    }

                    let nodos = new vis.DataSet(
                        listaNodos
                    );

                    //Crear aristas.
                    let aristas = new vis.DataSet(
                        listaAristas
                    );

                    //Crear el grafo.
                    let container = document.getElementById("visualizacionArtistasRelacionados");
                    let data = {
                        nodes: nodos,
                        edges: aristas,
                    };
                    let options = {
                        clickToUse: true,

                    };
                    let network = new vis.Network(container, data, options);
                    
                    network.on("click", function (params) {
                        params.event = "[original event]";

                        bus.$emit("nodoSeleccionado", params.nodes[0])
                    });

                    this.cargando = false

                    //Dar por seleccionado al primer nodo.
                    bus.$emit("nodoSeleccionado", this.artistasSemilla[0].user_spotify_id)
                }
            }
        },
    }
};
</script>

<style scoped>
#visualizacionArtistasRelacionados {
    height: calc(90vh - 50px);
    border-style: solid;
    border-width: 1px;
    border-color: #29efad;
    background-color: black;
}

.nota {
    font-size: 0.9rem;
}
</style>
