<template>
    <div
        v-if="artistas.length > 0"
    >
        <div class="clear"></div>

        <br />
        <div class="container">
            <hr />
        </div>

        <div class="container">
            <div class="fichas-artistas">
                <!-- <div class="vista-titulo">
                    Artistas seleccionados
                </div> -->

                <div class="row envoltorio-tarjetas">
                    <span v-for="(artista, index) in artistas" v-bind:key="artista.id">
                        <div
                            v-if="artista.imagen_url != ''"
                            class="card bd-negro tarjeta"
                        >
                            
                            <img
                                v-bind:src="artista.imagen_url"
                                width="140"
                                height="140"
                                v-on:mouseover="verDatos(index)"
                                v-on:mouseout="ocultarDatos(index)"
                                v-on:click="abrirPerfilSpotifyArtista(artista.user_spotify_id)"
                                class="caratula"
                            >

                            <div
                                v-if="artista.datos_visibles"
                            >                                
                                <div
                                    class="card-body zona-datos"
                                    v-on:click="abrirPerfilSpotifyArtista(artista.user_spotify_id)"
                                >
                                    <div class="zona-datos-valor amarillo-sistema">{{ artista.popularidad }}</div>
                                    <div class="zona-datos-titulo negro">Popularidad </div>
                                    <div class="zona-datos-valor amarillo-sistema">{{ artista.seguidores | separadorMiles() }}</div>
                                    <div class="zona-datos-titulo negro">Seguidores </div>
                                </div>
                            </div>
                        </div>
                    </span>
                </div>
            </div>

            <div class="row">
                <span class="amarillo">* </span>
                <span class="blanco nota">
                    Pulsa las fichas de los artistas para abrir sus páginas en Spotify.
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { bus } from "../main.js";
import { urlAPI } from "../main.js";

export default {
    name: "FichasArtistas",

    data() {
        return {
            cargando: false,
            artistas: []
        };
    },

    mounted() {
        bus.$on("botonEstudiarPulsado", () => {
            if (this.$store.getters.paginaVisible === 'estudio') {
                this.leerArtistas()
            }
        })
    },

    methods: {
        leerArtistas() {
            this.cargando = true

            axios
                .get(urlAPI + "/api/v1/artistas/lista", {
                    params: {
                        listaUserSpotifyIdArtistas: this.$store.getters.listaUserSpotifyIdArtistasSeleccionados
                    }
                })
                .then((respuesta) => {
                    this.artistas = []
                    
                    respuesta.data
                        .forEach(element => {
                            this.artistas.push({
                                id: element.id,
                                nombre: element.name,
                                popularidad: element.popularidad,
                                seguidores: element.seguidores,
                                user_spotify_id: element.user_spotify_id,
                                imagen_url: element.imagen_url,
                                datos_visibles: false
                            })
                        });
                })
                .catch((error) => {
                    console.log(error);
                    this.errores = true;
                })
                .finally(() => {
                    this.cargando = false;
                });
        },

        abrirPerfilSpotifyArtista(artistaUserSpotifyId) {
            window.open("https://open.spotify.com/artist/" + artistaUserSpotifyId)
        },

        verDatos(idArtista) {
            this.artistas[idArtista].datos_visibles = true
        },

        ocultarDatos(idArtista) {
            this.artistas[idArtista].datos_visibles = false
        }
    }
};
</script>

<style scoped>
.fichas-artistas {
    width: 70%;
    margin: auto;
    margin-bottom: 100px;
}

.clear {
    clear: both;
}

.caratula {
    cursor: pointer;
}

.envoltorio-tarjetas {
    margin: auto;
    text-align: center;
}

.tarjeta {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 40px;
    margin-bottom: 0px;
    width: 140px;
    height: 140px;
}

.zona-datos {
    margin-top: -80px;
    width: 140px;
    height: 80px;
    padding-top: 6px;
    background-color: rgba(41,239,173,0.5) !important;
    line-height: 17px;
    text-align: center;
}

.zona-datos-titulo {
    font-weight: 800;
    font-size: 0.8rem;
}

.zona-datos-valor {
    font-weight: 800;
}

.nota {
    font-size: 0.9rem;
}
</style>
