<template>
    <div class="container">
        <div class="zona-titulo texto-centro">
            <img
                v-if="$store.getters.paginaVisible == 'chileenspotify'"
                src="../assets/titulo-chile-en-spotify.png"
                height="100"
            />

            <img
                v-if="$store.getters.paginaVisible == 'estudio'"
                src="../assets/titulo-estudio.png"
                height="100"
            />

            <img
                v-if="$store.getters.paginaVisible == 'biblioteca'"
                src="../assets/titulo-biblioteca.png"
                height="100"
            />

            <img
                v-if="$store.getters.paginaVisible == 'observatorio'"
                src="../assets/titulo-observatorio.png"
                height="100"
            />

            <img
                v-if="$store.getters.paginaVisible == 'contacto'"
                src="../assets/titulo-contacto.png"
                height="100"
            />

            <img
                v-if="$store.getters.paginaVisible == 'registrarbanda'"
                src="../assets/titulo-registrar-banda.png"
                height="100"
            />

            <img
                v-if="$store.getters.paginaVisible == 'registro'"
                src="../assets/titulo-registro.png"
                height="100"
            />

            <img
                v-if="$store.getters.paginaVisible == 'documentos'"
                src="../assets/titulo-documentos.png"
                height="100"
            />

            <img
                v-if="$store.getters.paginaVisible == 'tags'"
                src="../assets/titulo-tags.png"
                height="100"
            />

            <img
                v-if="$store.getters.paginaVisible == 'artistas'"
                src="../assets/titulo-artistas.png"
                height="100"
            />

            <img
                v-if="$store.getters.paginaVisible == 'métricas'"
                src="../assets/titulo-metricas.png"
                height="100"
            />

            <img
                v-if="$store.getters.paginaVisible == 'basededatos'"
                src="../assets/titulo-base-de-datos.png"
                height="100"
            />
        </div>
        <br />
        <div class="zona-subrayado texto-centro">
            <img src="../assets/titulo-separador-92x10.png" />
        </div>
    </div>
</template>

<script>
export default {
    name: "TituloPagina"
};
</script>

<style scoped>
.zona-titulo {
    margin-top: 30px;
    margin-bottom: 0px;
}

.zona-subrayado {
    margin-bottom: 35px;
}
</style>