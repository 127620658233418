import Vue from 'vue'
import Vuex from 'vuex'
import { urlAPI } from "./main.js";
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        tipoUsuario: "anónimo",
        nombres: "",
        apodo: "",
        id: 0,
        email: "",
        usuarioIdentificado: false,
        paginaVisible: "chileenspotify",
        numClasificacionVisible: "1",
        numCuadroDashboardSeleccionado: "0",
        numVisualizacionVisible: "1",
        numVisualizacionDimensionesParesVisible: "1",
        listaUserSpotifyIdArtistasSeleccionados: [],
    },

    actions: {
        async verificarLogin({ commit }, datosLogin) {
            const res = await axios.get(urlAPI + "/api/v1/usuarios", {
                params: {
                    apodo: datosLogin.apodo,
                    clave: datosLogin.clave
                }
            })
            
            const cuentaUsuario = await res.data
            commit('setCuentaUsuario', cuentaUsuario)
        },

        cerrarSesionUsuario({ commit }) {
            commit('setCerrarSesionUsuario')
        },

        verPagina({ commit }, pagina) {
            commit('setPaginaVisible', pagina)
        },

        seleccionarClasificacion({ commit }, numClasificacionVisible) {
            commit('setClasificacionVisible', numClasificacionVisible)
        },

        seleccionarCuadroDashboard({ commit }, numCuadroDashboardSeleccionado) {
            commit('setSeleccionarCuadroDashboard', numCuadroDashboardSeleccionado)
        },

        seleccionarVisualizacion({ commit }, numVisualizacionVisible) {
            commit('setVisualizacionVisible', numVisualizacionVisible)
        },

        seleccionarVisualizacionDimensionesPares({ commit }, numVisualizacionDimensionesParesVisible) {
            commit('setVisualizacionDimensionesParesVisible', numVisualizacionDimensionesParesVisible)
        },

        definirListaUserSpotifyIdArtistasSeleccionados({ commit }, listaUserSpotifyIdArtistasSeleccionados) {
            commit('setListaUserSpotifyIdArtistasSeleccionados', listaUserSpotifyIdArtistasSeleccionados)
        }
    },

    mutations: {
        setCuentaUsuario(state, datosCuentaUsuario) {
            state.tipoUsuario = datosCuentaUsuario.tipo_usuario
            
            if (datosCuentaUsuario.tipo_usuario != 'anónimo') {
                state.nombres = datosCuentaUsuario.nombres
                state.apodo = datosCuentaUsuario.apodo
                state.id = datosCuentaUsuario.id
                state.email = datosCuentaUsuario.email
                state.usuarioIdentificado = true
            }

            state.paginaVisible = "chileenspotify"

            window.location.href = "#/musicachile"
        },

        setCerrarSesionUsuario(state) {
            state.tipoUsuario = "anónimo"
            state.nombres = ""
            state.apodo = ""
            state.id = 0
            state.email = ""
            state.usuarioIdentificado = false
            state.paginaVisible = "chileenspotify"

            window.location.href = "#/musicachile"
        },

        setPaginaVisible(state, pagina) {
            state.paginaVisible = pagina
        },

        setClasificacionVisible(state, numClasificacionVisible) {
            state.numClasificacionVisible = numClasificacionVisible
        },

        setSeleccionarCuadroDashboard(state, numCuadroDashboardSeleccionado) {
            state.numCuadroDashboardSeleccionado = numCuadroDashboardSeleccionado
        },

        setVisualizacionVisible(state, numVisualizacionVisible) {
            state.numVisualizacionVisible = numVisualizacionVisible
        },

        setVisualizacionDimensionesParesVisible(state, numVisualizacionDimensionesParesVisible) {
            state.numVisualizacionDimensionesParesVisible = numVisualizacionDimensionesParesVisible
        },

        setListaUserSpotifyIdArtistasSeleccionados(state, listaUserSpotifyIdArtistasSeleccionados) {
            state.listaUserSpotifyIdArtistasSeleccionados = listaUserSpotifyIdArtistasSeleccionados
        },
    },

    getters: {
        tipoUsuario: state => state.tipoUsuario,
        nombres: state => state.nombres,
        apodo: state => state.apodo,
        id: state => state.id,
        email: state => state.email,
        usuarioIdentificado: state => state.usuarioIdentificado,
        paginaVisible: state => state.paginaVisible,
        numClasificacionVisible: state => state.numClasificacionVisible,
        numCuadroDashboardSeleccionado: state => state.numCuadroDashboardSeleccionado,
        numVisualizacionVisible: state => state.numVisualizacionVisible,
        numVisualizacionDimensionesParesVisible: state => state.numVisualizacionDimensionesParesVisible,
        listaUserSpotifyIdArtistasSeleccionados: state => state.listaUserSpotifyIdArtistasSeleccionados,
    }
})
