<template>
    <div class="container">
        <br />
        <div
            class="opcionMenu margen-izquierdo"
            v-bind:class="[{'amarillo-sistema': $store.getters.numVisualizacionVisible == '1'}]"
            v-on:click="seleccionarVisualizacion('1')"
        >
            Seguidores
        </div>

        <div
            class="opcionMenu margen-izquierdo"
            v-bind:class="[{'amarillo-sistema': $store.getters.numVisualizacionVisible == '2'}]"
            v-on:click="seleccionarVisualizacion('2')"
        >
            Popularidad
        </div>

        <div
            class="opcionMenu margen-izquierdo"
            v-bind:class="[{'amarillo-sistema': $store.getters.numVisualizacionVisible == '3'}]"
            v-on:click="seleccionarVisualizacion('3')"
        >
            Oyentes
        </div>

        <div
            class="opcionMenu margen-izquierdo"
            v-bind:class="[{'amarillo-sistema': $store.getters.numVisualizacionVisible == '6'}]"
            v-on:click="seleccionarVisualizacion('6')"
        >
            Seguidores-oyentes
        </div>

        <div
            class="opcionMenu margen-izquierdo"
            v-bind:class="[{'amarillo-sistema': $store.getters.numVisualizacionVisible == '4'}]"
            v-on:click="seleccionarVisualizacion('4')"
        >
            Seguidores-popularidad
        </div>

        <div
            class="opcionMenu margen-izquierdo"
            v-bind:class="[{'amarillo-sistema': $store.getters.numVisualizacionVisible == '5'}]"
            v-on:click="seleccionarVisualizacion('5')"
        >
            Oyentes-popularidad
        </div>

        <div class="float-clear"></div>
    </div>
</template>

<script>
import { bus } from "../main.js";

export default {
    name: "MenuVisualizacionesTemporales",

    methods: {
        seleccionarVisualizacion(numVisualizacionSeleccionada) {
            if (numVisualizacionSeleccionada == '1') {
                this.$gtag.event('Ver visualización seguidores en el tiempo', { method: 'Google' })
            }
            if (numVisualizacionSeleccionada == '2') {
                this.$gtag.event('Ver visualización popularidad en el tiempo', { method: 'Google' })
            }
            if (numVisualizacionSeleccionada == '3') {
                this.$gtag.event('Ver visualización oyentes en el tiempo', { method: 'Google' })
            }
            if (numVisualizacionSeleccionada == '4') {
                this.$gtag.event('Ver visualización seguidores vs popularidad', { method: 'Google' })
            }
            if (numVisualizacionSeleccionada == '5') {
                this.$gtag.event('Ver visualización oyentes vs popularidad', { method: 'Google' })
            }
            if (numVisualizacionSeleccionada == '6') {
                this.$gtag.event('Ver visualización seguidores vs oyentes', { method: 'Google' })
            }
            
            this.$store.dispatch('seleccionarVisualizacion', numVisualizacionSeleccionada)

            bus.$emit("visualizacionSeleccionada");
        }
    }
};
</script>

<style scoped>
.opcionMenu {
    float: left;
    margin-top: 3px;
    color: #29efad;
    text-align: center;
    padding-left: 0;
    padding-right: 24px;
    line-height: 1rem;
    cursor: pointer;
    font-size: 0.9rem;
}

.opcionMenu:hover {
    color: rgb(240,240,16) !important;
}

.margen-izquierdo {
    margin-left: 3px;
}
</style>
