<template>
    <div>
        <div>
            <Header></Header>
        </div>
        <div>
            <router-view></router-view>
        </div>
        <div>
            <Footer></Footer>
        </div>
    </div>
</template>

<script>
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";

export default {
    name: "App",

    components: {
        Header,
        Footer,
    },
};
</script>

<style>
body {
    margin: 0;
    padding: 0;
    background-color: rgb(25,24,25);
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    font-family: BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", "Helvetica", "Arial", sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    text-size-adjust: 100%;
    height: 100%;
}

.combobox {
    font-size: 1rem;
    font-weight: 400;
    color: #29efad;
    background-color: rgb(33,32,32);
    border-style: none;
    margin: 0px 0px;
    padding: 9px 20px;
    border-radius: 1px;
    text-decoration: none;
}

.boton {
    font-size: 1rem;
    font-weight: 800;
    color: rgba(0, 0, 0, 0.7);
    background-color: rgb(240,240,16);
    border-style: none;
    margin-right: 10px;
    margin-top: 14px;
    padding: 9px 20px;
    border-radius: 1px;
    text-decoration: none !important;
}

.boton:hover {
    color: rgba(0, 0, 0, 0.7);
    background-color: rgb(240,240,16);
}

.boton-secundario {
    color: #29efad;
    background-color:  rgb(33,32,32);
}

.icono-boton {
    height: 12px;
    padding-left: 5px;
    padding-right: 5px;
}

.boton-accion {
    background-color: #29efad;
    border-style: none;
    margin-left: 6px;
    margin-right: 6px;
    font-size: 0.8rem;
    padding: 10px 15px;
    border-radius: 4px;
}

.boton-accion:hover {
    cursor: pointer;
}

.boton-accion-icono {
    height: 12px;
}

.boton-accion-curvo {
    border-radius: 20px;
}

.boton-accion-s {
    padding: 6px 9px !important;
}

.boton-accion-m {
    padding: 10px 15px;
}

.boton-accion-l {
    padding: 15px 20px;
}

.textbox {
    border-style: none;
    /* border-bottom-style: solid;
    border-bottom-width: 2px;
    border-color: darkgray; */
    height: 40px;
    background-color: rgb(44, 44, 44);
    color: #cccccc;
    font-size: 1rem;
    font-weight: 400;
}

h2 {
    color: rgba(245,245,245,0.7);
}

label {
    color: rgba(245,245,245,0.7);
}

.file {
    border-style: none;
    /* border-bottom-style: solid;
    border-bottom-width: 2px; */
    height: 40px;
    color: #cccccc;
    font-size: 1rem;
    font-weight: 400;
}

/* Tablas */
table {
    background-color: rgb(51, 51, 51);
    border-spacing: 0;
    margin-bottom: 3rem;
}

th, td {
    min-width: 100px;
    padding: 10px 15px;
    border-style: none;
    border-bottom-style: solid;
    border-bottom-color: rgb(102, 102, 102);
    vertical-align: top;
}

th {
    color: #cccccc;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-bottom-width: 2px;
    border-style: none !important;
}

td {
    color: #cccccc;
    border-width: 1px;
    border-bottom-width: 1px;
}

.vista-titulo {
    text-align: center;
    color: #fff;
    font-size: 1.6rem;
    font-weight: 800;
}

.vista-subtitulo {
    text-align: center;
    color: whitesmoke;
    font-size: 1.5rem;
    font-weight: 400;
}

h1 {
    color: rgb(245,245,245);
    font-size: 1.5rem;
    font-weight: 400;
}

/* Anchos generales */
.ancho-10por100 {
    width: 10%;
    max-width: 10%;
}

.ancho-20por100 {
    width: 20%;
    max-width: 20%;
}

.ancho-25por100 {
    width: 25%;
    max-width: 25%;
}

.ancho-30por100 {
    width: 30%;
    max-width: 30%;
}

.ancho-40por100 {
    width: 40%;
    max-width: 40%;
}

.ancho-50por100 {
    width: 50%;
    max-width: 50%;
}

.ancho-50px {
    width: 50px;
}

.ancho-100px {
    width: 100px;
}

.ancho-150px {
    width: 150px;
}

.ancho-200px {
    width: 200px;
}

.ancho-250px {
    width: 250px;
}

.ancho-300px {
    width: 300px;
}

.ancho-350px {
    width: 350px;
}

.ancho-400px {
    width: 400px;
}

/* Formularios */
.formulario {
    padding: 10px;
    border-width: 1px;
    border-radius: 5px;
    border-style: solid;
    border-color: rgb(102, 102, 102);
}

.contenedor-campo-formulario {
    margin-right: 20px;
}

.contenedor-fila-formulario {
    padding-top: 10px;
    padding-bottom: 10px;
}

/* Alineaciones generales */
.texto-centro {
    text-align: center;
}

.texto-derecha {
    text-align: right;
}

.texto-izquierda {
    text-align: left;
}

/* Colores */
.bg-blanco {
    background-color: #fff !important;
}

.bg-negro {
    background-color: #000 !important;
}

.bg-gris-oscuro {
    background-color:  rgb(33,32,32) !important;
}

.bg-rojo {
    background-color: rgb(241, 79, 115) !important;
}

.bg-verde {
    background-color: #29efad !important;
}

.bg-amarillo {
    background-color: rgb(240,173,78) !important;
}

.bg-amarillo-sistema {
    background-color:rgb(240,240,16) !important;
}

.bg-gris {
    background-color: #cccccc !important;
}

.bg-azul {
    background-color: rgb(66,139,202) !important;
}

.negro {
    color: #000;
}

.blanco {
    color: #fff;;
}

.gris {
    color: #cccccc !important;
}

.gris-transparente {
    color: rgba(204,204,204,0.2) !important;
}

.bg-gris-transparente {
    background-color: rgba(204,204,204,0.02) !important;
}

.verde {
    color: #29efad !important;
}

.verde-oscuro {
    color: rgb(0,127,78) !important;
}

.verde-transparente {
    color: rgba(41,239,173,0.13) !important;
}

.azul {
    color: rgb(66,139,202) !important;
}

.azul-oscuro {
    color: rgb(26, 61, 122) !important;
}

.azul-claro {
    color: rgb(86,229,255) !important;
}

.amarillo {
    color: rgb(240,240,16) !important;
}

.amarillo-sistema {
    color: rgb(240,240,16) !important;
}

.amarillo-claro {
    color: rgb(240,253,168) !important;
}

.amarillo-transparente {
    color: rgba(240,173,78,0.2) !important;
}

.rojo {
    color: rgb(217,83,79) !important;
}

.gris-oscuro {
    color:  rgb(44, 44, 44) !important;
}

.gris-oscurisimo {
    color:  rgb(33, 32, 32) !important;
}

.rojo-claro {
    color: rgb(241, 169, 205) !important;
}

/* Bordes */
.bd-gris {
    border-color: #555 !important;
}

.bd-negro {
    border-color: #000 !important;
}

.bd-blanco {
    border-color: #fff !important;
}

.bd-verde {
    border-color: #29efad !important;
}

.borde-delgado {
    border-width: 1px;
}

.borde-si {
    border-style: solid;
}

.borde-no {
    border-style: none;
}

.borde-bottom-no {
    border-bottom-style: none;
}

.borde-top-no {
    border-top-style: none;
}

.esquinas-top-no {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.esquinas-bottom-no {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

/* Letras */
.letra-s {
    font-size: 0.8rem;
}

.letra-m {
    font-size: 1rem;
}

.letra-l {
    font-size: 1.2rem;
}

.letra-xl {
    font-size: 1.5rem;
}

.negrita {
    font-weight: bold;
}

.titulo-filtro {
    color: #fff;
    background-color:  rgb(33,32,32);
    text-align: center;
    font-size: 1.1rem;
    font-weight: 800;
    margin-bottom: 8px;
    padding: 10px;
}

.float-left {
    float: left;
}

.float-clear {
    clear: both;
}

.margin-top-50 {
    margin-top: 50px !important;
}

.separador-derecho-10 {
    margin-right: 10px !important;
}

hr {
    height: 1px;
    background-color: rgba(240,240,16,0.6);
    margin-top: 45px;
    margin-bottom: 20px;
}
</style>
