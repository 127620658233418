<template>
    <div class="container">
        <TituloPagina />
        
        <!-- Formulario del archivo a subir -->
        <form
            id="formularioArchivo"
            name="formularioArchivo"
            enctype="multipart/form-data"
        >
            <div class="row">
                <div class="col gris">
                    Archivo actual
                </div>
                <div class="col gris">
                    Documento a subir
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <input
                        id="documentoLink"
                        type="text"
                        class="form-control"
                        placeholder="Archivo actual"
                        readonly="readonly"
                        v-model.trim="documentoLinkEnEdicion"
                    />
                </div>
                <div class="col">
                    <input
                        id="archivoASubir"
                        name="archivoASubir"
                        type="file"
                        class="form-control"
                    />
                </div>
            </div>
        </form>

        <br />
        <div class="row">
            <div class="col-6 gris">
                Tipo de origen
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <select class="form-select form-select-lg" id="cboDocumentoPropio">
                    <option selected value="externo">Documento externo</option>
                    <option value="propio">Producción propia</option>
                </select>
            </div>
        </div>

        <!-- Formulario de la portada a subir -->
        <form
            id="formularioPortada"
            name="formularioPortada"
            enctype="multipart/form-data"
        >
            <br />
            <div class="row">
                <div class="col gris">
                    Portada actual
                </div>
                <div class="col gris">
                    Portada doc propio (máx. 250x200 px)
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <input
                        id="portadaLink"
                        type="text"
                        class="form-control"
                        placeholder="Archivo actual"
                        readonly="readonly"
                        v-model.trim="documentoPortadaLinkEnEdicion"
                    />
                </div>
                <div class="col-6">
                    <input
                        id="archivoPortadaASubir"
                        name="archivoPortadaASubir"
                        type="file"
                        class="form-control"
                    />
                </div>
            </div>
        </form>

        <!-- Datos del documento. -->
        <br />
        <form>
            <div class="row">
                <div class="col">
                    <label for="documentoNombreEnEdicion">Nombre del documento</label>
                    <input
                        id="documentoNombreEnEdicion"
                        type="text"
                        class="form-control"
                        placeholder="Nombre documento"
                        v-model.trim="documentoNombreEnEdicion"
                    />
                </div>

                <div class="col">
                    <label for="cboTags">Tags</label>
                    <select
                        id="cboTags"
                        multiple
                        size="10"
                        class="form-control"
                    >
                        <option v-for="tag in tags" v-bind:key="tag.id">
                            {{ tag.nombre }}
                        </option>
                    </select>
                </div>
            </div>
            
            <br />
            <div class="row">
                <div class="col gris">
                    Origen
                </div>
                <div class="col gris">
                    Fecha de publicación
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <input
                        id="documentoOrigen"
                        type="text"
                        class="form-control"
                        placeholder="Origen"
                        v-model.trim="documentoOrigenEnEdicion"
                    />
                </div>
                <div class="col">
                    <input
                        id="documentoFechaPublicacion"
                        type="date"
                        class="form-control"
                        placeholder="Fecha publicación"
                        v-model.trim="documentoFechaPublicacionEnEdicion"
                    />
                </div>
            </div>
            

            <br />
            <div class="row">
                <button
                    type="reset"
                    class="boton boton-secundario"
                    v-on:click.prevent="limpiarFormulario()"
                >
                    Limpiar
                </button>

                <button
                    type="submit"
                    class="boton"
                    v-on:click.prevent="guardarFormulario()"
                >
                    Guardar
                </button>
            </div>
        </form>

        <br />
        <br />
        <div>
            <section v-if="errores">
                <p class="gris">
                    Lo sentimos, no es posible obtener la información en este
                    momento, por favor intente nuevamente más tarde.
                </p>
            </section>

            <section v-else>
                <div>
                    <div v-if="cargando">
                        <img src="../assets/loading.gif" width="200" />
                    </div>

                    <div v-else>
                        <table class="table table-dark bg-negro">
                            <thead>
                                <tr>
                                    <th scope="col" class="col-md-3 bg-negro amarillo">Nombre</th>
                                    <th scope="col" class="col-md-3 bg-negro amarillo">Tags</th>
                                    <th scope="col" class="col-md-2 bg-negro amarillo">Origen</th>
                                    <th scope="col" class="col-md-2 bg-negro amarillo">Publicación</th>
                                    <th scope="col" class="col-md-1 bg-negro amarillo">Descargas</th>
                                    <th scope="col" class="col-md-1 bg-negro amarillo">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="documento in documentos"
                                    v-bind:key="documento.id"
                                >
                                    <td>
                                        {{ documento.nombre }}
                                    </td>
                                    <td>
                                        <div
                                            v-for="tag in documento.tags.split(
                                                ','
                                            )"
                                            v-bind:key="tag.id"
                                            class="administrar-documentos-contenedor-tag"
                                        >
                                            <span
                                                class="administrar-documentos-tag"
                                            >
                                                {{ tag }}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        {{ documento.origen }}
                                    </td>
                                    <td>
                                        {{
                                            documento.fecha_publicacion
                                                | formatear_fecha
                                        }}
                                    </td>
                                    <td>
                                        {{ documento.numero_descargas }}
                                    </td>
                                    <td>
                                        <span
                                            v-on:click.prevent="
                                                mostrarDocumento(documento.id)
                                            "
                                            class="boton-accion boton-accion-s"
                                        >
                                            <img
                                                src="../iconos/svg/052-edit.svg"
                                                class="boton-accion-icono"
                                            />
                                        </span>
                                        <span
                                            v-on:click.prevent="
                                                deleteDocumento(documento.id)
                                            "
                                            class="boton-accion boton-accion-s bg-rojo"
                                        >
                                            <img
                                                src="../iconos/svg//028-trash.svg"
                                                class="boton-accion-icono"
                                            />
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import { urlAPI } from "../main.js";
import Combobox from "../Combobox.js";
import FileUpload from "../FileUpload.js";
import VueNotifications from 'vue-notifications';
import TituloPagina from "./TituloPagina.vue"

export default {
    name: "AdministrarDocumentos",

    data() {
        return {
            cargando: true,
            errores: false,
            tags: [],
            documentos: [],
            documentoIdEnEdicion: 0,
            documentoNombreEnEdicion: "",
            documentoOrigenEnEdicion: "",
            documentoFechaPublicacionEnEdicion: "",
            documentoLinkEnEdicion: "",
            documentoPortadaLinkEnEdicion: "",
            file: "",
            documentoPropioEnEdicion: false
        };
    },

    filters: {
        formatear_fecha(fecha) {
            let fechaFormateada = new Date(fecha);
            return (
                fechaFormateada.getDate() +
                "-" +
                (fechaFormateada.getMonth() + 1) +
                "-" +
                fechaFormateada.getFullYear()
            );
        },
    },

    components: {
        TituloPagina
    },

    mounted() {
        this.leerTags();
        this.leerDocumentos();
    },

    notifications: {
        alertaGuardadoExitoso: {
            type: VueNotifications.types.success,
            title: 'Guardado exitoso',
            message: 'El documento se ha guardado exitosamente.'
        },

        alertaFaltanDatos: {
            type: VueNotifications.types.error,
            title: 'Faltan datos',
            message: 'Por favor, complete todos los datos para poder guardar el documento.'
        },

        alertaDocumentoEliminado: {
            type: VueNotifications.types.success,
            title: 'Documento eliminado',
            message: 'El documento ha sido eliminado exitosamente.'
        },

        alertaFaltanTags: {
            type: VueNotifications.types.error,
            title: 'Faltan tags',
            message: 'El documento debe tener al menos un tag para poder guardarse.'
        }
    },

    methods: {
        leerDocumentos() {
            axios
                .get(urlAPI + "/api/v1/documentos", {
                    params: {
                        tags: "",
                    },
                })
                .then((respuesta) => {
                    this.documentos = respuesta.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.errores = true;
                })
                .finally(() => {
                    this.cargando = false;
                    this.documentoNombreEnEdicion = "";
                    this.file = "";
                    this.documentoIdEnEdicion = 0;
                    this.documentoOrigenEnEdicion = "";
                    this.documentoFechaPublicacionEnEdicion = "";
                    this.documentoLinkEnEdicion = "";
                    this.documentoPortadaLinkEnEdicion = "";

                    if (this.tags.length > 0) {
                        Combobox.ComboboxClear("cboTags");
                    }

                    document.getElementById("archivoASubir").value = ""
                    document.getElementById("archivoPortadaASubir").value = ""
                    document.getElementById("documentoNombreEnEdicion").focus();
                });
        },

        leerTags() {
            axios
                .get(urlAPI + "/api/v1/documentos/tags")
                .then((respuesta) => {
                    this.tags = respuesta.data;
                })
                .catch((error) => {
                    console.log(error);
                    this.errores = true;
                })
                .finally(() => {
                    this.cargando = false;
                });
        },

        guardarFormulario() {
            if (document.getElementById("archivoASubir").value.substring(document.getElementById("archivoASubir").value.lastIndexOf("\\") + 1) != ""
                && this.documentoNombreEnEdicion != ""
                && this.documentoOrigenEnEdicion != ""
                && this.documentoFechaPublicacionEnEdicion != ""
                && (Combobox.ComboboxGetSelectedCaption("cboDocumentoPropio") == "Documento externo" 
                    || (Combobox.ComboboxGetSelectedCaption("cboDocumentoPropio") == "Producción propia"
                    && document.getElementById("archivoPortadaASubir").value.substring(document.getElementById("archivoPortadaASubir").value.lastIndexOf("\\") + 1) != ""))
            ) {
                    
                if (this.documentoIdEnEdicion == 0) {
                    //Documento nuevo.
                    //Convertir en cadena los tags seleccionados.
                    let listaTagsSeleccionados
                    let cadenaTags = "";

                    if (Combobox.ComboboxGetSelectedCaption("cboTags") != false) {
                        listaTagsSeleccionados = Combobox.ComboboxGetSelectedCaption("cboTags").split(",");

                        for (let tagSeleccionado of listaTagsSeleccionados) {
                            for (let tag of this.tags) {
                                if (tagSeleccionado == tag.nombre) {
                                    if (cadenaTags != "") {
                                        cadenaTags += ",";
                                    }
                                    cadenaTags += tagSeleccionado;
                                }
                            }
                        }

                        if (Combobox.ComboboxGetSelectedCaption("cboDocumentoPropio") == "Producción propia") {
                            axios
                                .post(urlAPI + "/api/documentos", {
                                    documentoNombre: this.documentoNombreEnEdicion,
                                    documentoTags: cadenaTags,
                                    documentoOrigen: this.documentoOrigenEnEdicion,
                                    documentoFechaPublicacion: this.documentoFechaPublicacionEnEdicion,
                                    documentoProduccionPropia: Combobox.ComboboxGetSelectedCaption("cboDocumentoPropio") == "Producción propia",
                                    documentoLink: document.getElementById("archivoASubir").value.substring(document.getElementById("archivoASubir").value.lastIndexOf("\\") + 1),
                                    documentoImagenPortada: document.getElementById("archivoPortadaASubir").value.substring(document.getElementById("archivoPortadaASubir").value.lastIndexOf("\\") + 1)
                                })
                                .then((respuesta) => {
                                    let idNuevoDocumento = respuesta.data.id

                                    this.subirArchivos(idNuevoDocumento);

                                    this.alertaGuardadoExitoso()
                                })
                                .catch(function(error) {
                                    console.log(error);
                                })
                                .finally(() => {
                                    this.leerDocumentos();
                                });
                        } else {
                            axios
                                .post(urlAPI + "/api/documentos", {
                                    documentoNombre: this.documentoNombreEnEdicion,
                                    documentoTags: cadenaTags,
                                    documentoOrigen: this.documentoOrigenEnEdicion,
                                    documentoFechaPublicacion: this.documentoFechaPublicacionEnEdicion,
                                    documentoProduccionPropia: Combobox.ComboboxGetSelectedCaption("cboDocumentoPropio") == "Producción propia",
                                    documentoLink: document.getElementById("archivoASubir").value.substring(document.getElementById("archivoASubir").value.lastIndexOf("\\") + 1),
                                    documentoImagenPortada: ''
                                })
                                .then((respuesta) => {
                                    let idNuevoDocumento = respuesta.data.id

                                    this.subirArchivos(idNuevoDocumento);

                                    this.alertaGuardadoExitoso()
                                })
                                .catch(function(error) {
                                    console.log(error);
                                })
                                .finally(() => {
                                    this.leerDocumentos();
                                });
                        }
                    } else {
                        this.alertaFaltanTags()
                    }
                } else {
                    //Se está editando un documento.
                    //Convertir en cadena los tags seleccionados.
                    let listaTagsSeleccionados
                    let cadenaTags = "";

                    if (Combobox.ComboboxGetSelectedCaption("cboTags") != false) {

                        listaTagsSeleccionados = Combobox.ComboboxGetSelectedCaption("cboTags").split(",");

                        for (let tagSeleccionado of listaTagsSeleccionados) {
                            for (let tag of this.tags) {
                                if (tagSeleccionado == tag.nombre) {
                                    if (cadenaTags != "") {
                                        cadenaTags += ",";
                                    }
                                    cadenaTags += tagSeleccionado;
                                }
                            }
                        }

                        if (Combobox.ComboboxGetSelectedCaption("cboDocumentoPropio") == "Producción propia") {
                            axios
                                .put(urlAPI + "/api/documentos", {
                                    documentoId: this.documentoIdEnEdicion,
                                    documentoNombre: this.documentoNombreEnEdicion,
                                    documentoTags: cadenaTags,
                                    documentoOrigen: this.documentoOrigenEnEdicion,
                                    documentoFechaPublicacion: this.documentoFechaPublicacionEnEdicion,
                                    documentoProduccionPropia: Combobox.ComboboxGetSelectedCaption("cboDocumentoPropio") == "Producción propia",
                                    documentoLink: document.getElementById("archivoASubir").value.substring(document.getElementById("archivoASubir").value.lastIndexOf("\\") + 1),
                                    documentoImagenPortada: document.getElementById("archivoPortadaASubir").value.substring(document.getElementById("archivoPortadaASubir").value.lastIndexOf("\\") + 1)
                                })
                                .then(() => {
                                    this.subirArchivos(this.documentoIdEnEdicion);

                                    this.alertaGuardadoExitoso()
                                })
                                .catch(function(error) {
                                    console.log(error);
                                })
                                .finally(() => {    
                                    this.leerDocumentos();
                                });
                        } else {
                            axios
                                .put(urlAPI + "/api/documentos", {
                                    documentoId: this.documentoIdEnEdicion,
                                    documentoNombre: this.documentoNombreEnEdicion,
                                    documentoTags: cadenaTags,
                                    documentoOrigen: this.documentoOrigenEnEdicion,
                                    documentoFechaPublicacion: this.documentoFechaPublicacionEnEdicion,
                                    documentoProduccionPropia: Combobox.ComboboxGetSelectedCaption("cboDocumentoPropio") == "Producción propia",
                                    documentoLink: document.getElementById("archivoASubir").value.substring(document.getElementById("archivoASubir").value.lastIndexOf("\\") + 1),
                                    documentoImagenPortada: ''
                                })
                                .then(() => {
                                    this.subirArchivos(this.documentoIdEnEdicion);

                                    this.alertaGuardadoExitoso()
                                })
                                .catch(function(error) {
                                    console.log(error);
                                })
                                .finally(() => {    
                                    this.leerDocumentos();
                                });
                        }
                    } else {
                        this.alertaFaltanTags()
                    }
                }
            } else {
                this.alertaFaltanDatos()
            }
        },

        subirArchivos(idNuevoDocumento) {
            //Subir documento.
            FileUpload.FileUpload("formularioArchivo", urlAPI + "/archivo?id_documento=" + idNuevoDocumento + "&nombre_archivo=" + document.getElementById("archivoASubir").value.substring(document.getElementById("archivoASubir").value.lastIndexOf("\\") + 1))

            //Subir portada.
            if (Combobox.ComboboxGetSelectedCaption("cboDocumentoPropio") == "Producción propia") {
                FileUpload.FileUpload("formularioPortada", urlAPI + "/archivoportada?id_documento=" + idNuevoDocumento + "&nombre_archivo=" + document.getElementById("archivoPortadaASubir").value.substring(document.getElementById("archivoPortadaASubir").value.lastIndexOf("\\") + 1))
            }
        },

        mostrarDocumento(documentoId) {
            this.documentoIdEnEdicion = documentoId;
            let documento = this.documentos.find(
                (documento) => documento.id == documentoId
            );
            this.documentoNombreEnEdicion = documento.nombre;
            this.documentoOrigenEnEdicion = documento.origen;
            this.documentoFechaPublicacionEnEdicion = documento.fecha_publicacion.substring(0,10);
            this.documentoLinkEnEdicion = documento.link
            this.documentoPortadaLinkEnEdicion = documento.imagen_portada

            //Destacar en el listbox de tags los tags del documento.
            if (this.tags.length > 0) {
                Combobox.ComboboxClear("cboTags");
            }
            
            let listaTags = documento.tags.split(",");

            for (let tagDocumento of listaTags) {
                let i = 0;
                for (let tag of this.tags) {
                    i++;
                    if (tagDocumento == tag.nombre) {
                        Combobox.ComboboxAddSelected("cboTags", i);
                    }
                }
            }

            document.getElementById("archivoASubir").focus();
        },

        deleteDocumento(documentoId) {
            axios
                .delete(urlAPI + "/api/documentos/" + documentoId)
                .then((respuesta) => {
                    console.log(respuesta);
                    this.alertaDocumentoEliminado()
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.leerDocumentos();
                });
        },

        limpiarFormulario() {
            document.getElementById("documentoLink").value = ""
            document.getElementById("archivoASubir").value = ""
            document.getElementById("archivoPortadaASubir").value = ""
            document.getElementById("documentoNombreEnEdicion").value = ""
            
            if (this.tags.length > 0) {
                Combobox.ComboboxClear("cboTags");
            }

            document.getElementById("documentoOrigen").value = ""
            document.getElementById("documentoFechaPublicacion").value = ""

            this.documentoLinkEnEdicion = "";
            this.documentoPortadaLinkEnEdicion = "";
            this.file = "";
            this.documentoNombreEnEdicion = "";
            this.documentoIdEnEdicion = 0;
            this.documentoOrigenEnEdicion = "";
            this.documentoFechaPublicacionEnEdicion = "";
        }
    },
};
</script>

<style scoped>
.administrar-documentos-contenedor-tag {
    margin-bottom: 10px;
}

.administrar-documentos-tag {
    color: rgba(0, 0, 0, 0.7);
    background-color: #29efad;
    font-size: 0.7rem;
    padding: 5px 10px;
    border-radius: 5px;
}
</style>
