<template>
    <div class="container">
        <span v-if="($store.getters.paginaVisible === 'chileenspotify' && ($store.getters.numClasificacionVisible === '2' || $store.getters.numClasificacionVisible === '7'))
            || ($store.getters.paginaVisible === 'estudio' && $store.getters.numVisualizacionVisible === '2')">
            <div v-if="cargando">
                <img src="../assets/loading.gif" width="200" />
            </div>
            
            <div v-else>
                <br /><br />
                <SelectorFechas />

                <canvas id="visualizacionPopularidadArtistas"></canvas>

                <div class="container">
                    <br />
                    <div class="row">
                        <span class="amarillo">* </span>
                        <span class="blanco nota">
                            Pulsa los cuadros de colores para mostrar/ocultar una curva.
                        </span>
                    </div>
                </div>
            </div>
        </span>
    </div>
</template>

<script>
import { bus } from "../main.js";
import axios from "axios";
import { urlAPI } from "../main.js";
import Chart from 'chart.js'
import SelectorFechas from './SelectorFechas.vue';

export default {
    name: "VisualizacionPopularidadArtistas",

    components: {
        SelectorFechas,
    },
    
    data() {
        return {
            cargando: true,
            datosArtistas: [],
            fechasMuestras: [],
            fechaDesde: null,
            fechaHasta: null,
            backgroundcolor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(240, 240, 16, 0.2)', //amarillo sistema (amarillo anterior: rgba(255, 206, 86, 1))
                'rgba(41, 239, 173, 0.2)', //verde sistema (verde anterior: rgba(75, 192, 192, 1))
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
                '#000', //color de fondo del lienzo
            ],
            colores: [
                'rgb( 146, 43, 33 )',
                'rgb( 118, 68, 138 )',
                'rgb( 31, 97, 141 )',
                'rgb( 20, 143, 119 )',
                'rgb( 35, 155, 86 )',
                'rgb( 185, 119, 14 )',
                'rgb( 160, 64, 0 )',
                'rgb( 255, 255, 255 )',
                'rgb( 81, 90, 90 )',
                'rgb( 28, 40, 51 )',
                'rgb( 141, 110, 99 )',
                'rgb( 252, 243, 207 )',
                'rgb( 230, 176, 170 )',
                'rgb( 215, 189, 226 )',
                'rgb( 169, 204, 227 )',
                'rgb( 163, 228, 215 )',
                'rgb( 249, 231, 159 )',
                'rgb( 237, 187, 153 )',
                'rgb( 247, 249, 249 )',
                'rgb( 171, 178, 185 )',
            ]
        };
    },

    mounted() {
        bus.$on("artistasClasificacionArtistasPorPopularidadDesplegados", () => {
            if (this.$store.getters.paginaVisible === 'chileenspotify' && this.$store.getters.numClasificacionVisible === '2') {
                this.leerPopularidadArtistas()
            }
        })

        bus.$on("artistasClasificacionArtistasPorIncrementoPorcentualPopularidadDesplegados", () => {
            if (this.$store.getters.paginaVisible === 'chileenspotify' && this.$store.getters.numClasificacionVisible === '7') {
                this.leerPopularidadArtistas()
            }
        })

        bus.$on("rangoFechasSeleccionado", (fechas) => {
            if ((this.$store.getters.paginaVisible === 'chileenspotify' && this.$store.getters.numClasificacionVisible === '2') || (this.$store.getters.paginaVisible === 'estudio' && this.$store.getters.numVisualizacionVisible === '2')) {
                if (fechas != null) {
                    this.fechaDesde = fechas.split(",")[0]
                    this.fechaHasta = fechas.split(",")[1]
                } else {
                    this.fechaDesde = null
                    this.fechaHasta = null
                }

                this.leerPopularidadArtistas()
            }
        })

        bus.$on("visualizacionSeleccionada", () => {
            if (this.$store.getters.paginaVisible === 'estudio' && this.$store.getters.numVisualizacionVisible === '2') {
                this.leerPopularidadArtistas()
            }
        })

        bus.$on("botonEstudiarPulsado", () => {
            if (this.$store.getters.paginaVisible === 'estudio' && this.$store.getters.numVisualizacionVisible === '2') {
                this.leerPopularidadArtistas()
            }
        })
    },

    methods: {
        leerPopularidadArtistas() {
            let parametros = {}

            this.cargando = true

            if (this.fechaDesde !== null && this.fechaHasta !== null) {
                parametros = {
                    listaUserSpotifyIdArtistas: this.$store.getters.listaUserSpotifyIdArtistasSeleccionados,
                    fechaDesde: this.fechaDesde,
                    fechaHasta: this.fechaHasta
                }
            } else {
                parametros = {
                    listaUserSpotifyIdArtistas: this.$store.getters.listaUserSpotifyIdArtistasSeleccionados
                }
            }

            axios
                .get(urlAPI + "/api/v1/artistas/popularidadartistas", {
                    params: parametros
                })
                .then((respuesta) => {
                    this.datosArtistas = respuesta.data

                    this.leerFechasMuestras()
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        leerFechasMuestras() {
            let parametros = {}

            this.cargando = true

            if (this.fechaDesde !== null && this.fechaHasta !== null) {
                parametros = {
                    fechaDesde: this.fechaDesde,
                    fechaHasta: this.fechaHasta
                }
            }

            axios
                .get(urlAPI + "/api/v1/artistas/fechasmuestras", {
                    params: parametros
                })
                .then((respuesta) => {
                    this.fechasMuestras = []

                    for (let i=0; i<respuesta.data.length; i++) {
                        this.fechasMuestras.push(respuesta.data[i].fecha_muestra.substring(0,10))
                    }

                    this.visualizar()
                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.cargando = false
                });
        },

        visualizar() {
            if (document.getElementById("visualizacionPopularidadArtistas") !== null) {
                let valores = []
                let dataSets = []
                let nombreArtista = ""
                let color = 0
                let muestraParaArtistaEncontrada = false
                let numFechasMuestras = this.fechasMuestras.length
                let numArtistasAVisualizar = this.datosArtistas.length

                //Armar data set de cada artista.
                for (let userSpotifyIdArtista of this.$store.getters.listaUserSpotifyIdArtistasSeleccionados) {
                    valores = []
                    nombreArtista = ""
                    
                    for (let j=0; j<numFechasMuestras; j++) {

                        muestraParaArtistaEncontrada = false

                        for (let k=0; k<numArtistasAVisualizar; k++) {
                            
                            if (this.datosArtistas[k].user_spotify_id === userSpotifyIdArtista) {
                                nombreArtista = this.datosArtistas[k].nombre.substring(0,10)

                                if (this.datosArtistas[k].fecha_muestra.substring(0, 10) === this.fechasMuestras[j]) {
                                    muestraParaArtistaEncontrada = true

                                    valores.push(this.datosArtistas[k].popularidad)
                                    break
                                }
                            }
                        }

                        if (!muestraParaArtistaEncontrada) {
                            valores.push(null)
                        }
                    }

                    dataSets.push({
                        label: nombreArtista,
                        data: valores,
                        borderWidth: 3,
                        fill: false,
                        borderColor: this.colores[color],
                    })

                    color++
                    if (color >= this.colores.length) {
                        color = 0
                    }
                }

                //Crear gráfico Popularidad Artistas vs Tiempo.
                let lienzoGraficoPopularidadArtistas = document.getElementById('visualizacionPopularidadArtistas').getContext('2d');
                let visualizacionPopularidadArtistas = new Chart(lienzoGraficoPopularidadArtistas, {
                    type: 'line',
                    data: {
                        labels: this.fechasMuestras,
                        datasets: dataSets
                    },
                    options: {
                        title: {
                            display: true,
                            text: 'Popularidad',
                            fontColor: '#fff',
                            fontSize: '20'
                        },
                        legend: {
                            labels: {
                                fontColor: '#fff',
                                fontSize: 14,
                            }
                        },
                        scales: {
                            xAxes: [{
                                ticks: {
                                    fontColor: '#fff',
                                    fontSize: 14,
                                },
                            }],
                            yAxes: [{
                                ticks: {
                                    beginAtZero: false,
                                    fontColor: '#fff',
                                    fontSize: 14,
                                },
                                gridLines: {
                                    display:true,
                                    color: 'rgba(255,255,255,0.2)'
                                }
                            }]
                        }
                    }
                });

                console.log(visualizacionPopularidadArtistas)
                console.log(lienzoGraficoPopularidadArtistas)
            }
        }
    }
}
</script>

<style scoped>
#visualizacionPopularidadArtistas {
    background-color: #000;
}

.nota {
    font-size: 0.9rem;
}
</style>
